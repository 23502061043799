import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronDown, ChevronRight ,Gift, Users, Trophy, Newspaper, MessageSquare, Handshake, Shield, Headphones, Globe, Search } from 'lucide-react';

const SidebarContainer = styled.div`
  background-color: #1A2C38;
  color: #ffffff;
  width: 100%;
  border-radius: 10px;
  font-family: Arial, sans-serif;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px; 
  padding: 10px;
`;

const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#213743' : 'transparent')};

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const MenuItemText = styled.span`
  margin-left: 10px;
`;

const SubMenu = styled.div`
  background-color: #213743;
`;

const ExpandIconWrapper = styled(ChevronDown)`
height: 20px;
width:20px;
display: flex;
align-items: center;
justify-content: center;
margin-left: auto;
border-radius:50%;
background-color: ${({ open }) => (open ? '#0F5EB4' : '#374E5E')};
  margin-left: auto;
  transform: ${({ open }) => (open ? 'rotate(360deg)' : 'rotate(272deg)')};
  transition: transform 0.3s ease;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #1e2329;
  border-radius: 20px;
  padding: 2px;
  width: 100%;
  max-width: 400px;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: white;
  padding: 8px 12px;
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  cursor: pointer;

//   &:hover {
//     background-color: rgba(255, 255, 255, 0.1);
//   }


&::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3px; // Adjust the width of the red line as needed
    background-color: red;
  }
`;

const DropdownArrow = styled.span`
  margin-left: 5px;
  font-size: 10px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #2c3e50;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 5px;
`;

const DropdownItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  color: white;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Badge = styled.span`
  background-color: #3498db;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 10px;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
    border: 1px solid #213743;
   background-color: #1e2329;
  border-radius: 20px;
  flex-grow: 1;
  margin-left: 5px;
  padding: 0 10px;
  margin-bottom:12px;
`;

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  color: white;
  padding: 8px;
  width: 100%;

  &::placeholder {
    color: #7f8c8d;
  }

  &:focus {
    outline: none;
  }
`;

const ExpandIcon = styled( ChevronRight )`
`;


export default function SidebarContent(){
    const [promotionsOpen, setPromotionsOpen] = useState(false);
    const [sponsorshipsOpen, setSponsorshipsOpen] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const options = [
        { name: 'Casino', badge: null },
        { name: 'Sports', badge: null },
        { name: 'Coming Soon', badge: '56' },
      ];
    
      const handleOptionSelect = (option) => {
        setSelectedOption(option.name);
        setIsDropdownOpen(false);
      };
    
  
      const handleMenuItemClick = (tabName) => {
          setActiveTab(tabName);
          if (tabName === 'Promotions') {
              setPromotionsOpen(!promotionsOpen);
          } else if (tabName === 'Sponsorships') {
              setSponsorshipsOpen(!sponsorshipsOpen);
          } else if (tabName === 'Language') {
              setLanguageOpen(!languageOpen);
          }
      };
    return(
        <>
        <SidebarContainer>
                <MenuItemWrapper active={activeTab === 'Promotions'} onClick={() => handleMenuItemClick('Promotions')}>
                    <Gift size={20} />
                    {/* <img src='/assets/images/stakeicons/promotionstake.png' style={{objectFit:'contain', height:"25px", width:'25px'}}/> */}
                    <MenuItemText>Promotions</MenuItemText>
                    <ExpandIconWrapper open={promotionsOpen} >
                        {/* <ExpandIcon size={15} /> */}
                    </ExpandIconWrapper>
                </MenuItemWrapper>
                {promotionsOpen && (
                    <SubMenu>
                        <MenuItemWrapper>
                            <MenuItemText>$75k Weekly Raffle</MenuItemText>
                        </MenuItemWrapper>
                        <MenuItemWrapper>
                            <MenuItemText>$100k Race - 24 Hours</MenuItemText>
                        </MenuItemWrapper>
                        <MenuItemWrapper>
                            <MenuItemText>Pragmatic Drops & Wins</MenuItemText>
                        </MenuItemWrapper>
                        <MenuItemWrapper>
                            <MenuItemText>View All</MenuItemText>
                        </MenuItemWrapper>
                    </SubMenu>
                )}
                <MenuItemWrapper active={activeTab === 'Affiliate'} onClick={() => handleMenuItemClick('Affiliate')}>
                    <Users size={20} />
                    <MenuItemText>Affiliate</MenuItemText>
                </MenuItemWrapper>
                <MenuItemWrapper active={activeTab === 'VIP Club'} onClick={() => handleMenuItemClick('VIP Club')}>
                    <Trophy size={20} />
                    <MenuItemText>VIP Club</MenuItemText>
                </MenuItemWrapper>
                <MenuItemWrapper active={activeTab === 'Blog'} onClick={() => handleMenuItemClick('Blog')}>
                    <Newspaper size={20} />
                    <MenuItemText>Blog</MenuItemText>
                </MenuItemWrapper>
                <MenuItemWrapper active={activeTab === 'Forum'} onClick={() => handleMenuItemClick('Forum')}>
                    <MessageSquare size={20} />
                    <MenuItemText>Forum</MenuItemText>
                </MenuItemWrapper>
            </SidebarContainer>
            <div style={{marginTop:'12px'}}>
            <SidebarContainer>
                <MenuItemWrapper active={activeTab === 'Sponsorships'} onClick={() => handleMenuItemClick('Sponsorships')}>
                    <Handshake size={20} />
                    <MenuItemText>Sponsorships</MenuItemText>
                    <ExpandIconWrapper open={sponsorshipsOpen} >
                        {/* <ExpandIcon  size={20} /> */}
                    </ExpandIconWrapper>
                   
                </MenuItemWrapper>
                <MenuItemWrapper active={activeTab === 'Responsible Gambling'} onClick={() => handleMenuItemClick('Responsible Gambling')}>
                    <Shield size={20} />
                    <MenuItemText>Responsible Gambling</MenuItemText>
                </MenuItemWrapper>
                <MenuItemWrapper active={activeTab === 'Live Support'} onClick={() => handleMenuItemClick('Live Support')}>
                    <Headphones size={20} />
                    <MenuItemText>Live Support</MenuItemText>
                </MenuItemWrapper>
                <MenuItemWrapper active={activeTab === 'Language'} onClick={() => handleMenuItemClick('Language')}>
                    <Globe size={20} />
                    <MenuItemText>Language: English</MenuItemText>
                    <ExpandIconWrapper open={languageOpen} >
                    {/* <ExpandIcon size={20} /> */}
                    </ExpandIconWrapper>
      
                </MenuItemWrapper>
            </SidebarContainer>
            </div>
          
        </>
    )
}