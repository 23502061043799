import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import C from "../../../Constant";
import socket from "../../../Socket";
import Cookies from "js-cookie";
import storage from "../../../Storage";
import { decode, encode, wait } from "../../../Helper";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #2c3540;
  background-color: #1e2329;
  color: white;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #00ff00;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 14px;
  margin: 0;
`;

const SignInForm = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Validate the changed field
    const newErrors = { ...errors };
    validateField(name, value, newErrors);
    setErrors(newErrors);
  };

  const validateField = (name, value, newErrors) => {
    switch (name) {
      case 'email':
        if (!value.trim()) {
          newErrors.email = 'Email or Username is required';
        } else {
          delete newErrors.email;
        }
        break;
      case 'password':
        if (!value.trim()) {
          newErrors.password = 'Password is required';
        } else {
          delete newErrors.password;
        }
        break;
    }
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach(key => validateField(key, formData[key], newErrors));
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const setLogin = (data) => {
    console.log("data in login", data);
    if (data.status === true) {
      Cookies.set("session", data.token, { expires: 14 });
      storage.setKey("logged", true);
      storage.setKey("token", data.token);
      storage.setKey("name", data.name);
      storage.setKey("avatar", data.avatar);
      storage.setKey("email", data.email);
      storage.setKey("credit", data.credit);
      storage.setKey("room", data.room);
      storage.setKey("friends", data.friends);

      wait(500).then(() => {
        window.location.reload();
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && !isSubmitting) {
      setIsSubmitting(true);
      console.log("form data", formData);
      socket.emit(
        C.LOGIN_USER,
        encode({
          username: formData.email, // Using email field for both email and username
          password: formData.password,
          recaptcha: "google",
        })
      );
    }
  };

  useEffect(() => {
    const handleLoginResponse = (data) => {
      const decodedData = decode(data);
      setLogin(decodedData);
      setIsSubmitting(false);
      console.log("login", decodedData);
    };

    socket.on(C.LOGIN_USER, handleLoginResponse);

    return () => {
      socket.off(C.LOGIN_USER, handleLoginResponse);
    };
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type="text"
        name="email"
        placeholder="Email or Username"
        value={formData.email}
        onChange={handleChange}
      />
      {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
      <Input
        type="password"
        name="password"
        placeholder="Password"
        value={formData.password}
        onChange={handleChange}
      />
      {errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting ? "Signing In..." : "Sign In"}
      </Button>
    </Form>
  );
};

export default SignInForm;