


import React from 'react'

export default function AffiliateIcon() {
    return (
        <div>
<svg fill="currentColor" viewBox="0 0 64 64" class="svg-icon " style={{height:"20px", width:'20px'}}> <title></title> <path d="M55.543 19.096a8.353 8.353 0 0 0-8.296 7.437l-.004.038-6.464 1.73a8.172 8.172 0 0 0-3.56-2.641l-.057-.018v-7.05c3.127-1.262 5.293-4.272 5.293-7.79 0-4.628-3.75-8.38-8.38-8.38a8.378 8.378 0 0 0-8.378 8.38 8.383 8.383 0 0 0 5.237 7.771l.055.02v6.837a7.744 7.744 0 0 0-1.959 1.103l.018-.014-12.29-6.703a6.611 6.611 0 0 0 0-.736v.018c0-4.629-3.75-8.38-8.379-8.38A8.378 8.378 0 0 0 0 19.098a8.378 8.378 0 0 0 8.38 8.379 8.183 8.183 0 0 0 5.538-2.16l-.006.005 11.916 6.57a7.87 7.87 0 0 0 .002 1.415l-.002-.03a8.528 8.528 0 0 0 .66 3.276l-.021-.056-9.311 9.043a8.107 8.107 0 0 0-3.43-.772h-.002l-.16-.002a8.405 8.405 0 0 0-8.405 8.406 8.405 8.405 0 0 0 8.405 8.405 8.405 8.405 0 0 0 8.406-8.405 7.947 7.947 0 0 0-.63-3.35l.02.052 9.31-9.044a8.057 8.057 0 0 0 3.414.744h.02a7.938 7.938 0 0 0 2.318-.36l-.056.015 2.66 4.124a8.25 8.25 0 0 0-1.49 4.734v.026a8.405 8.405 0 0 0 8.405 8.405 8.405 8.405 0 0 0 8.405-8.405 8.405 8.405 0 0 0-8.405-8.405 7.84 7.84 0 0 0-1.836.223l.054-.012-2.927-4.443a7.84 7.84 0 0 0 1.114-3.262l.003-.035 6.49-1.73A8.378 8.378 0 0 0 64 27.527a8.377 8.377 0 0 0-8.378-8.38h-.082.004v-.051Z"></path></svg>        </div>
    )
}
