import React from 'react';
import styled from 'styled-components';
import PartnerFooter from '../PartnerFooter';

const FooterWrapper = styled.div`

  color: #a0aec0;

  font-family: Arial, sans-serif;
  padding: 0px 10px;
  max-width:1320px;
  margin:0 auto;
  @media (min-width: 1321px) {
    padding-left: 40px;
    padding-right:40px;
  }
`;

const FooterContainer = styled.div`
  position: relative;
  width: 100%;
`;

const FooterContent = styled.div`
  background-color: #1a202c;
  color: #6c7a89;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FooterSection = styled.div`
  h3 {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 0.4rem;
  }

  a {
    color: #a0aec0;
    text-decoration: none;
    font-size: 0.8rem;
    transition: color 0.3s ease;

    &:hover {
      color: #ffffff;
    }
  }
`;


const Logo = styled.h1`
  color: #ffffff;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;

const Copyright = styled.p`
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

const BtcPrice = styled.p`
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
`;

const CompanyInfo = styled.p`
  font-size: 0.8rem;
  line-height: 1.4;
  max-width: 800px;
  margin: 0 auto 1.5rem;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #2d3748;
  margin: 1rem 0;
`;

const LastWrapper = styled.div`
  background-color: #0f212e;
  color: #718096;
  // padding: 0rem 1rem;
  font-family: Arial, sans-serif;
  text-align: center;
`;


const Links = styled.div`
  font-size: 0.8rem;
  
  a {
    color: #718096;
    text-decoration: none;
    margin: 0 0.5rem;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StakeFooter = () => {
  return (

    <div style={{backgroundColor:' #0f212e'}}>
   
    <FooterWrapper>
    
      <FooterGrid>
        <FooterSection>
          <h3>Sports</h3>
          <ul>
            <li><a href="#">Sportsbook</a></li>
            <li><a href="#">Live Sports</a></li>
            <li><a href="#">Soccer</a></li>
            <li><a href="#">Basketball</a></li>
            <li><a href="#">Tennis</a></li>
            <li><a href="#">eSports</a></li>
            <li><a href="#">Bet Bonuses</a></li>
            <li><a href="#">Sports Rules</a></li>
            <li><a href="#">Racing Rules</a></li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h3>Casino</h3>
          <ul>
            <li><a href="#">Casino Games</a></li>
            <li><a href="#">Slots</a></li>
            <li><a href="#">Live Casino</a></li>
            <li><a href="#">Roulette</a></li>
            <li><a href="#">Blackjack</a></li>
            <li><a href="#">Providers</a></li>
            <li><a href="#">VIP Club</a></li>
            <li><a href="#">Promotions</a></li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h3>Support</h3>
          <ul>
            <li><a href="#">Fairness</a></li>
            <li><a href="#">Affiliate</a></li>
            <li><a href="#">Responsible Gambling</a></li>
            <li><a href="#">Gamble Aware</a></li>
            <li><a href="#">Live Support</a></li>
            <li><a href="#">Help Center</a></li>
            <li><a href="#">How to Guides</a></li>
            <li><a href="#">Local Currency Guide</a></li>
            <li><a href="#">Supported Crypto</a></li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h3>Community</h3>
          <ul>
            <li><a href="#">Blog</a></li>
            <li><a href="#">Forum</a></li>
            <li><a href="#">Facebook</a></li>
            <li><a href="#">Twitter</a></li>
            <li><a href="#">Instagram</a></li>
            <li><a href="#">YouTube</a></li>
            <li><a href="#">Shop</a></li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h3>About Us</h3>
          <ul>
            <li><a href="#">Privacy Policy</a></li>
            <li><a href="#">Licenses</a></li>
            <li><a href="#">AML Policy</a></li>
            <li><a href="#">Terms of Service</a></li>
            <li><a href="#">Self Exclusion</a></li>
            <li><a href="#">Primedice</a></li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h3>Language</h3>
          <ul>
            <li><a href="#">English</a></li>
          </ul>
          <h3>Odds</h3>
          <ul>
            <li><a href="#">Decimal</a></li>
          </ul>
        </FooterSection>
      </FooterGrid>
    </FooterWrapper>
    <PartnerFooter/>
    <LastWrapper>
    <Logo>Stake</Logo>
      <Copyright>© 2024 Stake.com | All Rights Reserved.</Copyright>
      <BtcPrice>1 BTC = $66,666.66</BtcPrice>
      <CompanyInfo>
        Stake is owned and operated by Medium Rare N.V., registration number: 145353, registered address: Korporaalweg 10, Willemstad, Curacao. Contact us at support@stake.com. Payment agent company is Medium Rare Limited with address 7-9 Riga Feraiou, LIZANTIA COURT, Office 310, Agioi Omologites, 1087 Nicosia, Cyprus and Registration number: HE 410775
      </CompanyInfo>
      <Links>
        <a href="mailto:support@stake.com">Support support@stake.com</a> |
        <a href="mailto:partners@stake.com">Partners partners@stake.com</a> |
        <a href="mailto:press@stake.com">Press press@stake.com</a>
      </Links>
      <Divider />
    </LastWrapper>
   
      {/* Add any additional elements like social media icons here */}
    </div>
  );
};

export default StakeFooter;