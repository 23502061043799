import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AuthModal from '../../StakeAuth/AuthModal';
import storage from '../../../../Storage';
import { Bell, ChevronDown, ChevronUp, MessageCircle, Search, User } from 'lucide-react';
import {
  Wallet,
  Vault,
  Trophy,
  Star,
  BarChart2,
  Receipt,
  TicketCheck,
  Settings,
  Zap,
  Headphones,
  LogOut
} from 'lucide-react';
import WalletModal from './StakeWalletModal';
import SearchInputBox from '../../StakeSearch';
import VipModal from './StakeVip';
import WalletIcon from '../../svgs/Wallet';
import VauletIcon from '../../svgs/Vaulet';
import VipIcon from '../../svgs/Vip';
import AffiliateIcon from '../../svgs/Affiliate';
import StatisticsIcon from '../../svgs/Statics';
import TransactionsIcon from '../../svgs/Transaction';
import MyBetsIcon from '../../svgs/MyBets';
import SettingsIcon from '../../svgs/Settings';
import StakeSupport from '../../svgs/StakeSupport';
import LiveSupport from '../../svgs/LiveSupport';
import LiveSupportIcon from '../../svgs/LiveSupport';
import LogoutIcon from '../../svgs/Logout';
import TopWalletIcon from '../../svgs/TopWallet';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  background-color: #1a2c38;
      box-shadow: #0003 0 4px 6px -1px, #0000001f 0 2px 4px -1px;
    touch-action: none;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: white;
   @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
`;

const SignInButton = styled(Button)`
  background-color: transparent;
  color: white;
`;

const RegisterButton = styled(Button)`
  background-color: #3498db;
  color: white;
`;

const Header = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: .77rem;
  background-color: #1a2c38;
  color: white;
  box-shadow: #0003 0 4px 6px -1px, #0000001f 0 2px 4px -1px;
  touch-action: none;

  @media (min-width: 768px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
`;



const Balance = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: #2c3137;
  padding: 0.5rem 1rem;
  border-radius: 4px;
`;

const CenterContent = styled.div`
display:flex;
justify-content:center;
width:100%;
`;



const IconButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    &.hide-on-mobile {
      display: none;
    }
  }

  span {
    margin-left: 5px;
    font-size: 12px;

    @media (max-width: 600px) {
      display: none;
    }
  }
`;
const WalletContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const WalletButton = styled.button`
  background-color: #0f212e;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const WalletButtonBlue = styled.button`
  background-color:#1475e1;
  color: white;
  padding: 10px 15px;
  border: none;
  font-weight:bold;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;



const DropdownContent = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  position: absolute;
  background-color:white;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top:45px;
  font-weight:bold;
  border-radius: 5px;
  padding: 5px;
   @media (max-width: 600px) {
 padding: 5px;
   }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: white;
  color:black;
`;

const CurrencyList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const CurrencyItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 3px;
  color:black;
   &:hover {
  
    background-color: #f0f0f0; /* Background color on hover */
  }
`;

const CurrencyIcon = styled.span`
  margin-right: 10px;
`;

const UserDropdownButton = styled.button`
  padding: 8px;
  border-radius: 9999px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #f3f4f6;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
  }
`;

const BlurOverlay = styled.div`
 



    position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255,0.1); /* Slightly opaque white background */
  backdrop-filter: blur(2px); /* Adjust the blur level as needed */
  display: ${props => props.isVisible ? 'block' : 'none'};
  z-index: 1;
`;

const UserDropdownMenu = styled.div`
  position: absolute;
  right: -45px;
  margin-top: 8px;
  width: 140px;
  background-color: white;
  border-radius: 8px;
  font-weight:bold;
  top:25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 4px 0;
`;

const UserDropdownMenuItem = styled.a`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap:9px;
  text-decoration: none;
  color: #374151;
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f3f4f6;
  }
`;

const UserDropdownIconWrapper = styled.span`
  margin-right: 12px;
`;

const RightWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
const SearchInputBoxWrapper = styled.div`
  display: ${props => props.isVisible ? 'block' : 'none'};
  position: fixed;
  top: 60px; // Adjust this value based on your header height
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  z-index: 1001;
`;

const StakeHeader = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const [authMode, setAuthMode] = useState('');
  const [isLogged, setIsLogged]= useState(true)
  const [selectedCurrency, setSelectedCurrency] = useState({ icon: '/assets/images/inr.png', code: 'INR', balance: '0.98' });
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const currencies = [
    { icon: '/assets/images/usdt.png', code: 'USDT', balance: '0.98' },
    { icon: '/assets/images/BTC.png', code: 'BTC', balance: '0.00000011' },
    { icon: '/assets/images/eth.png', code: 'ETH', balance: '0.00000000' },
    { icon: '/assets/images/inr.png', code: 'INR', balance: '0.00000003' },
    { icon: '/assets/images/LTC.png', code: 'LTC', balance: '0.00000000' },
    { icon: '/assets/images/BTC.png', code: 'DOGE', balance: '0.00088973' },
    { icon: '/assets/images/BTC.png', code: 'BCH', balance: '0.00000000' },
    { icon: '/assets/images/BTC.png', code: 'XRP', balance: '0.00000000' },
    { icon: '/assets/images/BTC.png', code: 'EOS', balance: '0.00000000' },
    { icon: '/assets/images/BTC.png', code: 'TRX', balance: '0.05176790' },
  ];
  const menuItems = [
    { icon: WalletIcon, label: 'Wallet' },
    { icon: VauletIcon, label: 'Vault' },
    { icon: VipIcon, label: 'VIP' },
    { icon: AffiliateIcon, label: 'Affiliate' },
    { icon: StatisticsIcon, label: 'Statistics' },
    { icon: TransactionsIcon,label: 'Transactions' },
    { icon:MyBetsIcon, label: 'My Bets' },
    { icon:SettingsIcon,label: 'Settings' },
    { icon: StakeSupport, label: 'Stake Smart' },
    { icon: LiveSupportIcon,label: 'Live support' },
    { icon: LogoutIcon, label: 'Logout' },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpenUser, setIsOpenUser] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

  const toggleDropdownUser = () => setIsOpenUser(!isOpenUser);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filteredCurrencies = currencies.filter(currency =>
    currency.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openSignInModal = () => {
    setAuthMode('signin');
    setIsAuthModalOpen(true);
  };

  const openRegisterModal = () => {
    setAuthMode('register');
    setIsAuthModalOpen(true);
  };

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
    setIsOpen(false);
  };

  const [openVip, setOpenVip] = useState(false)

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  useEffect(()=>{
     const logged= storage.getKey('logged') ? storage.getKey('logged') :false;

     const creditString = localStorage.getItem('credit');
     console.log("credit string", creditString)
if (creditString) {
  try {
    const creditObject = JSON.parse(creditString);
    console.log(creditObject);
    // Use creditObject here
  } catch (error) {
    console.error('Error parsing credit data:', error);
    // Handle the error appropriately
  }
} else {
  console.log('No credit data found in localStorage');
}
     if(logged){
      setIsLogged(logged)
     }

  },[])


  const handleMenuItemClick = (label) => {
    if (label === 'Wallet') {
      setIsWalletModalOpen(true);
    }
    if (label === 'VIP') {
      setOpenVip(true);
    }
    setIsOpenUser(false);
  };
  const balance = 1234.56;

  const handleClose = () => setIsWalletModalOpen(false);
  const handleDeposit = () => { /* Implement deposit logic */ };
  const handleWithdraw = () => { /* Implement withdraw logic */ };
  const handleBuyCrypto = () => { /* Implement buy crypto logic */ };
  const handleTip = () => { /* Implement tip logic */ };
  const handleEnable2FA = () => { /* Implement 2FA enabling logic */ };
  return (

    <>

    {
      isLogged ? 
      
      
      <Header>
         {/* <BlurOverlay isVisible={isSearchFocused} />
     
          {isSearchFocused && (
            <SearchInputBoxWrapper isVisible={isSearchFocused}>
              <SearchInputBox />
            </SearchInputBoxWrapper>
          )} */}
      <Logo>Stakes</Logo>
      <CenterContent>

        <WalletContainer>
          <WalletButton onClick={toggleDropdown}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px', textWrap: 'nowrap' }}>
              <img src={selectedCurrency.icon} style={{ height: '15px', width: '15px', objectFit: 'contain' }} />
              <div style={{fontWeight:'bold'}}>{selectedCurrency.balance}</div>
             <div> {isOpen ? <ChevronUp  size={15} /> : <ChevronDown size={15} />}</div>
            </div>
          </WalletButton>
          <DropdownContent isOpen={isOpen}>
            <SearchInput
              type="text"
              placeholder="Search Currencies"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <CurrencyList>
              {filteredCurrencies.map((currency, index) => (
                <CurrencyItem onClick={() => handleCurrencySelect(currency)} key={index}>
                  <img src={currency.icon} style={{ height: '15px', width: '15px', objectFit: 'contain' }} />
                  <span style={{ marginLeft: '4px' }}>{currency.code}</span>
                  <span style={{ marginLeft: 'auto' }}>{currency.balance}</span>
                </CurrencyItem>
              ))}
            </CurrencyList>

  
          </DropdownContent>

        </WalletContainer>
        <WalletButtonBlue>  {windowWidth <= 600 ?           <TopWalletIcon/> : 'Wallet'}</WalletButtonBlue>
        </CenterContent>

<RightWrapper>
  
<IconButton onClick={()=>setIsSearchFocused(!isSearchFocused)} className="hide-on-mobile">
        <Search size={15} />
        <span>Search</span>
      </IconButton>
      
      <div style={{position:'relative'}}>

        <div style={{display:'flex', alignItems:'center',gap:'8px'}}>
        <IconButton onClick={toggleDropdownUser}>
        {/* <User size={15} /> */}
        <img style={{height:'16px',width:'16px', objectFit:'contain'}} src='/assets/images/stakeicons/userprofilestake.png'/>
      </IconButton>
      <IconButton className="hide-on-mobile">
      <img style={{height:'20px',width:'20px', objectFit:'contain'}} src='/assets/images/stakeicons/stakeheaderbell.png'/>
      </IconButton>

        </div>
      
      {isOpenUser && (
      <UserDropdownMenu>
        {menuItems.map((item, index) => (
          <UserDropdownMenuItem   onClick={() => handleMenuItemClick(item.label)} key={index} href="#">
               {item.icon && React.createElement(item.icon, { size: 24 })}
            {/* <UserDropdownIconWrapper>{item.icon}</UserDropdownIconWrapper> */}
            {/* <img style={{height:'24px', width:'24px', objectFit:'contain'}} src='/assets/images/menuicons/wallet.svg'/> */}
           
            <div> {item.label}</div>
          </UserDropdownMenuItem>
        ))}
      </UserDropdownMenu>
    )}

{isWalletModalOpen && (
       <WalletModal

       onClose={()=>setIsWalletModalOpen(!WalletModal)}
      
       />
      )}
      {openVip && (
       <VipModal
       open={openVip}
       onClose={()=>setOpenVip(!openVip)}
      
       />
      )}

      </div>
     
      <IconButton>
      <img style={{height:'17px',width:'20px', objectFit:'contain'}} src='/assets/images/stakeicons/stakeheaderchat.png'/>
      </IconButton>
</RightWrapper>
    </Header>
    
    
    :  <HeaderContainer>
      <Logo>Stakes</Logo>
      <ButtonGroup>
        <SignInButton onClick={openSignInModal}>Sign In</SignInButton>
        <RegisterButton onClick={openRegisterModal}>Register</RegisterButton>
      </ButtonGroup>
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        initialMode={authMode}
      />
    </HeaderContainer> 

    }

     




   


    </>
  );
};

export default StakeHeader;