import React from "react";

export default class UserAgreement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const styles = {
            card: {
                backgroundColor: '#242A32',
                borderRadius: '10px',
                marginBottom: '20px',
                color: 'white',
                fontFamily: 'Arial, sans-serif',
                overflow: 'hidden', // Ensure the gradient doesn't overflow
            },
            imageContainer: {
                position: 'relative',
                width: '100%',
                borderRadius: '8px 8px 0 0',
                overflow: 'hidden',
            },
            image: {
                width: '100%',
                objectFit: 'cover',
                display: 'block',
            },
            imageOverlay: {
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '50%', // Adjust this value to control the gradient height
                background: 'linear-gradient(to bottom, rgba(36, 42, 50, 0), rgba(36, 42, 50, 1))',
                pointerEvents: 'none', // Allows clicks to pass through to the image
            },
            description: {
                fontSize: '14px',
                padding: '15px 15px 0',
                margin: 0,
            },
            buttonContainer: {
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
                marginBottom: '20px',
            },
            gridContainer: {
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                gap: '20px',
                backgroundColor: '#1A1D24',
            },
            loadMoreButton: {
                backgroundColor: '#215A4A',
                color: '#02db7f',
                border: 'none',
                borderRadius: '8px',
                padding: '12px 24px',
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
                textTransform: 'none',
                boxShadow: 'none',
                display: 'inline-block',
                textAlign: 'center',
                fontFamily: 'Arial, sans-serif',
            },
        };

        return (
           <div style={styles.gridContainer}>
            <div style={styles.card}>
                <div style={styles.imageContainer}>
                    <img 
                        src="https://pejuangkasino04.com/_next/image?url=https%3A%2F%2Fkassadin.fsource.io%2F7c042267e4ff4a22bbc2e69e79a12f28.png&w=1200&q=75" 
                        alt="Bonus Referral" 
                        style={styles.image}
                    />
                    <div style={styles.imageOverlay}></div>
                </div>
                <p style={styles.description}>
                    Invite friends to get a bonus of up to Rp. 100,000
                </p>
                <div style={styles.buttonContainer}>
                    <button style={styles.loadMoreButton}>
                        Load More
                    </button>
                </div>
            </div>
            <div style={styles.card}>
                <div style={styles.imageContainer}>
                    <img 
                    src="https://pejuangkasino04.com/_next/image?url=https%3A%2F%2Fkassadin.fsource.io%2Fcb6651d10f9449c7b10c06801fdd2883.png&w=1200&q=75"
                        alt="Bonus Referral" 
                        style={styles.image}
                    />
                    <div style={styles.imageOverlay}></div>
                </div>
                <p style={styles.description}>
                  Bonus Every Deposit 5% All Games
                </p>
                <div style={styles.buttonContainer}>
                    <button style={styles.loadMoreButton}>
                        Load More
                    </button>
                </div>
            </div>
            <div style={styles.card}>
                <div style={styles.imageContainer}>
                    <img 
                        src="https://pejuangkasino04.com/_next/image?url=https%3A%2F%2Fkassadin.fsource.io%2F3adb771838fa4a8a96952d4808a6bbc9.png&w=1200&q=75"
                        alt="Bonus Referral" 
                        style={styles.image}
                    />
                    <div style={styles.imageOverlay}></div>
                </div>
                <p style={styles.description}>
                2M prize every day, welcome to Pejuang!
                </p>
                <div style={styles.buttonContainer}>
                    <button style={styles.loadMoreButton}>
                        Load More
                    </button>
                </div>
            </div>
            <div style={styles.card}>
                <div style={styles.imageContainer}>
                    <img 
                        src="https://pejuangkasino04.com/_next/image?url=https%3A%2F%2Fkassadin.fsource.io%2Ffc5b7a84ec334a31bc006765a9a1e06a.png&w=1200&q=75"
                        style={styles.image}
                    />
                    <div style={styles.imageOverlay}></div>
                </div>
                <p style={styles.description}>
                Daily Deposit Bonus 20% Slot Games
                </p>
                <div style={styles.buttonContainer}>
                    <button style={styles.loadMoreButton}>
                        Load More
                    </button>
                </div>
            </div>
            </div>
        );
    }
}