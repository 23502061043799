import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { X, Eye, Bitcoin, DollarSign, Wallet as WalletIcon } from 'lucide-react';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  background-color: #1a2c38;
  color: white;
  border-radius: 10px;
  width: 520px;
  height: 500px;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: grey;
  // margin: 16px 0;
`;


const ModalContent = styled.div`

  overflow-y: auto;
  flex-grow: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1e2329;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4a5568;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #718096;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 20px 0;
`;

const Title = styled.h2`
  font-size: 14px;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
`;

const Balance = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const CurrencyList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CurrencyItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const CurrencyInfo = styled.div`
  display: flex;
  // align-items: center;
  gap:5px;
`;

const CurrencyIcon = styled.div`
  margin-right: 10px;
`;

const CurrencyName = styled.span`
  font-size: 14px;
`;

const Footer = styled.div`
  background-color: #0f212e;
  padding: 20px;
  // border-top: 1px solid #4a5568;
  text-align: center;
  color: white;
  font-size: 14px;
`;


const CurrencyValue = styled.span`
  font-size: 14px;
`;
const CurrencyCard = styled.div`
background-color:#213743;
padding:6px;
border-radius:8px;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: ${props => props.primary ? '#1475e1' : '#2f4553'};
  color: white;
  // border: ${props => props.primary ? 'none' : '1px solid #3861fb'};
  padding: 10px;
  border:none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
`;

const SecurityMessage = styled.div`
  font-size: 14px;
  color: #b1bad3;
  margin-top: 20px;
  text-align: left;
`;

const Enable2FAButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const WalletModal = ({ onClose }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);



  const currencies = [
    { name: 'INR', fullName: 'Indian Rupee', value: '0.98', usdValue: '$0.01', icon:'/assets/images/inr.png'},
    { name: 'BTC', fullName: 'Bitcoin', value: '0.00000011', usdValue: '$0.01', icon: '/assets/images/BTC.png' },
    { name: 'LTC', fullName: 'Litecoin', value: '0.00000003', usdValue: '$0.00', icon: '/assets/images/LTC.png' },
    { name: 'DOGE', fullName: 'Dogecoin', value: '0.00088973', usdValue: '$0.00', icon:  '/assets/images/BTC.png' },
    { name: 'TRX', fullName: 'Tron', value: '0.05176790', usdValue: '$0.01', icon:'/assets/images/BTC.png'},
    { name: 'BNB', fullName: 'Binance Coin', value: '0.00000428', usdValue: '$0.00', icon: '/assets/images/BTC.png' },
    { name: 'MATIC', fullName: 'Polygon', value: '0.01390638', usdValue: '$0.01', icon:'/assets/images/BTC.png'},
    { name: 'ETH', fullName: 'Ethereum', value: '0.00000532', usdValue: '$0.01', icon: '/assets/images/eth.png' },
    { name: 'XRP', fullName: 'Ripple', value: '0.02345678', usdValue: '$0.01', icon: '/assets/images/BTC.png' },
  ];

  return (
    <ModalOverlay>
      <Modal ref={modalRef}>
        <Header>
          <Title><WalletIcon size={15} /> Wallet</Title>
          <CloseButton onClick={onClose}><X size={20} /></CloseButton>
        </Header>
        <ModalContent>
          <div style={{padding:'20px'}}>

          <Balance>$0.03 USD</Balance>
          <CurrencyCard>
          <div style={{padding:'4px', marginBottom:'4px'}}>
          <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
              <div style={{color:'#b1bad3', fontSize:'14px'}}>Your Currencies</div>
              <div  style={{color:'#b1bad3', fontSize:'14px'}}>Value</div>
            </div>
          </div>

            <Divider/>
           
          <CurrencyList style={{marginTop:"4px"}}>
            {currencies.map((currency, index) => (
              <CurrencyItem key={index}>
                <CurrencyInfo>
                  {/* <CurrencyIcon>{currency.icon}</CurrencyIcon> */}
                  <img src={currency.icon} style={{height:'25px', width:"25px", objectFit:'contain'}} alt={index}/>
                  <div>
                    <CurrencyName style={{fontSize:'14px'}}>{currency.name}</CurrencyName>
                    <div style={{fontSize:'12px', color:'#b1bad3'}}>{currency.fullName}</div>
                  </div>
                </CurrencyInfo>
                <CurrencyValue>
                  <div style={{textAlign:'right', fontSize:'14px'}}>{currency.value}</div>
                  <div style={{textAlign:'right', fontSize:'12px', color:'#b1bad3'}}>{currency.usdValue}</div>
                </CurrencyValue>
              </CurrencyItem>
            ))}
          </CurrencyList>
          </CurrencyCard>
         
          <ButtonGroup>
            <Button primary>Deposit</Button>
            <Button primary>Withdraw</Button>
            <Button>Buy Crypto</Button>
            <Button>Tip</Button>
          </ButtonGroup>
          </div>
    
        <Footer>
        <SecurityMessage>
            Improve your account security with Two-Factor Authentication
          </SecurityMessage>
          <Enable2FAButton>Enable 2FA</Enable2FAButton>
        </Footer>
        </ModalContent>
      </Modal>
    </ModalOverlay>
  );
};

export default WalletModal;