
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { Provider } from 'react-redux';
import store from './store';

// Styles
import "./Static/css/bootstrap.min.css";
import "./Static/css/animate.css";
import "./Static/css/icons.css";
import 'react-notifications-component/dist/theme.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import "./Static/css/metisMenu.min.css";
import "./Static/css/extra.css";
import "./Static/css/app.css";

import './i18n';

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);


serviceWorker.unregister();


// const _ = require("lodash");
// const axios = require("axios");
// const config = require("../../config");
// const config = require("../../con")

// const Market = {};

// const Rate = Object.create(null);

// /**
//  * Get the price rate from market object
//  */
// Market.rate = function () {
//   return Rate;
// };

// /**
//  * Update from market price rate
//  */
// Market.update = function () {
//   axios({  headers:{
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Methods":"*"
//   },
   
//     url: "https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest",
    
//    method: "GET",
//     headers: {
//       "X-CMC_PRO_API_KEY": config.COIN_MARKET_API,
//     },
//   }).then(function (req) {
//     var res = req.data;
//     var i = 0;
//     var data = res.data;
//     for (i in data) {
//       var b = data[i];
//       var coin = b.symbol;
//       if (
//         coin === "BTC" ||
//         coin === "ETH" ||
//         coin === "LTC" ||
//         coin === "DOGE" ||
//         coin === "XRP" ||
//         coin === "USDT" ||
//         coin === "BNB" ||
//         coin === "BCH" ||
//         coin === "TRX" ||
//         coin === "ADA" ||
//         coin === "TUSD" ||
//         coin === "NEXO" ||
//         coin === "BUSD" ||
//         coin === "USDC" ||
//         coin === "MKR" ||
//         coin === "USDP" ||
//         coin === "SOL" ||
//         coin === "MATIC" ||
//         coin === "SHIB"
//       ) {
//         var price = b["quote"]["USD"]["price"];
//         Rate[coin] = price;
//       }
//     }
//   });
// };

// /**
//  * Update from market price rate For Development Mode
//  */
// Market.dummyUpdate = function () {
//   let api = "https://ibitplay.com/ticker.json";
//   axios({  headers:{
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Methods":"*"
//   },
//     url: api,
//    method: "GET",
//   }).then(function (req) {
//     var res = req.data;
//     Object.keys(res).forEach(function (coin, key) {
//       let val = res[coin];
//       Rate[coin] = parseFloat(val);
//     });
//   });
// };

// module.exports = Market;
