import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { ChevronDown, ChevronRight, Gift, Users, Trophy, Newspaper, MessageSquare, Handshake, Shield, Headphones, Globe, Search, X } from 'lucide-react';
import debounce from 'lodash/debounce';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px; 
  padding: 30px 10px;
  font-family: Arial, sans-serif;
  @media (min-width: 768px) {
    display: none;
  }
`;

const SidebarContainer = styled.div`
  background-color: #1A2C38;
  color: #ffffff;
  width: 100%;
  border-radius: 10px;
`;

const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#213743' : 'transparent')};

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const MenuItemText = styled.span`
  margin-left: 12px;
`;

const SubMenu = styled.div`
  background-color: #213743;
`;

const SubMenuItem = styled.div`
  padding: 10px 20px 10px 52px;
  cursor: pointer;

  &:hover {
    background-color: #2c4a5a;
  }
`;

const ExpandIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-radius: 50%;
  background-color: ${({ open }) => (open ? '#0F5EB4' : '#374E5E')};
  transform: ${({ open }) => (open ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease, background-color 0.3s ease;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #1e2329;
  border-radius: 20px;
  padding: 2px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 12px;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: white;
  padding: 8px 12px;
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #213743;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #0f212e;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  margin-top: 5px;
  width: 109px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const DropdownItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  color: white;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const StyledX = styled(X)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 4px;
`;

const Badge = styled.span`
  background-color: #3498db;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 10px;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #213743;
  background-color: #1e2329;
  border-radius: 20px;
  flex-grow: 1;
  margin-left: 5px;
  padding: 0 10px;
  position: relative;
`;

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  color: white;
  padding: 8px 8px 8px 30px;
  width: 100%;

  &::placeholder {
    color: #7f8c8d;
  }

  &:focus {
    outline: none;
  }
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  left: ${({ isSearchFocused }) => isSearchFocused ? '28%' : '13px'};
  top: 48%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const MentionOverlay = styled.div`
  position: fixed;
  top: 137px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 35, 41, 1);
  z-index: 9999;
  padding: 20px;
  color: #ffffff;
  overflow-y: auto;
  // opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;

  @media (min-width: 768px) {
    display: none;
  }
`;

const RecentSearches = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
`;

const SearchTag = styled.span`
  background-color: #2c3e50;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ClearButton = styled.button`
  background-color: transparent;
  border: none;
  color: #3498db;
  cursor: pointer;
  margin-top: 10px;
  padding: 5px;
  font-size: 13px;
`;

const MentionContent = ({ onOverlayClick }) => (
  <div onClick={onOverlayClick}>
    <p style={{textAlign:'center'}}>Search requires at least 3 characters.</p>
    <div style={{marginTop:'40px'}}>
      <div style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
        <h4 style={{fontSize:'14px'}}>Recent Searches</h4>
        <ClearButton>Clear Search (5)</ClearButton>
      </div>
      <RecentSearches>
        <SearchTag>Monopoly <span><StyledX/></span></SearchTag>
        <SearchTag>Crazy Time <span><StyledX/></span></SearchTag>
        <SearchTag>Sweet Bonanza <span><StyledX/></span></SearchTag>
        <SearchTag>Money Train <span><StyledX/></span></SearchTag>
        <SearchTag>Reactoonz <span><StyledX/></span></SearchTag>
      </RecentSearches>
    </div>
  </div>
);

const AccordionMenu = () => {
  const [promotionsOpen, setPromotionsOpen] = useState(false);
  const [sponsorshipsOpen, setSponsorshipsOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Sports');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const options = [
    { name: 'Casino', badge: null },
    { name: 'Sports', badge: null },
  ];

  const searchContainerRef = useRef(null);

  const handleResize = useCallback(
    debounce(() => {
      const newIsMobile = window.innerWidth < 768;
      setIsMobile(newIsMobile);
      if (!newIsMobile) {
        setIsSearchFocused(false);
        setIsDropdownOpen(false);
      }
    }, 200),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
    };
  }, [handleResize]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setIsSearchFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedOption(option.name);
    setIsDropdownOpen(false);
  };

  const handleMenuItemClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === 'Promotions') {
      setPromotionsOpen(!promotionsOpen);
    } else if (tabName === 'Sponsorships') {
      setSponsorshipsOpen(!sponsorshipsOpen);
    } else if (tabName === 'Language') {
      setLanguageOpen(!languageOpen);
    }
  };

  const handleOverlayClick = (event) => {
    event.stopPropagation();
    setIsSearchFocused(false);
  };

  return (
    <MainWrapper>
      <SearchContainer ref={searchContainerRef}>
        <SearchInputContainer>
          {isSearchFocused && (
            <div style={{ position: 'relative' }}>
              <DropdownButton onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                {selectedOption}
                <ChevronDown size={16} style={{ marginLeft: '5px' }} />
              </DropdownButton>
              {isDropdownOpen && (
                <DropdownMenu>
                  {options.map((option, index) => (
                    <DropdownItem key={index} onClick={() => handleOptionSelect(option)}>
                      {option.name}
                      {option.badge && <Badge>{option.badge}</Badge>}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              )}
            </div>
          )}
          <SearchIconWrapper isSearchFocused={isSearchFocused}>
            <Search size={18} color="#7f8c8d" />
          </SearchIconWrapper>
          <SearchInput
          style={{paddingLeft:'35px'}}
            onFocus={() => setIsSearchFocused(true)}
            type="text"
            placeholder="Search your game"
          />
        </SearchInputContainer>
      </SearchContainer>

      
      <div className="top-casino-bg top-icon-sports"></div>
      <div className="top-casino-bg top-icon-sports"></div>

      {/* <div>jdjdhh</div> */}

      {/* <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>

    </div> */}
      <MentionOverlay isVisible={isSearchFocused && isMobile}>
        <MentionContent onOverlayClick={handleOverlayClick} />
      </MentionOverlay>
      <SidebarContainer>
        <MenuItemWrapper active={activeTab === 'Promotions'} onClick={() => handleMenuItemClick('Promotions')}>
          <Gift size={20} />
          <MenuItemText>Promotions</MenuItemText>
          <ExpandIconWrapper open={promotionsOpen}>
            <ChevronRight size={15} />
          </ExpandIconWrapper>
        </MenuItemWrapper>
        {promotionsOpen && (
          <SubMenu>
            <SubMenuItem>$75k Weekly Raffle</SubMenuItem>
            <SubMenuItem>$100k Race - 24 Hours</SubMenuItem>
            <SubMenuItem>Pragmatic Drops & Wins</SubMenuItem>
            <SubMenuItem>View All</SubMenuItem>
          </SubMenu>
        )}
        <MenuItemWrapper active={activeTab === 'Affiliate'} onClick={() => handleMenuItemClick('Affiliate')}>
          <Users size={20} />
          <MenuItemText>Affiliate</MenuItemText>
        </MenuItemWrapper>
        <MenuItemWrapper active={activeTab === 'VIP Club'} onClick={() => handleMenuItemClick('VIP Club')}>
          <Trophy size={20} />
          <MenuItemText>VIP Club</MenuItemText>
        </MenuItemWrapper>
        <MenuItemWrapper active={activeTab === 'Blog'} onClick={() => handleMenuItemClick('Blog')}>
          <Newspaper size={20} />
          <MenuItemText>Blog</MenuItemText>
        </MenuItemWrapper>
        <MenuItemWrapper active={activeTab === 'Forum'} onClick={() => handleMenuItemClick('Forum')}>
          <MessageSquare size={20} />
          <MenuItemText>Forum</MenuItemText>
        </MenuItemWrapper>
      </SidebarContainer>
      <SidebarContainer>
        <MenuItemWrapper active={activeTab === 'Sponsorships'} onClick={() => handleMenuItemClick('Sponsorships')}>
          <Handshake size={20} />
          <MenuItemText>Sponsorships</MenuItemText>
          <ExpandIconWrapper open={sponsorshipsOpen}>
            <ChevronRight size={20} />
          </ExpandIconWrapper>
        </MenuItemWrapper>
        <MenuItemWrapper active={activeTab === 'Responsible Gambling'} onClick={() => handleMenuItemClick('Responsible Gambling')}>
          <Shield size={20} />
          <MenuItemText>Responsible Gambling</MenuItemText>
        </MenuItemWrapper>
        <MenuItemWrapper active={activeTab === 'Live Support'} onClick={() => handleMenuItemClick('Live Support')}>
          <Headphones size={20} />
          <MenuItemText>Live Support</MenuItemText>
        </MenuItemWrapper>
        <MenuItemWrapper active={activeTab === 'Language'} onClick={() => handleMenuItemClick('Language')}>
          <Globe size={20} />
          <MenuItemText>Language: English</MenuItemText>
          <ExpandIconWrapper open={languageOpen}>
            <ChevronRight size={20} />
          </ExpandIconWrapper>
        </MenuItemWrapper>
      </SidebarContainer>
    </MainWrapper>
  );
};

export default AccordionMenu;