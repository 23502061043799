import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  background-color: #0f212e;
  color: #718096;
  // padding: 0rem 1rem;
  font-family: Arial, sans-serif;
  text-align: center;
`;

const Logo = styled.h1`
  color: #ffffff;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;

const Copyright = styled.p`
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

const BtcPrice = styled.p`
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
`;

const CompanyInfo = styled.p`
  font-size: 0.8rem;
  line-height: 1.4;
  max-width: 800px;
  margin: 0 auto 1.5rem;
`;

const Links = styled.div`
  font-size: 0.8rem;
  
  a {
    color: #718096;
    text-decoration: none;
    margin: 0 0.5rem;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #2d3748;
  margin: 1rem 0;
`;

const StakeBottomFooter = () => {
  return (
    <FooterWrapper>
      <Logo>Stake</Logo>
      <Copyright>© 2024 Stake.com | All Rights Reserved.</Copyright>
      <BtcPrice>1 BTC = $66,666.66</BtcPrice>
      <CompanyInfo>
        Stake is owned and operated by Medium Rare N.V., registration number: 145353, registered address: Korporaalweg 10, Willemstad, Curacao. Contact us at support@stake.com. Payment agent company is Medium Rare Limited with address 7-9 Riga Feraiou, LIZANTIA COURT, Office 310, Agioi Omologites, 1087 Nicosia, Cyprus and Registration number: HE 410775
      </CompanyInfo>
      <Links>
        <a href="mailto:support@stake.com">Support support@stake.com</a> |
        <a href="mailto:partners@stake.com">Partners partners@stake.com</a> |
        <a href="mailto:press@stake.com">Press press@stake.com</a>
      </Links>
      <Divider />
      {/* Add any additional elements like social media icons here */}
    </FooterWrapper>
  );
};

export default StakeBottomFooter;