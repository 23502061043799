import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Search, X } from 'lucide-react';
const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  z-index:500;


  @media (max-width: 1320px) {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 200px;
  }

  @media (max-width: 768px) {
    display: none; /* Hide the component on mobile views */
  }
`;

const SearchContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
`;

const SearchInput = styled.input`
  padding: 10px 40px 10px 40px;
  background-color: #0f212e;
  border: 1px solid #2c3137;
  border-radius: 25px;
  color: #fff;
  font-size: 16px;
  outline: none;
  width: 100%;
//   max-width: 1000px;

  &:focus {
    border-color: #3d4550;
  }

  @media (max-width: 1320px) {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 550px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 8px 35px 8px 35px;
  }
`;

const SearchIcon = styled(Search)`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c7684;

  @media (max-width: 480px) {
    left: 10px;
    width: 18px;
    height: 18px;
  }
`;

const ClearIcon = styled(X)`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c7684;
  cursor: pointer;

  @media (max-width: 480px) {
    right: 10px;
    width: 18px;
    height: 18px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
  right: 200px;
  background-color: #0f212e;
  border: 1px solid #2c3137;
  border-top: none;
  border-radius: 15px;
  padding: 20px;
  display: ${props => props.show ? 'block' : 'none'};
  z-index: 2;
//   max-width: 1000px;
  margin: 0 auto;
  width:100%;

  @media (max-width: 1320px) {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 550px;
  }

  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const BlurBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255,0.1); /* Slightly opaque white background */
  backdrop-filter: blur(2px); /* Adjust the blur level as needed */
  display: ${props => props.show ? 'block' : 'none'};
  z-index: 1;
`;


const RecentSearches = styled.div`
  margin-top: 10px;
`;

const RecentSearchTitle = styled.div`
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin-bottom: 10px;
  font-size: 14px;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const ClearSearch = styled.span`
  // color: #3d92ff;
  cursor: pointer;
  color:white;
`;

const SearchTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const SearchTag = styled.span`
  background-color: #2F4453;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 14px;

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 4px 8px;
  }
`;

const SearchInputBox = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [recentSearches, setRecentSearches] = useState(['mon', 'Monopoly', 'Crazy Time', 'Sweet Bonanza', 'Money Train']);

  useEffect(() => {
    const storedSearches = localStorage.getItem('recentSearches');
    if (storedSearches) {
      setRecentSearches(JSON.parse(storedSearches));
    }
  }, []);

  const handleSearch = (term) => {
    if (term.length >= 3 && !recentSearches.includes(term)) {
      const updatedSearches = [term, ...recentSearches.slice(0, 4)];
      setRecentSearches(updatedSearches);
      localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClear = () => {
    setSearchTerm('');
  };

  const handleClearAllSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem('recentSearches');
  };

  const handleTagClick = (tag) => {
    setSearchTerm(tag);
    handleSearch(tag);
  };

  return (
    <SearchWrapper>
      <SearchContainer>
        <SearchIcon size={20} />
        <SearchInput
          type="text"
          placeholder="Search your game"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 200)}
        />
        {searchTerm && <ClearIcon size={20} onClick={handleClear} />}
      </SearchContainer>
      <Overlay show={isFocused}>
        {searchTerm.length > 0 && searchTerm.length < 3 && (
          <div style={{ color: '#fff', fontSize: '14px' }}>Search requires at least 3 characters.</div>
        )}
        <div style={{textAlign:'center', paddingTop:'14px' , paddingBottom:'34px'}}>Search requires at least 3 characters.</div>
        <RecentSearches>
          <RecentSearchTitle>
            <span>Recent Searches</span>
            <ClearSearch onClick={handleClearAllSearches}>Clear Search ({recentSearches.length})</ClearSearch>
          </RecentSearchTitle>
          <SearchTags>
            {recentSearches.map((search, index) => (
              <SearchTag key={index} onClick={() => handleTagClick(search)}>
                {search}
                <X size={12} />
              </SearchTag>
            ))}
          </SearchTags>
        </RecentSearches>
      </Overlay>
      <BlurBackground show={isFocused} />
    </SearchWrapper>
  );
};

export default SearchInputBox;