import React, { useState } from 'react';
import styled from 'styled-components';

const TabContainer = styled.div`
  display: flex;
  background-color: #0F212E;
  border-radius: 50px;
  padding: 5px;
  margin-bottom: 15px;
   text-wrap:no-wrap;
`;

const Tab = styled.button`
  background-color: ${props => props.active ? '#2F4453' : 'transparent'};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 12px;
  text-wrap:nowrap !important;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.active ? '#2F4453' : '#252a30'};
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  color: white;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  color: #8b949e;
`;

const TableRow = styled.tr`
  background-color: #2F4553;
  border-radius:2px;
`;

const TableCell = styled.td`
  padding: 15px 10px;
`;


const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  background-color: #1a1d21;
  padding: 20px;
  border-radius: 10px;
`;

const GridItem = styled.div`
  display: flex;
  align-items: center;
  color: #4a5568;
  font-size: ${props => props.fontSize || '16px'};
  font-weight: ${props => props.fontWeight || 'normal'};
`;

const Icon = styled.span`
  margin-right: 10px;
  font-size: 24px;
`;

const cryptoData = [
  { icon: '/assets/images/lit1.svg', name: 'Litecoin' },
  { icon: '/assets/images/bit1.svg', name: 'bitcoin' },
  { icon: '/assets/images/ether.svg', name: 'ethereum' },
  { icon: '/assets/images/tr.svg', name: 'TRON' },
  { icon: '/assets/images/rip.svg', name: 'ripple' },
  { icon: '/assets/images/doge.svg', name: 'dogecoin' },
  { icon: '/assets/images/btsh.svg', name: 'bitcoincash' },
  { icon: '/assets/images/teth.svg', name: 'tether' },
  { icon: '/assets/images/', name: 'hub88', subtext: 'Preferred Aggregator' },
  { name: 'Responsible Gambling' },
  { name: 'betblocker' },
  { name: '18+' }
];


const BettingDashboard = () => {
  const [activeTab, setActiveTab] = useState('Casino Bets');

  const renderTable = () => {
    switch(activeTab) {
      case 'Casino Bets':
        return (
          <Table>
            <thead>
              <tr>
                <TableHeader>Game</TableHeader>
                <TableHeader>Payout</TableHeader>
              </tr>
            </thead>
            <tbody>
              <TableRow>
                <TableCell>Speed Baccarat 1</TableCell>
                <TableCell style={{color: '#4caf50'}}>9746.099610...</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Stake Roulette</TableCell>
                <TableCell style={{color: '#4caf50'}}>0.04268487</TableCell>
              </TableRow>
              {/* Add more rows as needed */}
            </tbody>
          </Table>
        );
      case 'Sports Bets':
        return (
          <Table>
            <thead>
              <tr>
                <TableHeader>Event</TableHeader>
                <TableHeader>Bet Amount</TableHeader>
              </tr>
            </thead>
            <tbody>
              <TableRow>
                <TableCell>Tundra - BetBoo...</TableCell>
                <TableCell>4172.729451...</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>NJA - AAB</TableCell>
                <TableCell>41.27760184</TableCell>
              </TableRow>
              {/* Add more rows as needed */}
            </tbody>
          </Table>
        );
      case 'Race Leaderboard':
        return (
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '15px'}}>
              <span>$100k Race - 24 Hours</span>
              <span>Ends in 19 hours</span>
            </div>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Rank</TableHeader>
                  <TableHeader>User</TableHeader>
                  <TableHeader>Wagered</TableHeader>
                  <TableHeader>Prize</TableHeader>
                </tr>
              </thead>
              <tbody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>Hidden</TableCell>
                  <TableCell>$6,503,068.43</TableCell>
                  <TableCell>25.00%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>2</TableCell>
                  <TableCell>Hidden</TableCell>
                  <TableCell>$3,718,578.49</TableCell>
                  <TableCell>12.00%</TableCell>
                </TableRow>
                {/* Add more rows as needed */}
              </tbody>
            </Table>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{backgroundColor: '', padding: '20px', borderRadius: '10px', width:"100%"}}>
      <TabContainer>
        {['Casino Bets', 'Sports Bets', 'Race Leaderboard'].map(tab => (
          <Tab
            key={tab}
            active={activeTab === tab}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </Tab>
        ))}
      </TabContainer>
      {renderTable()}
    </div>
  );
};

export default BettingDashboard;