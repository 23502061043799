import React, { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import C from "../../../Constant";
import socket from "../../../Socket";
import { throttle } from 'lodash';
import Cookies from "js-cookie";
import storage from "../../../Storage";
import { decode, encode, wait } from "../../../Helper";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #2c3540;
  background-color: #1e2329;
  color: white;
`;

const Select = styled.select`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #2c3540;
  background-color: #1e2329;
  color: white;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #00ff00;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 14px;
  margin: 0;
`;

const DateOfBirthContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const PhoneContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Checkbox = styled.input`
  cursor: pointer;
`;

const Label = styled.label`
  color: white;
  cursor: pointer;
`;

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    day: "",
    month: "",
    year: "",
    countryCode: "",
    phoneNumber: "",
    code: "",
  });
  const [errors, setErrors] = useState({});
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [registerData, setRegisterData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const newErrors = { ...errors };
    validateField(name, value, newErrors);
    setErrors(newErrors);
  };

  const validateField = (name, value, newErrors) => {
    switch (name) {
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value.trim()) {
          newErrors.email = "Email is required";
        } else if (!emailRegex.test(value)) {
          newErrors.email = "Invalid email format";
        } else {
          delete newErrors.email;
        }
        break;
      case "username":
        if (!value.trim()) {
          newErrors.username = "Username is required";
        } else if (value.length < 3) {
          newErrors.username = "Username must be at least 3 characters long";
        } else if (value.length > 20) {
          newErrors.username = "Username must not exceed 20 characters";
        } else if (!/^[a-zA-Z0-9_]+$/.test(value)) {
          newErrors.username = "Username can only contain letters, numbers, and underscores";
        } else {
          delete newErrors.username;
        }
        break;
      case "password":
        if (!value) {
          newErrors.password = "Password is required";
        } else if (value.length < 8) {
          newErrors.password = "Password must be at least 8 characters long";
        } else {
          delete newErrors.password;
        }
        break;
      // Add other field validations as needed
    }
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => validateField(key, formData[key], newErrors));
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const emitRegisterUser = useCallback(
    throttle(() => {
      if (!isSubmitting) {
        setIsSubmitting(true);
        socket.emit(
          C.REGISTER_USER,
          encode({
            username: formData.username,
            password: formData.password,
            email: formData.email,
            method: true,
            aff: storage.getKey("aff") ? storage.getKey("aff") : null,
          })
        );
      }
    }, 1000),
    [formData, isSubmitting]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && !isSubmitting) {
      emitRegisterUser();
    }
  };

  useEffect(() => {
    const handleRegisterResponse = (data) => {
      const decodedData = data ? decode(data) : null;
      setRegisterData(decodedData);
      setIsSubmitting(false);
    };

    const handleLoginResponse = (data) => {
      const decodedData = decode(data);
      setLogin(decodedData);
      console.log("login", decodedData);
    };

    socket.on(C.REGISTER_USER, handleRegisterResponse);
    socket.on(C.LOGIN_USER, handleLoginResponse);

    return () => {
      socket.off(C.REGISTER_USER, handleRegisterResponse);
      socket.off(C.LOGIN_USER, handleLoginResponse);
    };
  }, []);

  useEffect(() => {
    if (registerData) {
      console.log("user register data", registerData);
      socket.emit(
        C.LOGIN_USER,
        encode({
          username: registerData?.name,
          password: registerData?.password,
          recaptcha: "google",
        })
      );
    }
  }, [registerData]);

  const setLogin = (data) => {
    console.log("data in login", data);
    if (data.status === true) {
      Cookies.set("session", data.token, { expires: 14 });
      storage.setKey("logged", true);
      storage.setKey("token", data.token);
      storage.setKey("name", data.name);
      storage.setKey("avatar", data.avatar);
      storage.setKey("email", data.email);
      storage.setKey("credit", data.credit);
      storage.setKey("room", data.room);
      storage.setKey("friends", data.friends);

      wait(500).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <Input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
      />
      {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
      <Input
        type="text"
        name="username"
        placeholder="Username"
        value={formData.username}
        onChange={handleChange}
      />
      {errors.username && <ErrorMessage>{errors.username}</ErrorMessage>}
      <Input
        type="password"
        name="password"
        placeholder="Password"
        value={formData.password}
        onChange={handleChange}
      />
      {/* {errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
      <DateOfBirthContainer>
        <Input
          type="number"
          name="day"
          placeholder="Day"
          min="1"
          max="31"
          value={formData.day}
          onChange={handleChange}
        />
        <Select name="month" value={formData.month} onChange={handleChange}>
          <option value="">Month</option>
          <option value="1">January</option>
        </Select>
        <Input
          type="number"
          name="year"
          placeholder="Year"
          min="1900"
          max="2023"
          value={formData.year}
          onChange={handleChange}
        />
      </DateOfBirthContainer> */}
      {/* {errors.dob && <ErrorMessage>{errors.dob}</ErrorMessage>}
      <PhoneContainer>
        <Select
          name="countryCode"
          value={formData.countryCode}
          onChange={handleChange}
        >
          <option value="">Country code</option>
          <option value="+1">+1 (US)</option>
          <option value="+44">+44 (UK)</option>
        </Select>
        <Input
          type="tel"
          name="phoneNumber"
          placeholder="Phone number"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </PhoneContainer>
      {errors.phoneNumber && <ErrorMessage>{errors.phoneNumber}</ErrorMessage>}
      <CheckboxContainer>
        <Checkbox
          type="checkbox"
          id="showCode"
          checked={showCodeInput}
          onChange={() => setShowCodeInput(!showCodeInput)}
        />
        <Label htmlFor="showCode">Code (Optional)</Label>
      </CheckboxContainer>
      {showCodeInput && (
        <Input
          type="text"
          name="code"
          placeholder="Enter code"
          value={formData.code}
          onChange={handleChange}
        />
      )}
      {errors.code && <ErrorMessage>{errors.code}</ErrorMessage>} */}
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting ? "Creating Account..." : "Create Account"}
      </Button>
    </Form>
  );
};

export default RegisterForm;