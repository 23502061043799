import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align:center;
  animation: ${fadeIn} 1.5s ease-out;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align:center;
  animation: ${slideIn} 1.5s ease-out 0.5s both;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const AnimatedText = styled.div`
  font-size: 1.2rem;
  animation: ${fadeIn} 1.5s ease-out 1s both;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const SportsBook = () => {
  return (
    <Container>
      <Title>Coming Soon</Title>
      <Subtitle>We're working hard to bring you something amazing!</Subtitle>
      <AnimatedText>Stay tuned for updates...</AnimatedText>
    </Container>
  );
};

export default SportsBook;