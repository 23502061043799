import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import GamesCardComponent from './Games';

const promoData = [
    {
        id: 1,
        title: "Daily Races",
        description: "Play in our $100,000 Daily Race Read More",
        buttonText: "Race Now",
        imageUrl: "/assets/images/first.jpg"
    },
    {
        id: 2,
        title: "Weekly Raffle",
        description: "Share in $75,000 each week Read More",
        buttonText: "Learn More",
        imageUrl: "/assets/images/second.jpg"
    },
    {
        id: 3,
        title: "Conquer the Casino",
        description: "Win a share in $50,000 every week Read More",
        buttonText: "Play Now",
        imageUrl: "/assets/images/third.jpg"
    },
    {
        id: 4,
        title: "Stake vs Eddie",
        description: "Win a share in $50,000 every week Read More",
        buttonText: "Spin Now",
        imageUrl: "/assets/images/fourth.jpg"
    },
    {
        id: 5,
        title: "Chaos Collector",
        description: "Win a share in $50,000 every week Read More",
        buttonText: "Register",
        imageUrl: "/assets/images/fifth.jpg"
    },
    {
        id: 6,
        title: "The Level Up",
        description: "Win a share in $50,000 every week Read More",
        buttonText: "Play Live",
        imageUrl: "/assets/images/sixth.jpg"
    }
];

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
`;

const CarouselTrack = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Card = styled.div`
  background-color: #213744;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin: 0.5rem;
  width: 24rem;
  height: 13.5rem;
  flex: 0 0 auto;
  scroll-snap-align: start;
`;

const CardContent = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

const CardTextContent = styled.div`
  padding-left: 1rem;
  width: 46%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PromoTag = styled.div`
  color: black;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  width: 44px;
  height: 18px;
`;

const CardTitle = styled.h3`
  color: white;
  font-size:18px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-wrap: nowrap;
`;

const CardDescription = styled.p`
  color: #d1d5db;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  width: 10rem;
`;

const CardButton = styled.button`
  color: white;
  background-color: transparent;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;

  &:hover {
    background-color: #2563eb;
  }
`;

const CardImageContainer = styled.div`
  width: 54%;
  position: absolute;
  right: 0;
  height: 100%;
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(31, 41, 55, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  // z-index: 10;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(55, 65, 81, 0.9);
  }
`;

const PrevButton = styled(NavButton)`
  left: 0.5rem;
`;

const NextButton = styled(NavButton)`
  right: 0.5rem;
`;
const Top = styled.div`
max-width: 1320px;
margin: 0 auto;

padding: 0px 10px;
  @media (min-width: 1321px) {
    padding-left: 40px;
    padding-right:40px;
  }
`;

const PromoCard = ({ title, description, buttonText, imageUrl }) => (
  <Card>
    <CardContent>
      <CardTextContent>
        <PromoTag>Promo</PromoTag>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
        <CardButton>Play Now</CardButton>
      </CardTextContent>
      <CardImageContainer>
        <CardImage src={imageUrl} alt={title} />
      </CardImageContainer>
    </CardContent>
  </Card>
);

const CasinoPage = () => {
  const trackRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollButtons = () => {
    const { current } = trackRef;
    if (current) {
      setCanScrollLeft(current.scrollLeft > 0);
      setCanScrollRight(
        current.scrollLeft < current.scrollWidth - current.clientWidth
      );
    }
  };

  useEffect(() => {
    const { current } = trackRef;
    if (current) {
      current.addEventListener('scroll', checkScrollButtons);
      checkScrollButtons();
      return () => current.removeEventListener('scroll', checkScrollButtons);
    }
  }, []);

  const scroll = (direction) => {
    const { current } = trackRef;
    if (current) {
      const scrollAmount = direction === 'left' ? -current.offsetWidth : current.offsetWidth;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (

    <Top>
        <CarouselContainer>
      <CarouselTrack ref={trackRef}>
        {promoData.map((promo) => (
          <PromoCard key={promo.id} {...promo} />
        ))}
      </CarouselTrack>
      {canScrollLeft && (
        <PrevButton onClick={() => scroll('left')}>
          <ChevronLeft size={24} />
        </PrevButton>
      )}
      {canScrollRight && (
        <NextButton onClick={() => scroll('right')}>
          <ChevronRight size={24} />
        </NextButton>
      )}
    </CarouselContainer>

    <GamesCardComponent/>
    </Top>

  );
};

export default CasinoPage;