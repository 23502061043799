import React from 'react';
import styled from 'styled-components';
import { Menu, Gift, Star, Award, Newspaper, MessageSquare, Shield, Headphones, Globe } from 'lucide-react';
import { Link } from 'react-router-dom';
import SidebarContent from '../../BottomNavBar/SidebarContent';

const SidebarContainer = styled.div`
  height: 100%;
  background-color:#0F212E;
  color: #ffffff;
  transition: width 0.3s ease;
  width: ${props => props.expanded ? '265px' : '60px'};
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 999;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1000;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .2), 0 4px 6px -2px rgba(0, 0, 0, .1);
`;

const ToggleButton = styled.button`
    background-color:#0F212E;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  padding:15px 15px;
  text-align: left;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .2), 0 4px 6px -2px rgba(0, 0, 0, .1);
  z-index: 1001;
  position: relative;
`;

const NavLinksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .2), 0 4px 6px -2px rgba(0, 0, 0, .1);
  gap: 4px;
  background-color:#0F212E;
  align-items: center;
  justify-content: center;
   padding:10px 15px;
   margin-top:4px;
`;

const NavCradWrapper = styled.div`
  padding: 8px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const NavCrad = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: #1a2c38;
  border-radius: .25rem;
  color: #fff;
  gap: 20px;
`;

const IconWrapper = styled.div`
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #2F4553;
  }
`;

const StakeSidebar = ({ expanded, setExpanded }) => {
    const toggleSidebar = (e) => {
        e.stopPropagation();
        console.log("clicked");
        setExpanded(!expanded);
    };

    return (
        <SidebarContainer expanded={expanded}>
            <TopSection>
                <ToggleButton onClick={toggleSidebar}>
                    <Menu size={24} />
                </ToggleButton>
                {expanded && (
                    <NavLinksWrapper>
                        <Link to='/home/casino' className='side-image item-image'>
                            <span>Casino</span>
                        </Link>
                        <a href='/' className='side-image-sports item-image-sports'>
                            <span>Sports</span>
                        </a>
                    </NavLinksWrapper>
                )}
            </TopSection>

            {!expanded && (
                <NavCradWrapper>
                    <Link to='/home/casino' className='side-image-small item-image-small'></Link>
                    <div className='side-image-small-2 item-image-smal-2'></div>
                </NavCradWrapper>
            )}

            {expanded ? (
                <div style={{padding:'12px'}}><SidebarContent /></div>
            ) : (
                <NavCradWrapper>
                    <NavCrad>
                        <IconWrapper><Gift size={17} /></IconWrapper>
                        <IconWrapper><Star size={17} /></IconWrapper>
                        <IconWrapper><Award size={17} /></IconWrapper>
                        <IconWrapper><Newspaper size={17} /></IconWrapper>
                        <IconWrapper><MessageSquare size={17} /></IconWrapper>
                        <IconWrapper><Shield size={17} /></IconWrapper>
                    </NavCrad>
                    <NavCrad>
                        <IconWrapper><Shield size={17} /></IconWrapper>
                        <IconWrapper><Headphones size={17} /></IconWrapper>
                        <IconWrapper><Award size={17} /></IconWrapper>
                        <IconWrapper><Globe size={17} /></IconWrapper>
                    </NavCrad>
                </NavCradWrapper>
            )}
        </SidebarContainer>
    );
};

export default StakeSidebar;