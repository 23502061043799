// import React from "react";
// import { Modal } from "react-bootstrap";
// import PerfectScrollbar from 'perfect-scrollbar';
// import { wait, isMobile, isTablet, Event, __, hasClass } from "./Helper";
// import StakeLayout from "./App/Components/StakeLayout";

// const chatSideBarWidth = 330;

// class Layout extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             chat: true
//         }
//     }

//     componentDidMount() {
//         if (isMobile() || isTablet()) {
//             this.setState({ chat: false });
//         }
//         window.addEventListener('resize', this.handleResize);

//         wait(500).then(() => {
//             this.handleResize();
//         });

//         Event.on('toggle_sidebar', (show) => {
//             wait(100).then(() => {
//                 this.handleResize();
//             });
//         })

//         Event.on('toggle_chat', (show) => {
//             this.setState({ chat: show })
//             wait(100).then(() => {
//                 this.handleResize();
//             });
//         })
//     }

//     componentWillUnmount() {
//         window.removeEventListener('resize', this.handleResize);
//     }

//     handleResize = () => {
//         const leftSidenav = document.querySelector('.left-sidenav');
//         const leftSidebar = document.querySelector('.left-sidebar');
//         const pageContent = document.querySelector('#page-content');
//         const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

//         if (pageContent !== null) {
//             if (!isMobile()) {
//                 if (this.state.chat) {
//                     pageContent.style.marginLeft = '40px';
//                 }
//                 else {
//                     pageContent.style.marginLeft = 'auto';
//                 }

//                 let pWidth = this.state.chat === true ? clientWidth - 600 : clientWidth;
//                 // pageContent.style.width = + 'px';
//                 pageContent.style.width = + 'px';

//                 if (clientWidth < 1200) {
//                     if (leftSidebar) {
//                         pageContent.style.marginLeft = '0';

//                         pWidth = this.state.chat === true ? clientWidth - 416 : clientWidth;
//                         // pageContent.style.width = pWidth + 'px';

//                         leftSidebar.classList.remove('big');
//                         leftSidebar.classList.add('min');
//                         leftSidenav.classList.remove('big');
//                         leftSidenav.classList.add('min');
//                     }
//                 }
//                 else {
//                     if (leftSidebar) {
//                         leftSidebar.classList.remove('min');
//                         leftSidebar.classList.add('big');
//                         leftSidenav.classList.remove('min');
//                         leftSidenav.classList.add('big');
//                     }
//                 }
//             }
//             else {

//             }

//         }
//     };

//     render() {
//         return (
// <>
// <StakeLayout/>
//             < div style={{height:'100vh', overflow:'auto'}}>
//                 {this.props.children}
//             </div>

// </>
          
//         );
//     }
// }

// export default Layout;
import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import styled from 'styled-components';
import { wait, isMobile, isTablet, Event } from "./Helper";
import StakeLayout from "./App/Components/StakeLayout";
import StakeSidebar from "./App/Components/StakeLayout/StakeSidebar";
import StakeHeader from "./App/Components/StakeLayout/StakeHeader";
import StakeFooter from "./App/Components/StakeLayout/StakeFooter";
import StakeBottomFooter from "./App/Components/StakeLayout/StakeBottomFooter";
import BottomNavbar from "./App/Components/BottomNavBar/BottomNavbar";

const chatSideBarWidth = 330;

const AppContainer = styled.div`
    display: grid;
    grid-template-areas: 
      "sidebar header"
      "sidebar main";
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    height: 100vh;
    // background-color: #1e2329;
      background-color: #1A2C38;
    color: white;
    overflow: hidden;
`;

const HeaderWrapper = styled.div`
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: 1000;
`;

const SidebarWrapper = styled.div`
    grid-area: sidebar;
    overflow-y: auto;
    height: 100vh;
`;

const MainContent = styled.main`
    grid-area: main;
    overflow-y: auto;
    // padding: 20px;
`;

const Layout = (props) => {
    const [chat, setChat] = useState(true);
    const [sidebarExpanded, setSidebarExpanded] = useState(true);

    // const handleResize = useCallback(() => {
    //     const leftSidenav = document.querySelector('.left-sidenav');
    //     const leftSidebar = document.querySelector('.left-sidebar');
    //     const pageContent = document.querySelector('#page-content');
    //     const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

    //     if (pageContent !== null) {
    //         if (!isMobile()) {
    //             if (chat) {
    //                 pageContent.style.marginLeft = '40px';
    //             } else {
    //                 pageContent.style.marginLeft = 'auto';
    //             }

    //             let pWidth = chat ? clientWidth - 600 : clientWidth;
    //             pageContent.style.width = pWidth + 'px';

    //             if (clientWidth < 1200) {
    //                 if (leftSidebar) {
    //                     pageContent.style.marginLeft = '0';

    //                     pWidth = chat ? clientWidth - 416 : clientWidth;
    //                     pageContent.style.width = pWidth + 'px';

    //                     leftSidebar.classList.remove('big');
    //                     leftSidebar.classList.add('min');
    //                     leftSidenav.classList.remove('big');
    //                     leftSidenav.classList.add('min');
    //                 }
    //             } else {
    //                 if (leftSidebar) {
    //                     leftSidebar.classList.remove('min');
    //                     leftSidebar.classList.add('big');
    //                     leftSidenav.classList.remove('min');
    //                     leftSidenav.classList.add('big');
    //                 }
    //             }
    //         }
    //     }
    // }, [chat]);

    // useEffect(() => {
    //     if (isMobile() || isTablet()) {
    //         setChat(false);
    //     }
    //     window.addEventListener('resize', handleResize);

    //     wait(500).then(() => {
    //         handleResize();
    //     });

    //     Event.on('toggle_sidebar', (show) => {
    //         wait(100).then(() => {
    //             handleResize();
    //         });
    //     });

    //     Event.on('toggle_chat', (show) => {
    //         setChat(show);
    //         wait(100).then(() => {
    //             handleResize();
    //         });
    //     });

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [handleResize]);

    return (
        <>
        <AppContainer>
            <SidebarWrapper>
                <StakeSidebar expanded={sidebarExpanded} setExpanded={setSidebarExpanded} />
            </SidebarWrapper>
            <HeaderWrapper>
                <StakeHeader/>
            </HeaderWrapper>
            <MainContent>
                {props.children}
            <StakeFooter/>
            {/* <StakeBottomFooter/> */}
            </MainContent>
            <BottomNavbar/>
        </AppContainer>
    </>
    );
};

export default Layout;


