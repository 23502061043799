import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import styled from "styled-components";
import AccordionMenu from "./BottomOverlay";

const NavContainer = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0f212e;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  width: 100%;
  z-index: 999;
  @media (min-width: 769px) {
    display: none;
  }
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ active }) => (active ? "#3498db" : "#bdc3c7")};
  font-size: 12px;
  cursor: pointer;
  position: relative;
  padding-top: 5px;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #3498db;
    opacity: ${({ active }) => (active ? 1 : 0)};
    transition: opacity 0.3s ease;
  }
`;

const Overlay = styled.div`
  position: fixed;
  bottom: ${({ show }) => (show ? "0" : "-100%")};
  left: 0;
  right: 0;
  height: 100%;
  top: 52px;
  background-color: #1e2329;
  transition: bottom 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  z-index: ${({ isWebView }) => (isWebView ? -1 : 100)};
`;

const NavImage = styled.img`
  height: 19px;
  width: 16px;
  object-fit: contain;
  filter: ${({ active }) => (active ? "brightness(0) invert(1)" : "none")};
`;

const BottomNavbar = () => {
  const location = useLocation();
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWebView(window.innerWidth >= 769);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isActive = (path) => location.pathname === path;
  const handleBrowseClick = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  return (
    <>
      <NavContainer>
        <NavItem active={isActive("/")} onClick={handleBrowseClick}>
          <Link to={"/"} style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit"
          }}>
            <NavImage
              active={isActive("/")}
              src="/assets/images/stakeicons/browsestake.png"
              alt="Browse"
            />
            <span style={{ fontSize: "12px", color: "white", fontWeight: "bold" }}>
              Browse
            </span>
          </Link>
        </NavItem>
        <NavItem active={isActive("/home/casino")}>
          <Link to="/home/casino" style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit"
          }}>
            <NavImage
              active={isActive("/home/casino")}
              src="/assets/images/stakeicons/casinostake.png"
              alt="Casino"
            />
            <span style={{ fontSize: "12px", color: "white", fontWeight: "bold" }}>
              Casino
            </span>
          </Link>
        </NavItem>
        <NavItem onClick={()=>setOverlayVisible(false)} active={isActive("/mybets")}>
          <Link to="/mybets" style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "center",
          }}>
            <NavImage
              active={isActive("/mybets")}
              src="/assets/images/stakeicons/browsestake.png"
              alt="Bets"
            />
            <span style={{ fontSize: "12px", color: "white", fontWeight: "bold" }}>
              Bets
            </span>
          </Link>
        </NavItem>
        <NavItem onClick={()=>setOverlayVisible(false)} active={isActive("/sports")}>
          <Link to="/sports" style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "center",
          }}>
            <NavImage
              active={isActive("/sports")}
              src="/assets/images/stakeicons/sportsstake.png"
              alt="Sports"
            />
            <span style={{ fontSize: "12px", color: "white", fontWeight: "bold" }}>
              Sports
            </span>
          </Link>
        </NavItem>
        <NavItem active={isActive("/chat")}>
          <Link to="/chat" style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "center",
          }}>
            <NavImage
              active={isActive("/chat")}
              src="/assets/images/stakeicons/chatstake.png"
              alt="Chat"
            />
            <span style={{ fontSize: "12px", color: "white", fontWeight: "bold" }}>
              Chat
            </span>
          </Link>
        </NavItem>
      </NavContainer>
      {isOverlayVisible && (
        <Overlay show={isOverlayVisible} isWebView={isWebView}>
          <AccordionMenu />
        </Overlay>
      )}
    </>
  );
};

export default BottomNavbar;