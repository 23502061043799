import React, {Component} from "react";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {__, decode, encode, forceSatoshiFormat, fixDate, wait} from "../../../../Helper";
import C from "../../../../Constant";
import axios from "axios";

const C_ = React.createElement;

class DepositArchive extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            deposits: []
        };
        this.source = axios.CancelToken.source();
    }

    componentDidMount(){
        this._isMounted = true;
        socket.emit(C.WALLET_HISTORY);
        socket.on(C.WALLET_HISTORY, data => this.makeHistory(decode(data)));
        this.fetchDepositHistory();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

     fetchDepositHistory = async () => {
        this.setState({loading:true})
        try {
            
    
            const username = storage.getKey('name');
    
            const response = await axios.post('https://api.ibitplay.com/inrhistory', {
                name: username
            }, {
                cancelToken: this.source.token, // Using cancel token
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': '*',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
       console.log(response.data);
       if (response.data && Array.isArray(response.data)) {
        const mappedData = response.data.map(deposit => ({
            date: fixDate(deposit.date), 
            amount: forceSatoshiFormat(deposit.amount), 
            status: deposit.status,
            trxid: deposit.trxid
        }));
        console.log("deposit history Data:", mappedData); // Debugging
        // setDeposits(mappedData);
        this.setState({deposits:mappedData})
        console.log("State set for Deposits"); 
    } else {
        console.error("Unexpected API response format");
    }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                console.error("API Error:", error);
            }
        } finally {
            this.setState({loading:false})
        }
    
        return () => source.cancel('Operation canceled by the user.');
    };

    makeHistory(data){
        if(this._isMounted)
        {
            let array = data.deposit;
            console.log('data is------->',data)
            if(array) {
                array.forEach((deposit, i) => {
                    let block = <DepositTable data={deposit} key={i}/>;
                    // this.setState(state => ({deposits: [block, ...state.deposits]}));
                });
            }
            this.setState({ loading: false });
            // console.log(deposit);
        }
    }



    render() {
       
        let { loading } = this.state;
        return(
            <>
                    <>
                        <div className="table-responsive mt-2" style={{ minHeight: 460 }}>
                            <table className="table table-hover deposit">
                                <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Transaction</th>
                                </tr>
                                </thead>
                                {/* {this.state.deposits.length > 0 &&
                                    this.state.deposits
                                } */}
                                 <tbody>
                        {this.state.deposits.map((deposit, index) => (
                            <tr key={index}>
                                <td>{deposit.date}</td>
                                <td>{deposit.amount}</td>
                                <td>{deposit.status}</td>
                                <td>
                                    <input 
                                        className="form-control" 
                                        readOnly={true} 
                                        value={deposit.trxid} 
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                            </table>
                            {loading ?
                                <div className="text-center" style={{ minHeight: 460 }}>
                                       <div className="spinner-grow text-white my-3" role="status" />
                                </div>
                            :
                                <>
                                {this.state.deposits.length === 0 &&
                                    <div className="alert bg-soft-dark mt-2 w-100 font-13 text-center text-upper">
                                        [ Your deposit listssss is empty ]
                                    </div>
                                }
                                </>
                            }
                        </div>
                    </>
            </>
        );
    }
}

function DepositTable(props){
    const { amount, status, date, txtid, coin } = props.data;
    return(
            <tr>
                <td>{fixDate(date)}</td>
                <td>{forceSatoshiFormat(amount) + ' ' + __.upperCase(coin)}</td>
                <td>{status}</td>
                <td>
                    <input className="form-control" readOnly={true} value={txtid} />
                </td>
            </tr>
    );
}

export default DepositArchive;
