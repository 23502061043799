import React from 'react';
import styled from 'styled-components';
import './index.css'

const FooterContainer = styled.div`
  background-color: #0f212e;
  color: #6c7a89;
  font-family: Arial, sans-serif;

  padding: 0px 10px;
  max-width:1320px;
  margin:0 auto;
  @media (min-width: 1321px) {
    padding-left: 40px;
    padding-right:40px;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const CryptoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 10px;
  justify-content: center;

  @media (max-width: 600px) {
    justify-content: center;
    & > * {
      flex: 1 1 calc(50% - 20px); /* Adjusting for gap */
      max-width: calc(50% - 20px); /* Adjusting for gap */
    }
  }
`;


const CryptoItem = styled.div`
  text-align: center;
`;

const PartnersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const PartnerItem = styled.div`
  text-align: center;
  padding: 10px;
  // background-color: #2c3e50;
  border-radius: 5px;
`;

const Divider = styled.hr`
  border: 0;
  border-top: 1px solid #34495e;
  // margin: 20px 0;
  margin-bottom:20px;
`;

const BottomSection = styled.div`
  text-align: center;
`;

const PartnerFooter = () => {
  return (
    <FooterContainer>
      <Section>
      <Divider />
        <CryptoGrid>
          {/* {['Li', 'Bitcoin', 'Ethereum', 'TRON', 'Ripple', 'Dogecoin', 'Bitcoincash', 'Tether'].map((crypto) => (
            <CryptoItem key={crypto}>{crypto}</CryptoItem>
          ))} */}
          <img src='/assets/images/litecoin.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakecoins/bitcoinstake.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakecoins/etherumstake.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakecoins/tronstake.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakecoins/ripplestake.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakecoins/doggestake.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakeicons/bitcash.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakeicons/hub.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakeicons/tether.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakeicons/responsible.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakeicons/betblocker.svg' style={{height:'40px', width:'130px' }}/>
          <img src='/assets/images/stakeicons/eightplus.svg' style={{height:'40px', width:'130px' }}/>
        </CryptoGrid>
        {/* <CryptoGrid>
          {['Hub88', 'Responsible Gambling', 'Betblocker', '18+'].map((item) => (
            <CryptoItem key={item}>{item}</CryptoItem>
          ))}
        </CryptoGrid> */}
      </Section>
      
      <Divider />
      
      <Section>
        <PartnersGrid>
          <PartnerItem>
            <img  src='/assets/images/stakecoins/curacao.png' style={{height:'46px', objectFit:'contain', width:'120px'}}/>
          </PartnerItem>
          <PartnerItem>
          <img src='/assets/images/stakeicons/p1.svg' style={{height:'46px', objectFit:'contain', width:'120px'}}/>

          </PartnerItem>
          <PartnerItem>
          <img src='/assets/images/stakeicons/p2.svg' style={{height:'46px', objectFit:'contain', width:'120px'}}/>

          </PartnerItem>
          <PartnerItem>
          <img src='/assets/images/stakeicons/p3.svg' style={{height:'46px', objectFit:'contain', width:'120px'}}/>

          </PartnerItem>
        </PartnersGrid>
      </Section>
      
      <Divider />
      
      {/* <BottomSection>
        <div>Stake</div>
        <div>© 2024 Stake.com | All Rights Reserved.</div>
        <div>1 BTC = $69,783.67</div>
      </BottomSection> */}
    </FooterContainer>
  );
};

export default PartnerFooter;