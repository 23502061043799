import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Spinner, Alert, FormControl, Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import Carousel from "../Home/crousel";
import { Helmet } from 'react-helmet';
import Popular from "../Home/Popular";
import Games from "../Home/Games";
import Slots from "../../../Slots/Slots";
import { BRAND, isMobile } from "../../../../Helper";
import { withCookies } from 'react-cookie';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import BottomNavBar from "../../../Components/BottomNavBar/BottomNavbar";
const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #1b2336;
`;

const FullScreenIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  display: ${(props) => (props.loading ? 'none' : 'block')};
`;

const BackButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: none;
  border: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
`;

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #000;
`;

class Live extends Component {
  constructor(props) {
    super(props);
    this.state = {
      padding: 'py-2',
      selectedSection: 'home',
      selectedProvider: 'All',
      sectionIndex: 0,
      slideSelected: null,
      gamesEvolution: [],
      gamesPragmatic: [],
      gamesPragmaticlive: [],
      gamesEvoplay: [],
      gamesGenisis: [],
      gamesPgsoft: [],
      gamesLiveCasinoProvider: [],
      gamesSpribe: [],
      gamesSports: [],
     gamesMostExpensive:[],
      error: null,
      loading: false,
      message: null,
      showMore: false,
      showMoreP: false,
      showMoreO: false,
      showMoreLCP: false,
      showMoreSP: false,
      showMoreSB: false,
      isMobileView: window.innerWidth <= 768,
      searchQuery: '',
      showIframe: false,
      iframeUrl: '',
      iframeLoading: true, 
    };
    this.sections = ["/assets/images/evolutionicon.png", "/assets/images/ezubi.png", "/assets/images/pragmatic.png", "/assets/images/netent.png", "/assets/images/novomatic.png"];
    this.livecasino = ["/assets/images/live1.png", "/assets/images/live2.png", "/assets/images/live3.jpg", "/assets/images/live4.jpg", "/assets/images/live5.jpg", "/assets/images/live6.png"];
    this.itemsToShow = 5;
  }

  handleGameLaunch = async (gameCode, provider) => {
    this.setState({ loading: true, message: null });
    try {
      const { cookies } = this.props;
      const uid = cookies.get('uid');

      if (!uid) {
        throw new Error("Please login to play live game");
      }

      const response = await fetch('https://api.ibitplay.com/game_launch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_code: String(uid),
          provider_code: provider,
          game_code: gameCode
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.status === 1) {
        this.setState({ iframeUrl: result.launch_url, showIframe: true, iframeLoading: true  });
      } else if (result.status === 0 && result.detail === "user_code is not allowed to be empty") {
        throw new Error("Please login to play live game");
      } else {
        throw new Error("Failed to launch game: " + result.msg);
      }
    } catch (error) {
      this.setState({ message: { type: 'error', text: error.message } });
    } finally {
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  };

  handleGameLaunchNew = async (gameCode, provider) => {
    this.setState({ loading: true, message: null });
    try {
      const { cookies } = this.props;
      const uid = cookies.get('uid');
      if (!uid) {
        throw new Error("Please login to play live game");
      }
      const response = await fetch('https://api.ibitplay.com/game_launch_new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_code: String(uid),
          game_code: gameCode
        }),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
  
      if (result.status === "success" && result.content && result.content.game && result.content.game.url) {
        this.setState({ iframeUrl: result.content.game.url, showIframe: true , iframeLoading: true });
      } else if (result.status === "fail" && result.detail === "user_code is not allowed to be empty") {
        throw new Error("Please login to play live game");
      } else {
        throw new Error("Failed to launch game: " + result.error);
      }
    } catch (error) {
      this.setState({ message: { type: 'error', text: error.message } });
    } finally {
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  };

  handleBackButton = () => {
    this.setState({ showIframe: false, iframeUrl: '', iframeLoading: true });  
  };
  
  handleIframeLoad = () => {
    this.setState({ iframeLoading: false });
  };
  
  componentDidMount() {
    this._isMounted = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (isMobile()) {
      this.setState({ padding: 'p-1' });
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.fetchGames("EVOLUTION").then(games => {
      this.setState({ gamesEvolution: games.map(game => ({ ...game, provider_code: "EVOLUTION" })) });
    });
    this.fetchGames("PRAGMATICLIVE").then(games => {
      this.setState({ gamesPragmatic: games.map(game => ({ ...game, provider_code: "PRAGMATICLIVE" })) });
    });
    this.fetchGames("PRAGMATIC").then(games => {
      this.setState({ gamesPragmaticlive: games.map(game => ({ ...game, provider_code: "PRAGMATIC" })) });
    });
    this.fetchGames("EVOPLAY").then(games => {
      this.setState({ gamesEvoplay: games.map(game => ({ ...game, provider_code: "EVOPLAY" })) });
    });
    this.fetchGames("DREAMTECH").then(games => {
      this.setState({ gamesGenisis: games.map(game => ({ ...game, provider_code: "DREAMTECH" })) });
    });
    this.fetchGames("PGSOFT").then(games => {
      this.setState({ gamesPgsoft: games.map(game => ({ ...game, provider_code: "PGSOFT" })) });
    });
    // this.fetchGamesNew("live_dealers").then(games => {
    //   const filteredGames = games.filter((game, index) => [1,6,0].includes(index));
    //   this.setState({ gamesLiveCasinoProvider: filteredGames.map(game => ({ ...game, provider_code: "live_dealers" })) });
    // });
    this.fetchGamesNew("spribe").then(games => {
      const filteredGames = games.filter((game, index) => [8,5,6,7].includes(index));
      this.setState({ gamesSpribe: filteredGames.map(game => ({ ...game, provider_code: "spribe" })) });
    });   
    // this.fetchGamesNew("more_expensive").then(games => {
    //   this.setState({ gamesMostExpensive: games.map(game => ({ ...game, provider_code: "more_expensive" })) });
    //   gamesLiveCasinoProvider.concat(gamesMostExpensive);
    // }); 
this.fetchGamesNew("more_expensive").then(moreExpensiveGames => {
  const updatedMoreExpensiveGames = moreExpensiveGames.map(game => ({ ...game, provider_code: "more_expensive" }));
  this.setState(prevState => ({
    gamesMostExpensive: updatedMoreExpensiveGames,
    gamesLiveCasinoProvider: (prevState.gamesLiveCasinoProvider || []).concat(updatedMoreExpensiveGames)
  }));
});
this.fetchGamesNew("live_dealers").then(liveDealerGames => {
  const filteredLiveDealerGames = liveDealerGames.filter((game, index) => [1, 6, 0].includes(index));
  const updatedLiveDealerGames = filteredLiveDealerGames.map(game => ({ ...game, provider_code: "live_dealers" }));
  this.setState(prevState => ({
    gamesLiveCasinoProvider: (prevState.gamesLiveCasinoProvider || []).concat(updatedLiveDealerGames)
  }));
});

    this.fetchGamesNew("sport_betting").then(games => {
      this.setState({ gamesSports: games.map(game => ({ ...game, provider_code: "sport_betting" })) });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this._isMounted = false;
  }

  fetchGames = async (provider) => {
    try {
      const response = await fetch(`https://api.ibitplay.com/game-list?s=${provider}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      return result.games;
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
      return [];
    }
  };

  fetchGamesNew = async (provider) => {
    try {
      const response = await fetch(`https://api.ibitplay.com/game-list-new?lable=${provider}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      
      const result = await response.json();
      if (result.games && result.games[provider]) {
        return result.games[provider];
      } else {
        throw new Error(`No games found for the provider: ${provider}`);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
      return [];
    }
  };

  handleResize = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.setState({ isMobileView: width <= 768 });
  };

  handleClick = (e) => {
    this.setState({ selectedSection: e });
  };

  handleArrowClick = (direction) => {
    const { sectionIndex } = this.state;
    const { sections, itemsToShow } = this;
    const totalSections = sections.length;

    let newIndex;

    if (direction === 'left') {
      newIndex = (sectionIndex - itemsToShow + totalSections) % totalSections;
    } else {
      newIndex = (sectionIndex + itemsToShow) % totalSections;
    }

    this.setState({ selectedSection: sections[newIndex], sectionIndex: newIndex });
  };

  handleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  handleShowMoreP = () => {
    this.setState({ showMoreP: !this.state.showMoreP });
  };

  handleShowMoreO = () => {
    this.setState({ showMoreO: !this.state.showMoreO });
  };

  handleShowMoreLCP = () => {
    this.setState({ showMoreLCP: !this.state.showMoreLCP });
  };

  handleShowMoreSP = () => {
    this.setState({ showMoreSP: !this.state.showMoreSP });
  };

  handleShowMoreSB = () => {
    this.setState({ showMoreSB: !this.state.showMoreSB });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };

  getFilteredGames = (games) => {
    const { searchQuery } = this.state;
    if (!searchQuery) return games;
    return games.filter(game => game.game_name && game.game_name.toLowerCase().includes(searchQuery.toLowerCase()));
  };
  handleProviderChange = (provider) => {
    this.setState({ selectedProvider: provider });
  };

  // render() {
  //   let { content, t } = this.props;
  //   const { selectedProvider, isMobileView, loading, message, showMore, gamesEvolution, gamesPragmatic, gamesLiveCasinoProvider, iframeLoading, showIframe, iframeUrl, padding } = this.state;
  //   const { sections, itemsToShow, livecasino } = this;

  //   const filteredGamesEvolution = this.getFilteredGames(gamesEvolution);
  //   const filteredGamesPragmatic = this.getFilteredGames(gamesPragmatic);
  //   const filtergamesLiveCasinoProvider = this.getFilteredGames(gamesLiveCasinoProvider);

  //   let displayedGames = [];
  //   if (selectedProvider === 'All') {
  //     displayedGames = [...filteredGamesEvolution, ...filteredGamesPragmatic, ...filtergamesLiveCasinoProvider];
  //   } else if (selectedProvider === 'Evolution') {
  //     displayedGames = filteredGamesEvolution;
  //   } else if (selectedProvider === 'Pragmatic Live') {
  //     displayedGames = filteredGamesPragmatic;
  //   } else if (selectedProvider === 'Other Providers') {
  //     displayedGames = filtergamesLiveCasinoProvider;
  //   }

  //   displayedGames = isMobileView ? displayedGames : (showMore ? displayedGames : displayedGames.slice(0, 9));

  //   const imageStyle = {
  //     position: 'absolute',
  //     top: '50%',
  //     left: '50%',
  //     transform: 'translate(-50%, -50%)',
  //     width: '250px',
  //     animation: 'fadeinout 2s infinite',
  //   };
  //   const animationStyle = `
  //     @keyframes fadeinout {
  //       0%, 100% { opacity: 0; }
  //       50% { opacity: 1; }
  //     }
  //   `;

  //   if (showIframe) {
  //     return (
  //       <FullScreenContainer>
  //         <style>{animationStyle}</style>
  //         {iframeLoading && (
  //           <img src="assets/images/lg.png" alt="Loading" style={imageStyle} />
  //         )}
  //         <FullScreenIframe
  //           src={iframeUrl}
  //           title="Game Iframe"
  //           onLoad={this.handleIframeLoad}
  //           loading={iframeLoading}
  //         />
  //         <BackButton onClick={this.handleBackButton}>
  //           <FontAwesomeIcon icon={faArrowLeft} />
  //         </BackButton>
  //       </FullScreenContainer>
  //     );
  //   }

  //   return (
  //     <>
  //       {this._isMounted &&
  //         <>
  //           <Helmet>
  //             <title>{BRAND} - Crypto Casino Games and Crypto Gambling</title>
  //             <meta name="keywords" content="WEB 3.0 Crypto Casino Games, Online Casino Games, Bitcoin Crash Game, Dice Games, Live Casino Games, Crypto Casino Games, Blockchain Games, Bitcoin Casino Games, Best Crypto Online Game, Crypto Gambling Games, PlayCrash.com Crypto Games" />
  //             <meta name="description"
  //               content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
  //             <meta name="og:title" content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
  //             <meta name="og:description" content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
  //           </Helmet>

  //           {/* Page Content */}
  //           {content}

  //           <Container style={{ marginTop: -20, textAlign: 'center' }}>
  //             <DropdownButton id="provider-dropdown" title={`Select Provider: ${selectedProvider}`} onSelect={this.handleProviderChange} className="mb-4">
  //               <Dropdown.Item eventKey="All">All</Dropdown.Item>
  //               <Dropdown.Item eventKey="Evolution">Evolution</Dropdown.Item>
  //               <Dropdown.Item eventKey="Pragmatic Live">Pragmatic Live</Dropdown.Item>
  //               <Dropdown.Item eventKey="Other Providers">Other Providers</Dropdown.Item>
  //             </DropdownButton>

  //             <Card className="brd7" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
  //               <Card.Body className={padding}>
  //                 <Row>
  //                   {displayedGames.map((game, index) => (
  //                     <Col xs={12} sm={6} md={4} lg={3} key={game.game_code || game.id} className="mb-4">
  //                       <div onClick={() => {
  //                         if (game.game_code) {
  //                           this.handleGameLaunch(game.game_code, game.provider_code);
  //                         } else {
  //                           this.handleGameLaunchNew(game.id, game.provider_code);
  //                         }
  //                       }}>
  //                         <img src={game.banner || game.img} style={{ width: '100%', height: 'auto', borderRadius: 10 }} alt={`Game ${index}`} />
  //                       </div>
  //                     </Col>
  //                   ))}
  //                 </Row>
  //                 {!isMobileView && displayedGames.length > 9 && (
  //                   <div className="text-center">
  //                     <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMore}>
  //                       <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMore ? 'Show Less' : 'Show More'}
  //                     </div>
  //                   </div>
  //                 )}
  //               </Card.Body>
  //             </Card>
  //           </Container>
  //         </>
  //       }

  //       {/* Loader */}
  //       {loading && (
  //         <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
  //           <Spinner animation="border" role="status">
  //             <span className="sr-only">Loading...</span>
  //           </Spinner>
  //         </div>
  //       )}

  //       {/* Snackbar */}
  //       {message && (
  //         <Alert variant={message.type === 'error' ? 'danger' : 'success'} style={{ position: 'fixed', bottom: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 9999 }}>
  //           {message.text}
  //         </Alert>
  //       )}
  //     </>
  //   );
  // }
  render() {
    const { selectedProvider, isMobileView, showMore, gamesEvolution, gamesPragmatic, gamesLiveCasinoProvider } = this.state;

    const filteredGamesEvolution = this.getFilteredGames(gamesEvolution);
    const filteredGamesPragmatic = this.getFilteredGames(gamesPragmatic);
    const filteredGamesLiveCasinoProvider = this.getFilteredGames(gamesLiveCasinoProvider);

    let displayedGames = [];
    if (selectedProvider === 'All') {
      displayedGames = [...filteredGamesEvolution, ...filteredGamesPragmatic, ...filteredGamesLiveCasinoProvider];
    } else if (selectedProvider === 'Evolution') {
      displayedGames = filteredGamesEvolution;
    } else if (selectedProvider === 'Pragmatic Live') {
      displayedGames = filteredGamesPragmatic;
    } else if (selectedProvider === 'Other Providers') {
      displayedGames = filteredGamesLiveCasinoProvider;
    }

    displayedGames = isMobileView ? displayedGames : (showMore ? displayedGames : displayedGames.slice(0, 9));

    return (
      <Container style={{ marginTop: -20 }}>
        <Row className="align-items-center mb-4">
          <Col xs={12} md={6} className="text-md-left text-center mb-2 mb-md-0">
            <h2 style={{ color: 'white' }}>Live Casino</h2>
          </Col>
          <Col xs={12} md={6} className="text-md-right text-center">
            <DropdownButton
              id="provider-dropdown"
              title={`Select Provider: ${selectedProvider}`}
              onSelect={this.handleProviderChange}
            >
              <Dropdown.Item eventKey="All">All</Dropdown.Item>
              <Dropdown.Item eventKey="Evolution">Evolution</Dropdown.Item>
              <Dropdown.Item eventKey="Pragmatic Live">Pragmatic Live</Dropdown.Item>
              <Dropdown.Item eventKey="Other Providers">Other Providers</Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>

        <Card className="brd7" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
          <Card.Body className="some-padding-class">
            <Row>
              {displayedGames.map((game, index) => (
                <Col xs={12} md={4} key={game.game_code || game.id} className="mb-4">
                  <div onClick={() => {
                    if (game.game_code) {
                      this.handleGameLaunch(game.game_code, game.provider_code);
                    } else if(game.id){
                      this.handleGameLaunchNew(game.id, game.provider_code);
                    }
                  }}>
                    <img src={game.banner || game.img} style={{ width: '100%', height: 'auto', borderRadius: 10 }} alt={`Game ${index}`} />
                  </div>
                </Col>
              ))}
            </Row>
            {!isMobileView && displayedGames.length > 9 && (
              <div className="text-center">
                <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMore}>
                  <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMore ? 'Show Less' : 'Show More'}
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default withCookies(Live);
