

// components/AuthModal.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import SignInForm from './SignInForm';
import RegisterForm from './RegisterForm';
import Modal from './Modal';

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const ToggleText = styled.p`
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  color: #3498db;
`;

const AuthModal = ({ isOpen, onClose, initialMode }) => {
  const [mode, setMode] = useState(initialMode);

  useEffect(() => {
    if (isOpen) {
      setMode(initialMode);
    }
  }, [isOpen, initialMode]);

  const toggleMode = () => {
    setMode(mode === 'signin' ? 'register' : 'signin');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Title>{mode === 'signin' ? 'Sign In' : 'Create an Account'}</Title>
      {mode === 'signin' ? <SignInForm /> : <RegisterForm />}
      <ToggleText onClick={toggleMode}>
        {mode === 'signin' ? "Don't have an account? Register" : 'Already have an account? Sign In'}
      </ToggleText>
    </Modal>
  );
};

export default AuthModal;
