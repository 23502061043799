import { ChevronLeft, ChevronRight } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  padding-left: 80px;
  padding-right: 80px;

  @media (max-width: 1320px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const CarouselTrack = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  margin-top: 12px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
    grid-auto-columns: calc((100% - 16px) / 3);
  }

  @media (min-width: 768px) {
    gap: 16px;
    grid-auto-columns: calc((100% - 96px) / 7);
  }
`;

const pulseAnimation = keyframes`
  0%, 100% {
    transform: translateY(0) scale(1);
    // box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  50% {
    transform: translateY(-5px) scale(1.02);
    // box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
`;

const GameCard = styled.div`
  height: 0;
  padding-bottom: 134%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundColor};
  scroll-snap-align: start;
  position: relative;
  top: 0;
  transition: top 0.3s ease;
  
  &:hover {
    top: -10px;
    z-index:10001;
  }
`;
const GameCardImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const GameCardInfo = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: left;
  width: 100%;
  margin-top: 4px;
  font-size: 12px;
`;

const PlayerCount = styled.div`
  font-weight: bold;
`;

const PlayingText = styled.div`
  color: #b1bbd3;
`;

const ButtonGroup = styled.div`
  display: flex;
  background-color: transparent;
  border-radius: 9999px;
  padding: 2px;
  width: 84px;
  border: 1px solid #2f4553;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  // top: 0px;
  right: ${props => props.rightPosition}px;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  color: #94a3b8;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
  width: 28px;
  height: 28px;

  &:hover:not(:disabled) {
    background-color: rgba(30, 41, 59, 0.8);
    color: #e2e8f0;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const DividerButton = styled.div`
  width: 1px;
  height: 16px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 1px;
`;

const GameNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const GameName = styled.div`
  font-size: 18px;
  color: white;
  font-weight: bold;
`;

const GameCardComponent = ({ title, subtitle, players, backgroundColor, imageUrl }) => (
  <div>
    <GameCard backgroundColor={backgroundColor}>
      <GameCardImage>
        <img src={imageUrl} alt={title} />
      </GameCardImage>
    </GameCard>
    <GameCardInfo>
      <div style={{height: '8px', width: '8px', borderRadius: '50%', backgroundColor: "#1fff20"}}></div>
      <PlayerCount>{players}</PlayerCount>
      <PlayingText>Playing</PlayingText>
    </GameCardInfo>
  </div>
);

export const StakeCarousel = ({ title, data }) => {
  const trackRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [itemWidth, setItemWidth] = useState(0);
  const [buttonGroupRight, setButtonGroupRight] = useState(83);

  const checkScrollButtons = () => {
    const { current } = trackRef;
    if (current) {
      setCanScrollLeft(current.scrollLeft > 0);
      setCanScrollRight(
        current.scrollLeft < current.scrollWidth - current.clientWidth - 1
      );
    }
  };

  const updateItemWidth = () => {
    const { current } = trackRef;
    if (current) {
      const computedStyle = window.getComputedStyle(current);
      const gap = parseInt(computedStyle.getPropertyValue('column-gap'), 10);
      const totalWidth = current.clientWidth + gap;
      const itemsPerView = window.innerWidth < 768 ? 3 : 7;
      setItemWidth(totalWidth / itemsPerView - gap);
    }
  };

  useEffect(() => {
    const { current } = trackRef;
    if (current) {
      const resizeObserver = new ResizeObserver(() => {
        updateItemWidth();
        checkScrollButtons();
      });
      resizeObserver.observe(current);

      current.addEventListener('scroll', checkScrollButtons);
      updateItemWidth();
      checkScrollButtons();

      const updateButtonGroupPosition = () => {
        setButtonGroupRight(window.innerWidth <= 767 ? 12 : 83);
      };

      window.addEventListener('resize', updateButtonGroupPosition);
      updateButtonGroupPosition(); // Initial call

      return () => {
        current.removeEventListener('scroll', checkScrollButtons);
        resizeObserver.disconnect();
        window.removeEventListener('resize', updateButtonGroupPosition);
      };
    }
  }, []);

  const scroll = (direction) => {
    const { current } = trackRef;
    if (current) {
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      setTimeout(checkScrollButtons, 400); // Check after scroll animation
    }
  };

  return (
    <CarouselWrapper>
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <GameNameWrapper>
          <GameName>{title}</GameName>
        </GameNameWrapper>
        <ButtonGroup rightPosition={buttonGroupRight}>
          <Button onClick={() => scroll('left')} disabled={!canScrollLeft}>
            <ChevronLeft size={16} />
          </Button>
          <DividerButton />
          <Button onClick={() => scroll('right')} disabled={!canScrollRight}>
            <ChevronRight size={16} />
          </Button>
        </ButtonGroup>
      </div>
      <CarouselContainer>
        <CarouselTrack ref={trackRef}>
          {data.map((game) => (
            <GameCardComponent key={game.id} {...game} />
          ))}
        </CarouselTrack>
      </CarouselContainer>
    </CarouselWrapper>
  );
};