import React, { useState } from 'react';
import styled from 'styled-components';
import storage from "../../../../../Storage";
import { X, Wallet as WalletIcon, ChevronDown, ChevronLeft, IndianRupee, Calendar, ArrowUpIcon, ArrowDownIcon, Star, ArrowRight, Info, Sparkles } from 'lucide-react';
import Bronze from '../../../svgs/Bronze';
import Silver from '../../../svgs/Silver';
import Platinum from '../../../svgs/Platinum';
import Platinum2 from '../../../svgs/Platinum2';
import Diamond from '../../../svgs/Diamond';
import Gold from '../../../svgs/Gold'


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  background-color: #1a2c38;
  color: white;
  border-radius: 10px;
  width: 90%; /* Set width to a percentage */
  max-width: 420px; /* Maximum width */
  height: 80vh;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    width: 60%; /* Adjust width for medium to large screens */
  }

  @media (min-width: 1024px) {
    width: 50%; /* Adjust width for larger screens */
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  overflow-y: auto; // make the content scrollable
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; // add padding to the header
  background-color: #0f212e; // set a background color for the header
  border-top-left-radius: 10px; // add border-radius to the header
  border-top-right-radius: 10px; // add border-radius to the header
`;
const Title = styled.h2`
  font-size: 16px;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
`;

const TabSwitcher = styled.div`
  display: flex;
  margin-bottom: 20px;
  background-color: #0f212e;
  border-radius: 50px;
  padding: 5px;
  width: 195px;
`;

const Tab = styled.button`
  background-color: ${(props) => (props.active ? 'rgba(47, 69, 83, 1)' : 'transparent')};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
`;

const IconBox = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:  rgba(15, 33, 46,1) ;
`;



const AccordionContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

const AccordionItem = styled.div`
  border-top: ${({ isOpen }) => (isOpen ? '1px solid #2c3038' : 'none')};
  background-color: #1e2128;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12), inset 0 1px rgba(255, 255, 255, 0.04);
  border-radius: 5px;
  background: #213743;
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
`;

const AccordionTitle = styled.h3`
  font-size: 14px;
  color: #f2f2f2;
`;

const AccordionContent = styled.div`

  color: #b1bad3;
`;

const Divider = styled.div`
  height: 1px;
  background-color: white;
  opacity: 0.4;
`;

const CardComp = styled.div`
  background-color: rgba(15, 33, 46,1) ;
  border-radius: 8px;
  padding: 33px 16px;
  color: white;
  border: 1px solid #fed100;
  flex: 1;
`;

const Conatiner = styled.div`
  border-radius: 8px;
  padding: 8px;
  color: white;
  background-color: #213743;

  @media (min-width: 1400px) {
    max-width: 560px;
    flex: 0.5;
  }

  @media (min-width: 769px) and (max-width: 1399px) {
    width: 100%;
    max-width: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
`;

const Username = styled.h2`
  margin: 0 0 16px 0;
  font-size: 18px;
`;

const ProgressSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 16px;
`;

const ProgressText = styled.span`
  font-size: 14px;
`;

const ProgressBar = styled.div`
  background-color: #2a3541;
  height: 8px;
  border-radius: 4px;
  width: 100%;
  margin: 8px 0;
`;

const ProgressFill = styled.div`
  background-color: #fed100;
  height: 100%;
  border-radius: 4px;
`;

const LevelSection = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;
const accordionItems = [
    {
        title: 'Bronze',
        svg: <Bronze/>,
        content: (
            <ul>
                <li>Bonus from Support in currency of your choice</li>
                <li>Rakebacks enabled</li>
                <li>Weekly bonuses</li>
                <li>VIP Telegram channel access</li>
                <li>Monthly bonuses</li>
            </ul>
        ),
    },
    {
        title: 'Silver',
        svg: <Silver/>,
        content: (
            <ul >
                <li>Bonus from Support in currency of your choice</li>
                <li>Weekly & monthly bonuses increased</li>
            </ul>
        ),
    },
    {
        title: 'Gold',
        svg: <Gold />,
        content: (
            <ul>
                <ul >
                    <li>Bonus from Support in currency of your choice</li>
                    <li>Weekly & monthly bonuses increased</li>
                </ul>
            </ul>
        ),
    },
    {
        title: 'Platinum I - III',
        svg: <Platinum />,
        content: (
            <ul>
                <li>Bonus from Support in currency of your choice</li>
                <li>Weekly & monthly bonuses increased</li>
                <li>14 - 42 Day, Daily bonus (Reload)</li>
            </ul>
        ),
    },
    {
        title: 'Platinum IV - VI',
        svg: <Platinum2 />,
        content: (
            <ul>
                <li>Dedicated VIP host</li>
                <li>Bonus from VIP host in currency of your choice</li>
                <li>Daily & weekly bonuses increased</li>
                <li>VIP Telegram channel access</li>
                <li>Monthly bonuses</li>
            </ul>
        ),
    },
    {
        title: 'Diamond',
        svg: <Diamond />,
        content: (
            <ul>
                <li>Bonus from VIP host in currency of your choice</li>
                <li>Exclusively customized benefits</li>
                <li>Weekly bonuses</li>
                <li>Daily & weekly bonuses increased</li>
            </ul>
        ),
    },
];


const VipModal = ({ open, onClose }) => {
    const [activeTab, setActiveTab] = useState('Progress');
    const [openAccordion, setOpenAccordion] = useState(null);
    const  progress = 25.7 
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };

    if (!open) return null;

    return (
        <ModalOverlay>
            <Modal>
                <Header>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <WalletIcon size={20} />
                        <Title>VIP</Title>
                    </div>
                    <CloseButton onClick={onClose}>
                        <X size={20} />
                    </CloseButton>
                </Header>
                <ModalContent>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <TabSwitcher>
                            <Tab active={activeTab === 'Progress'} onClick={() => setActiveTab('Progress')}>
                                Progress
                            </Tab>
                            <Tab active={activeTab === 'Benefits'} onClick={() => setActiveTab('Benefits')}>
                                Benefits
                            </Tab>
                        </TabSwitcher>
                    </div>
                    {activeTab === 'Progress' ? (
                        <div>
                              <Conatiner>
                <CardComp>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Username>
                      {storage.getKey("name") ? storage.getKey("name") : ""}
                    </Username>
                    <Star color="#FED100" />
                  </div>
                  <ProgressSection>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <ProgressText>Your VIP Progress</ProgressText>
                      {/* <MoveRight/> */}
                      <ArrowRight size={16} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <ProgressText>{progress.toFixed(2)}%</ProgressText>
                      <Info size={18} />
                    </div>
                  </ProgressSection>
                  <ProgressBar>
                    <ProgressFill progress={progress} />
                  </ProgressBar>
                  <LevelSection>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Star color="#FED100" size={20} />
                      <span>Gold</span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Sparkles size={20} />
                      <span>Platinum I</span>
                    </div>
                  </LevelSection>
                </CardComp>
              </Conatiner>
                            <div>
                                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Want to achieve the next level?</p>
                            </div>
                            {[
                                {
                                    title: 'Wager on Casino',
                                    description: 'Play & wager on any casino games',
                                    icon: (
                                        <svg
                                            style={{ height: '20px', width: '20px' }}
                                            fill="currentColor"
                                            viewBox="0 0 64 64"
                                            className="svg-icon"
                                        >
                                            <title></title>
                                            <path d="M12.265 47.726.21 14.603a3.574 3.574 0 0 1 2.108-4.553l.024-.007 19.282-7.015a3.55 3.55 0 0 1 4.553 2.082l.008.024.694 1.92L12.69 46.073a8.9 8.9 0 0 0-.418 1.598l-.008.056ZM63.79 15.511 48.002 58.93a3.529 3.529 0 0 1-4.558 2.1l.024.009-21.948-8.001a3.58 3.58 0 0 1-2.124-4.585l-.008.024 15.787-43.39a3.555 3.555 0 0 1 4.559-2.126l-.024-.008 21.948 8a3.58 3.58 0 0 1 2.124 4.585l.008-.024v-.002ZM50.457 32.685l-1.386-3.254a1.789 1.789 0 0 0-2.333-.956l.012-.004-2.666 1.174a1.787 1.787 0 0 1-2.316-.948l-.004-.012-1.146-2.667a1.764 1.764 0 0 0-2.332-.93l.012-.004-3.28 1.386a1.738 1.738 0 0 0-.929 2.33l-.004-.01 3.92 9.255a1.816 1.816 0 0 0 2.359.928l-.012.005 9.227-3.947a1.737 1.737 0 0 0 .794-2.356l.004.01h.08Z"></path>
                                        </svg>
                                    ),
                                },
                                {
                                    title: 'Bet on Sportsbook',
                                    description: 'Place bets on upcoming & live sports',
                                    icon: (
                                        <svg
                                            fill="currentColor"
                                            viewBox="0 0 96 96"
                                            className="svg-icon"
                                            style={{ height: '20px', width: '20px' }}
                                        >
                                            <title></title>
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M14.287 13.917c16.599 6.854 30.869 15.965 43.231 27.143l-.001.002.126.11-.125-.112C64.262 31 65.501 17.31 60.63 1.865 56.773.739 52.34.092 47.758.092c-13.046 0-24.87 5.249-33.47 13.748v.077Zm79.997 46.514a46.803 46.803 0 0 1-7.907 15.996v-.003c-2.275-3.87-4.709-7.622-7.185-11.295l-.137.08c4.463-2.823 9.63-4.63 15.307-5.11l-.078.332ZM80.986 82.734c-4.75 4.553-10.46 8.116-17.124 10.458h-.003l.006-.108a38.977 38.977 0 0 1 9.137-22.842l-.281-.41c2.838 3.924 5.478 8.005 8.265 12.902Zm0 0 .016-.014-.015.015ZM12.017 64.772a83.99 83.99 0 0 0 9.697.599h.003l-.117-.006c.832.039 1.674.06 2.518.06 12.98 0 24.848-4.766 33.883-12.589a132.455 132.455 0 0 1 9.859 11.137 47.738 47.738 0 0 0-11.975 31.216l.284-.042c-2.68.49-5.44.751-8.269.76-21.022-.012-38.88-13.566-45.416-32.75 3.102.685 6.287 1.224 9.931 1.654l-.398-.039Zm-9.533-1.614c-.226-.05-.45-.1-.675-.152l.667.129.008.023Zm65.376.815.045-.051-.045.05ZM58 52.836l-.009-.009.01.01Zm-5.59-5.706A140.354 140.354 0 0 0 9.776 20.677l-.952-.332C3.305 28.021 0 37.61 0 47.97v.038c.018 2.3.192 4.539.512 6.733l-.033-.266c3.542.97 7.889 1.823 12.325 2.386l.488.05c16.526 1.797 30.138-1.637 39.12-9.78Zm21.58 11.182a149.73 149.73 0 0 0-10.601-11.7c7.864-10.897 10.059-25.19 6.466-41.155l.267.126C85.46 13.537 95.953 29.542 96 48.007c0 .604-.048 1.247-.097 1.904-.051.688-.104 1.393-.104 2.087h-.042c-8.002.159-15.445 2.596-21.552 6.586l-.215-.272Zm-10.601-11.7-.003-.003.003.003Z"></path>
                                        </svg>
                                    ),
                                },
                                {
                                    title: 'Boost x3 with Sports Bets',
                                    description: 'Increase progression 3 times faster with sports wagers',
                                    icon: (
                                        <div style={{ fontWeight: 'bold' }}>x3</div>
                                    ),
                                },
                            ].map((benefit, index) => (
                                <div key={index} style={{ display: 'flex', gap: '12px', margin: '12px 0' }}>
                                    <IconBox>{index !== 2 ? benefit.icon : <div style={{ fontWeight: 'bold' }}>x3</div>}</IconBox>
                                    <div>
                                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{benefit.title}</div>
                                        <span style={{ fontSize: '14px', color: '#b1bad3' }}>{benefit.description}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <AccordionContainer>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                {accordionItems.map((item, index) => (
                                    <AccordionItem key={index} isOpen={openAccordion === index}>
                                        <AccordionHeader onClick={() => toggleAccordion(index)}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <div>{item.svg}</div>
                                                <AccordionTitle>{item.title}</AccordionTitle>
                                            </div>
                                            {openAccordion === index ? <ChevronDown size={15} /> : <ChevronLeft size={15} />}
                                        </AccordionHeader>
                                        {openAccordion === index && <Divider />}
                                        {openAccordion === index && (
                                            <AccordionContent>{item.content}</AccordionContent>
                                        )}
                                    </AccordionItem>
                                ))}
                            </div>
                        </AccordionContainer>
                    )}
                </ModalContent>
            </Modal>
        </ModalOverlay>
    );
};

export default VipModal;
