import React from 'react';
import styled from 'styled-components';
import { Trophy, Info,Satellite } from 'lucide-react';



const LinearProgressBarContainer = styled.div.attrs({
  className: 'LinearProgressBarContainer',
})`
  background-color: rgba(47, 69, 83,1);
  border-radius: 9999px;
  height: 16px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const LinearProgress = styled.div.attrs({
  className: 'LinearProgress',
})`
  background-color: #3b82f6;
  height: 100%;
  width: ${props => props.progress}%;
  transition: width 0.3s ease-in-out;
`;

const LinearIconWrapper = styled.div.attrs({
  className: 'LinearIconWrapper',
})`
  position: absolute;
  left: 8px;
  display: flex;
  align-items: center;
`;

const LinearProgressText = styled.span.attrs({
  className: 'LinearProgressText',
})`
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-left: 36px;
`;
const Container = styled.div`
color: white;
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  padding-left: 67px;
  padding-right: 67px;

  @media (max-width: 1320px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const PlayerCountBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const PlayerCount = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  
  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.color};
    margin-right: 5px;
  }
`;

const Title = styled.h2`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size:18px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  
  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-color: rgba(33, 55, 67,1);
  border-radius: 8px;
  padding: 15Fpx;
`;

const CardTitle = styled.h3`
  font-size: 18px;
  margin: 0 0 5px 0;
  font-weight: bold;

`;

const CardSubtitle = styled.p`
  font-size: 14px;
  color: #ffff;
  margin: 0 0 15px 0;
`;

const Button = styled.button`
  background-color: #2962a8;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
`;

const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px;
`;

const NotEntered = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #a0a0a0;
  margin:12px;
`;

const Timer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #3498db;
  font-size: 14px;
  font-weight: bold;
  flex-direction: column;
  line-height: 1.2;
`;

const ProgressContainer = styled.div`
  position: relative;
  width: 130px;
  height: 130px;
`;

const ProgressCircle = styled.svg`
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
`;

const Background = styled.circle`
  fill: none;
  stroke: #2a3144;
  stroke-width: 10;
`;

const Progress = styled.circle`
  fill: none;
  stroke: #3a6df0;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-dasharray: 251.32;
  stroke-dashoffset: ${props => 251.32 * (1 - props.progress)};
  transition: stroke-dashoffset 0.5s ease;
`;

const TimeDisplay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-align: center;
`;

const EndsIn = styled.div`
  font-size: 10px;
  color: #6c7283;
  margin-bottom: 2px;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #2f4553;
`;


const playerCounts = [
  { count: 5231, color: '#4caf50' },
  { count: 4892, color: '#ff9800' },
  { count: 3754, color: '#2196f3' },
  { count: 3201, color: '#e91e63' },
  { count: 2987, color: '#9c27b0' },
  { count: 2543, color: '#ff5722' },
  { count: 2321, color: '#795548' },
];

const RacesRaffles = () => 
    {
    const totalMinutes = 24 * 60;
  const remainingMinutes = 16 * 60 + 24;
  const progress = 1 - (remainingMinutes / totalMinutes);
return(  <Container>
    {/* <PlayerCountBar>
      {playerCounts.map((item, index) => (
        <PlayerCount key={index} color={item.color}>
          {item.count} Playing
        </PlayerCount>
      ))}
    </PlayerCountBar> */}
    <Title>
      <Trophy size={24} />
      Races & Raffles
    </Title>
    <Grid>
      <Card>
      <div style={{display:'flex' ,gap:'10px',padding:'15px', justifyContent:'space-between'}}>
        <div>
        <CardTitle>$100k Race - 24 Hours</CardTitle>
        <CardSubtitle>Ready to race to the top?</CardSubtitle>
        <Button>
          Leaderboard
          <Info size={16} />
        </Button>
        </div>
        <CircularProgressTimer
      hours={16}
      minutes={24}
      progress={progress}
    />

      </div>

    
      <Divider/>
       <div>
       <NotEntered>
            <Trophy size={16} />
            Not entered yet
          </NotEntered>
       </div>
      </Card>
      <Card>
       <div style={{display:'flex' ,gap:'10px',padding:'15px', justifyContent:'space-between'}}>
      <div>
      <CardTitle>$75k Weekly Raffle</CardTitle>
        <CardSubtitle>Finish your week with a win!</CardSubtitle>
        <Button>
          0 Tickets
          <Info size={16} />
        </Button>
      </div>
        <CircularProgressTimer
      hours={16}
      minutes={24}
      progress={progress}
    />
       </div>
      
       <Divider/>
       <div>
       <NotEntered>
            <LinearProgressBar progress={0} />
          </NotEntered>
       </div>

      
      </Card>
    </Grid>
  </Container>
);
    }

export default RacesRaffles;

const LinearProgressBar = ({ progress = 0 }) => {
  return (
    <LinearProgressBarContainer>
      <LinearProgress progress={progress} />
      <LinearIconWrapper>
        {/* <Satellite size={16} color="white" /> */}
      </LinearIconWrapper>
    
    </LinearProgressBarContainer>
      // <LinearProgressText>{progress}%</LinearProgressText>
  );
};













const CircularProgressTimer = ({ hours, minutes, progress }) => {
    return (
      <ProgressContainer>
        <ProgressCircle viewBox="0 0 100 100">
          <Background cx="50" cy="50" r="40" />
          <Progress cx="50" cy="50" r="40" progress={progress} />
        </ProgressCircle>
        <TimeDisplay>
          <EndsIn>Ends in</EndsIn>
          {hours}h {minutes}m
        </TimeDisplay>
      </ProgressContainer>
    );
  };
