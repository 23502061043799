


import React from 'react'

export default function VipIcon() {
    return (
<svg fill="currentColor" viewBox="0 0 96 96" class="svg-icon " style={{height:"20px", width:'20px'}}> <title></title> <path d="M64.688 54.312c37.121-4.57 30.353-38.63 30.353-38.63H78.354c.628-4.697.991-10.138 1.005-15.665V0H16.64c.014 5.54.377 10.982 1.073 16.32l-.068-.641H.958S-5.807 49.69 31.31 54.309c2.62 3.87 5.51 7.244 8.743 10.254l.034.031c-.411 5.579-2.65 21.624-15.59 21.624h-7.861V96h62.719v-9.782l-10.88-.367C58.192 83.52 56.272 69.67 55.905 64.594a59.158 59.158 0 0 0 8.647-10.08l.13-.205.007.003ZM75.43 30.267l2.194-5.44h8.503c-.33 4.638-1.995 8.831-4.611 12.26l.04-.055a22.158 22.158 0 0 1-10.542 6.682l-.158.038c1.735-3.789 3.302-8.283 4.457-12.94l.117-.548v.003Zm-60.984 6.765a22.653 22.653 0 0 1-4.567-12.134l-.003-.072h8.41l2.287 5.441c1.395 5.322 3.051 9.882 5.064 14.246l-.22-.531c-4.409-1.235-8.143-3.665-10.947-6.92l-.024-.027v-.003Zm40.78 3.977-6.402-3.11a1.712 1.712 0 0 0-.823-.205c-.301 0-.586.075-.833.212l.01-.003-6.4 3.11a1.604 1.604 0 0 1-2.243-1.656v.006l1.005-7.176a1.547 1.547 0 0 0-.411-1.282l-4.846-5.256a1.56 1.56 0 0 1 .813-2.606h.01l7.176-1.326c.498-.055.916-.35 1.139-.768l.003-.007 3.429-6.401a1.555 1.555 0 0 1 2.74-.007l.003.007 3.428 6.4c.216.416.617.71 1.09.776h.007l7.176 1.326a1.555 1.555 0 0 1 .823 2.606l-5.03 5.256a1.548 1.548 0 0 0-.425 1.07c0 .075.007.147.014.22v-.008l1.005 7.176a1.6 1.6 0 0 1-2.475 1.646l.006.004.01-.004Z"></path></svg>
    )
}
