

import styled from 'styled-components';
import { Menu, Gift, Star, Award, Newspaper, MessageSquare, Shield, Headphones, Globe, ChevronRight, Brain } from 'lucide-react';
import { useState } from 'react';
import {Link} from 'react-router-dom'
import SidebarContent from '../BottomNavBar/SidebarContent';
import './index.css'




const SidebarContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: #1e2329;
  color: #ffffff;
  transition: width 0.3s ease;
  width: ${props => props.expanded ? '240px' : '65px'};
  overflow-x: hidden;
  z-index: 1000;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  padding: 15px;
  text-align: left;
  box-shadow:0 10px 15px -3px rgba(0, 0, 0, .2), 0 4px 6px -2px rgba(0, 0, 0, .1);
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
width: 100%;
  cursor: pointer;
  justify-content: ${props => props.expanded ? 'flex-start' : 'center'};
    flex-grow: ${props => props.expanded ? 'none' : '1'};
  &:hover {
    background-color: #2c3540;
  }
`;

const IconWrapper = styled.div`
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
   padding: 10px;
    &:hover {
    background-color: #2F4553;
  }
`;


const NavCradWrapper = styled.div`
padding: 8px;
display: flex;
width: 100%;
flex-direction: column;
align-items: start;
justify-content: center;
gap: 20px;
`;


const NavCrad = styled.div`
 display: flex;
 flex-direction: column;
 max-width: 100%;
 background-color:#213743;
 border-radius:.25rem;
 color: #fff;

 gap:20px;
 
`;



const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1e2329;
//   position: sticky;
//   top: 0;
//   z-index: 1000;
`;
const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: white;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
`;

const SignInButton = styled(Button)`
  background-color: transparent;
  color: white;
`;

const RegisterButton = styled(Button)`
  background-color: #3498db;
  color: white;
`;

const StakeLayout = () => {

  const [expanded, setExpanded] = useState(false);
const [sponsorshipsOpen, setSponsorshipsOpen] = useState(false);
const [languageOpen, setLanguageOpen] = useState(false);

const toggleSidebar = () => setExpanded(!expanded);
const toggleSponsorships = () => setSponsorshipsOpen(!sponsorshipsOpen);
const toggleLanguage = () => setLanguageOpen(!languageOpen);

  return (
    <div>
           <SidebarContainer expanded={expanded}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ToggleButton onClick={toggleSidebar}>
                    <Menu size={24} />

                    
                </ToggleButton>
                {
                    expanded && (
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center', justifyContent: 'center', paddingRight: '10px' }}>
                            <Link to='/home/casino' className='side-image item-image '>
                                <span>Casino</span>
                            </Link>
                            <a href='/' className='side-image-sports item-image-sports'>
                                <span>Sports</span>
                            </a>
                        </div>
                    )
                }
            </div>

            {
                !expanded && (
                    <NavCradWrapper>
                        <Link to='/home/casino' className='side-image-small item-image-small '></Link>
                        <div className='side-image-small-2 item-image-smal-2'></div>
                    </NavCradWrapper>
                )
            }

            {
                expanded ?  <div style={{padding:'12px'}}><SidebarContent/></div> : (
                    <NavCradWrapper>
                        <NavCrad>
                            <IconWrapper><Gift size={20} /></IconWrapper>
                            <IconWrapper><Star size={20} /></IconWrapper>
                            <IconWrapper><Award size={20} /></IconWrapper>
                            <IconWrapper><Newspaper size={20} /></IconWrapper>
                            <IconWrapper><MessageSquare size={20} /></IconWrapper>
                            <IconWrapper><Shield size={20} /></IconWrapper>
                        </NavCrad>
                        <div>
                            <NavCrad>
                            <IconWrapper><Shield size={20} /></IconWrapper>
                            <IconWrapper><Headphones size={20} /></IconWrapper>
                            <IconWrapper><Award size={20} /></IconWrapper>
                            <IconWrapper><Globe size={20} /></IconWrapper>
                        </NavCrad>
                        </div>
                    </NavCradWrapper>
                )
            }

        </SidebarContainer>

<HeaderContainer>
      <Logo>Stake</Logo>
      <ButtonGroup>
        <SignInButton>Sign In</SignInButton>
        <RegisterButton>Register</RegisterButton>
      </ButtonGroup>
    </HeaderContainer>

    </div>
    
  );
};

export default StakeLayout;