import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
//   background-color: #1e2328;
  color: white;
 
  font-family: Arial, sans-serif;

   padding-left: 67px;
  padding-right: 67px;
  margin-top:24px;

  @media (max-width: 1320px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const TabSwitcher = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background-color: ${props => props.active ? '#2c3137' : 'transparent'};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  color: #8a8d92;
  font-weight: normal;
`;


const TableCell = styled.td`
  padding: 10px;
`;

const RaceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #8a8d92;
`;

const GreenText = styled.span`
  color: #4caf50;
`;

const RankIcon = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${props => props.rank <= 3 ? '#ffd700' : '#c0c0c0'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #1e2328;
`;

const moveUpAnimation = keyframes`
//   0% { transform: translateY(10px); opacity: 0; }
//   100% { transform: translateY(0); opacity: 1; }
`;

const TableRow = styled.tr`
  background-color: ${props => props.even ? 'rgba(47, 69, 83, 1)' : 'transparent'};
  transition: background-color 0.3s ease;
  border-radius:3px;
`;

const AnimatedTableRow = styled(TableRow)`
  animation: ${moveUpAnimation} 0.5s ease-out;
`;

const AnimatedTable = ({ data, visibleRowsCount = 10 }) => {
  const [visibleData, setVisibleData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStartIndex((prevIndex) => (prevIndex + 1) % (data.length - visibleRowsCount + 1));
    }, 2000);

    return () => clearInterval(interval);
  }, [data.length, visibleRowsCount]);

  useEffect(() => {
    setVisibleData(data.slice(startIndex, startIndex + visibleRowsCount));
  }, [startIndex, data, visibleRowsCount]);

  return (
    <>
       <Table>
      <thead>
        <tr>
          <TableHeader>Game</TableHeader>
          <TableHeader>User</TableHeader>
          <TableHeader>Time</TableHeader>
          <TableHeader>Amount</TableHeader>
          <TableHeader>Multiplier</TableHeader>
          <TableHeader>Result</TableHeader>
        </tr>
      </thead>
      <tbody>
        {visibleData.map((row, index) => (
          <AnimatedTableRow key={`${startIndex}-${index}`} even={index % 2 === 0}>
            <TableCell>{row.game}</TableCell>
            <TableCell>{row.user}</TableCell>
            <TableCell>{row.time}</TableCell>
            <TableCell>{row.amount}</TableCell>
            <TableCell>{row.multiplier}</TableCell>
            <TableCell><GreenText>{row.result}</GreenText></TableCell>
          </AnimatedTableRow>
        ))}
      </tbody>
    </Table>
  
    {/* <Table>
      <thead>
        <tr>
          <TableHeader>Game</TableHeader>
          <TableHeader>User</TableHeader>
          <TableHeader>Time</TableHeader>
          <TableHeader>Amount</TableHeader>
          <TableHeader>Multiplier</TableHeader>
          <TableHeader>Result</TableHeader>
        </tr>
      </thead>
      <tbody>
        {visibleData.map((row, index) => (
          <AnimatedTableRow key={`${startIndex}-${index}`} even={index % 2 === 0}>
            <TableCell>{row.game}</TableCell>
            <TableCell>{row.user}</TableCell>
            <TableCell>{row.time}</TableCell>
            <TableCell>{row.amount}</TableCell>
            <TableCell>{row.multiplier}</TableCell>
            <TableCell><GreenText>{row.result}</GreenText></TableCell>
          </AnimatedTableRow>
        ))}
      </tbody>
    </Table> */}
  </>
  );
};
function CasinoLeaderboard() {
  const [activeTab, setActiveTab] = useState('Social Casino');

  const socialCasinoData = Array.from({ length: 50 }, (_, i) => ({
    game: `Game ${i + 1}`,
    user: `User${i + 1}`,
    time: '12:00 PM',
    amount: `${(Math.random() * 1000000).toFixed(2)}`,
    multiplier: `${(Math.random() * 100).toFixed(2)}x`,
    result: `${(Math.random() * 10000000).toFixed(2)}`,
  }));

  const raceLeaderboardData = [
    { rank: 1, user: 'Hidden', played: '15,530,015,3...', prize: '7,000,000.00' },
    { rank: 2, user: 'Hidden', played: '348,911,530,...', prize: '4,750,000.00' },
    // Add more data as needed
  ];

  return (
    <Container>
      <TabSwitcher>
        <Tab active={activeTab === 'Social Casino'} onClick={() => setActiveTab('Social Casino')}>Social Casino</Tab>
        <Tab active={activeTab === 'Race Leaderboard'} onClick={() => setActiveTab('Race Leaderboard')}>Race Leaderboard</Tab>
      </TabSwitcher>
      
      {activeTab === 'Social Casino' && (
        <AnimatedTable data={socialCasinoData} />
      )}
      
      {activeTab === 'Race Leaderboard' && (
        <>
          <RaceInfo>
            <span>50m GC Race - 24 Hours</span>
            <span>Ends in 8 hours</span>
          </RaceInfo>
          <Table>
            <thead>
              <tr>
                <TableHeader>Rank</TableHeader>
                <TableHeader>User</TableHeader>
                <TableHeader>Played</TableHeader>
                <TableHeader>Prize</TableHeader>
              </tr>
            </thead>
            <tbody>
              {raceLeaderboardData.map((row, index) => (
                <TableRow key={index} bgColor={index % 2 === 0 ? '#23272d' : 'transparent'}>
                  <TableCell>
                    <RankIcon rank={row.rank}>{row.rank}</RankIcon>
                  </TableCell>
                  <TableCell>{row.user}</TableCell>
                  <TableCell>{row.played}</TableCell>
                  <TableCell><GreenText>{row.prize}</GreenText></TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  );
}

export default CasinoLeaderboard;