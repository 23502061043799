import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Col, Dropdown, Row} from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import {__, Event, forceSatoshiFormat, isValidNumber, wait, sendNotfication, playAudio} from "../../../Helper";
import {gameCoin} from "../../../actions/gameCoin";
import storage from "../../../Storage";
import {setWallet} from "../../../actions/gameWallet";
import C from "../../../Constant";

class Bet extends React.Component {
    _isMounted = false;
    
    constructor (props){
        super(props);
        this.state = {
            engine: this.props.engine,
            isLogged: storage.getKey("logged") !== null ? true : false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : .00000010),
            buttonText: 'BET',
            type: 1,
            typeName: 'DEFAULT',
            disabled: false,
            deal: false,
            cards: [],
            dealCards: [],
            winning: false,
            c: 0
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
    }

    componentDidMount(){
        let { engine } = this.state;
        this._isMounted = true;

        if(this._isMounted)
        {
            this.props.gameCoin();

            engine.on(C.PLAY_VIDEOPOKER, () => this.play());
            engine.on(C.BUSTED_VIDEOPOKER, () => this.busted());
            engine.on(C.ERROR_VIDEOPOKER, data => this.error(data));
            engine.on("card", (data) => this.setCards(data));
            engine.on("deal_card", (data) => this.setDealCards(data));
            engine.on("toggle_card_hold", (data) => this.toggleCard(data));
            engine.on("deal", (data) => this.deal(data));
            engine.on("stop_playing", () => this.stopManual());
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setCards(data) {
        this.setState(state => ({ cards: [data, ...state.cards] }));
    }

    setDealCards(data) {
        this.setState(state => ({ dealCards: [data, ...state.dealCards] }));
    }

    toggleCard(data) {
        let { cards } = this.state;

        for( var i = 0; i < cards.length; i++){
            if (cards[i].pos === data.pos){
                cards[i].hold = data.hold;
            }
        }
    }

    deal(data) {
        let { win, score } = data;
        let { engine, dealCards } = this.state;

        if(win !== 0)
            playAudio('win.mp3');
        else
            playAudio('lost.mp3');

        // Update Table
        engine.emit('update_table', score)

        engine.init = true;
        engine.dealCards = dealCards;
        engine.winning = win;
        engine.deal();
    }
    
    stopManual() {
        if(this._isMounted){
            this.setState({ started: false, buttonText: 'BET', disabled: false });
        }
    }

    error(data) {
        if(this._isMounted)
        {
            this.setState({disabled: false});
        
            sendNotfication(data.message, 'info', 'top-center');

            if(data.code === 'credit')
                this.props.setWallet(true, data.uid);
        }
    }

    play(){
        if(this._isMounted){
            this.setState({ disabled: false, buttonText: "Deal", deal: true });
        }
    }

    busted(){
        if(this._isMounted)
        {
            this.setState({ disabled: true, buttonText: "BET", deal: false, cards: [], dealCards: [] });
            wait(3000).then(() => {
                this.setState({ disabled: false  });
            })
        }
    }

    placeBet(){
        if(this._isMounted)
        {
            let { engine, amount, deal, cards } = this.state;
            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.coin = this.props.coin;
                    engine.amount = amount;
                    engine.cards = cards;

                    if(deal)
                    {
                        engine.emit(C.DEAL_VIDEOPOKER)
                    }
                    else {
                        engine.start();
                    }
                });
        }
    }

    isCorrectBet(){
        if(this._isMounted)
        {
            let { amount, isLogged } = this.state;

           // Check User
            if(!isLogged){
               return Event.emit('showAuthModal', true);
            }

            if(amount.trim() === '')
                return false;

            if(!isValidNumber(amount))
                return false;
            
            
            if(parseFloat(amount) <= 0){
                this.setState({ amount: forceSatoshiFormat(0.00000010) });
            }
            else {
                amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
                this.setState({ amount: forceSatoshiFormat(amount) });
            }
            return true;
        }
    }

    handleBet(e){
        if(this._isMounted)
        {
            e.preventDefault();

            // Check is Correct Bet
            if(!this.isCorrectBet())
                return false;

            this.setState({ disabled: true });
            this.placeBet();
        }
    }

    handleInputChange(event){
        if(this._isMounted)
        {
            let target = event.target;
            let value  = target.type === 'checkbox' ? true : target.value;
            let name   = target.name;

            if(__.isEmpty(value)){
                if(name === 'amount')
                    this.setState({ bitError: 'red-border' });
            }
            else {
                if(name === 'amount')
                    this.setState({ bitError: false });
            }

            if(name === 'amount'){
                this.setState({amount:value})
                if(!isValidNumber(value)) return;
            }

            if(name === 'amount'){
                storage.setKey('lam', value);

                // Update Table
                this.state.engine.emit('update_table_amount', value);
            }
            
            this.setState({ [name]: value });
        }
    }

    handleMode = value => {
        if(this._isMounted)
        {
            this.setState({ type: value });
            let name;
            if(value === '1') name = 'DEFAULT';
            if(value === '2') name = 'HARD';
            this.setState({ typeName: name });
        }
    };
    
    setAmount = (val) => {
        if(this._isMounted)
        {
            var am = forceSatoshiFormat(val);
            this.setState({ amount: am });
            storage.setKey('lam', am );

            // Update Table
            this.state.engine.emit('update_table_amount', am);
        }
    }

    render() {
        let {disabled, typeName} = this.state;

        return(
                        <>
                            <form className="w-100 mt-1"  onSubmit={(e) => {
                e.preventDefault();
                if(this.props.coin==='INR'){
                  if(this.state.amount < 100){
                    sendNotfication('Enter valid amount')
                   
                  }else{
                    this.handleBet(e);
                  }
                }
                else if (this.props.coin ==='USDT'){
                   if(this.state.amount < 1){
                  sendNotfication('Enter valid amount')

                }else{
                  this.handleBet(e);

                }
              }else{
                this.handleBet(e);

              }
              }}>
                                <Row>
                                    <Col xl={6} md={7} sm={12}>
                                        <div className={"form-group mb-1 bet-input cashout " + this.state.bitError}>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt=""/>
                                                    BET
                                                </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    disabled={disabled}
                                                    className="form-control"
                                                    id="amount" name="amount"
                                                    value={this.state.amount}
                                                    autoComplete={"off"}
                                                    onKeyUp={this.handleInputChange}
                                                    onChange={this.handleInputChange} />
                                                <div className="input-group-append">
                                                    <RangeCredit set={this.setAmount} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={6} md={5} sm={12}>
                                        <div className={"form-group mb-1"}>
                                            <div className="input-group fw">
                                                <div className="input-group-append">
                                                    <span className="input-group-text">TYPE</span>
                                                </div>
                                                <Dropdown className={"drop-select btn-block"}>
                                                    <Dropdown.Toggle split variant="" className={'py-0 mt-0'} >
                                                    <span className="text-muted text-center">
                                                        <i className={'mdi mdi-chevron-down mr-1'} />
                                                        {typeName}
                                                     </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu disabled={disabled} className={"dopdown-menu-bottom " + disabled }>
                                                        <Dropdown.Item onClick={e => this.handleMode('1')} className={'animated fadeIn'}>
                                                            DEFAULT
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={e => this.handleMode('2')} className={'animated fadeIn'}>
                                                            HARD
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div>
              <span style={{fontSize:11,marginLeft:5}}>
                {this.props.coin ==='INR' ?
              "(Minimum Bet - 100 INR)"
              :
              "(Minimum Bet - 1 USDT)"
              }
              </span>
              </div>
                                <Row>
                                    <Col md={5} sm={12} className="m-auto">
                                        <div className={ "form-group rev mt-2 mb-0"}>
                                            <Button variant={'btn btn-block btn-bet btn-xl'}
                                                    disabled={disabled}
                                                    type="submit">
                                                {this.state.buttonText}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                    </form>
                </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
