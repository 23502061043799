import React from 'react';
import { Box, Button, Typography, styled } from '@mui/material';

const Card = styled(Box)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor,
  borderRadius: '16px',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: 'white',
}));

const ProgressBar = styled(Box)({
  height: '4px',
  backgroundColor: '#444',
  borderRadius: '2px',
  marginTop: '8px',
});

const StyledButton = styled(Button)({
  backgroundColor: '#2A2A2A',
  color: 'white',
  '&:hover': {
    backgroundColor: '#333333',
  },

});


const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  background: '#485362',
  '&.card-iron .main-vip': {
    background:'#485362'
  },
}));

const VIP = () => {
  return (
    <Box sx={{ p: 2, backgroundColor: '#1A1A1A', minHeight: '100vh' }}>
        <StyledCard className='card-iron'>
      <img style={{width:'100px' , objectFit:'contain', position:'absolute', right:'0'}} src='/assets/images/vip.png' alt='img'/>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>VIP 0</Typography>
        <Box sx={{ width: 40, height: 40, backgroundColor: '#555', borderRadius: '50%' }}></Box>
      </Box>
      <StyledButton variant="contained" size="small" sx={{ mb: 2 }}>
        Level Benefits →
      </StyledButton>
      <Typography sx={{ color: '#BBB', mb: 1 }}>Deposit Required</Typography>
      <Typography sx={{ color: '#BBB', mb: 1 }}>Need Bet Rp 0</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Typography>V 0</Typography>
        <Typography>V 1</Typography>
      </Box>
      <ProgressBar />
    </StyledCard>

      <Card bgcolor="#1E3A2F">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Level up bonus</Typography>
          <Button variant="contained" size="small" sx={{ bgcolor: '#2A5A4F', color: '#1CC980' }}>
            Detail →
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          {['2K', '5K', '10K'].map((value, index) => (
            <Box key={index} sx={{ textAlign: 'center' }}>
              {/* Placeholder for treasure chest icon */}
              <Box sx={{ width: 40, height: 40, backgroundColor: '#D4AF37', borderRadius: '8px', mb: 1 }}></Box>
              <Typography>{value}</Typography>
              <Typography sx={{ color: '#999', fontSize: '0.8rem' }}>V{index + 1}</Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ height: '4px', bgcolor: '#2A5A4F', borderRadius: '2px', mb: 2 }}>
          {[0, 50, 100].map((value) => (
            <Box key={value} sx={{ width: '12px', height: '12px', bgcolor: '#1CC980', borderRadius: '50%', mt: '-4px', ml: `${value}%` }} />
          ))}
        </Box>
        <StyledButton fullWidth>Claim</StyledButton>
      </Card>

      <Card bgcolor="#222222">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box>
            <Typography variant="h6">Cashback Bonus</Typography>
            <Typography sx={{ color: '#999', fontSize: '0.8rem' }}>Daily betting cashback</Typography>
          </Box>
          {/* Placeholder for info icon */}
          <Box sx={{ width: 24, height: 24, backgroundColor: '#444', borderRadius: '50%' }}></Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography sx={{ color: '#999' }}>Time span</Typography>
          <Typography>00:00 - 24:00</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography sx={{ color: '#999' }}>Tomorrow Can Claim</Typography>
          <Typography sx={{ color: '#FFD700' }}>Rp 0</Typography>
        </Box>
        <StyledButton fullWidth startIcon={<Box sx={{ width: 20, height: 20, bgcolor: '#FFD700', borderRadius: '50%' }} />}>
          Available for VIP 1
        </StyledButton>
      </Card>
    </Box>
  );
};

export default VIP;