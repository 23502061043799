// // import React, { Component } from "react";
// // import { Link } from "react-router-dom";
// // import { Card, Spinner, Alert, FormControl, Container, Row, Col } from "react-bootstrap";
// // import Carousel from "../../Parts/Home/crousel";
// // import { Helmet } from 'react-helmet';
// // import Popular from "./Popular";
// // import Games from "./Games";
// // import Slots from "../../../Slots/Slots";
// // import { BRAND, isMobile } from "../../../../Helper";
// // import { withCookies } from 'react-cookie';
// // import styled from 'styled-components';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
// // import BottomNavBar from "../../../Components/BottomNavBar/BottomNavbar";
// // const FullScreenContainer = styled.div`
// //   position: fixed;
// //   top: 0;
// //   left: 0;
// //   width: 100%;
// //   height: 100%;
// //   z-index: 999;
// //   background-color: #1b2336;
// // `;

// // const FullScreenIframe = styled.iframe`
// //   width: 100%;
// //   height: 100%;
// //   border: none;
// //   display: ${(props) => (props.loading ? 'none' : 'block')};
// // `;

// // const BackButton = styled.button`
// //   position: absolute;
// //   top: 10px;
// //   left: 10px;
// //   z-index: 1000;
// //   background: none;
// //   border: none;
// //   color: #000;
// //   font-size: 24px;
// //   cursor: pointer;
// // `;

// // const Loader = styled.div`
// //   position: absolute;
// //   top: 50%;
// //   left: 50%;
// //   transform: translate(-50%, -50%);
// //   font-size: 24px;
// //   color: #000;
// // `;

// // class Home extends Component {
// //   constructor(props) {
// //     super(props);
// //     this.state = {
// //       padding: 'py-2',
// //       selectedSection: 'home',
// //       sectionIndex: 0,
// //       slideSelected: null,
// //       gamesEvolution: [],
// //       gamesPragmatic: [],
// //       gamesPragmaticlive: [],
// //       gamesEvoplay: [],
// //       gamesGenisis: [],
// //       gamesPgsoft: [],
// //       gamesLiveCasinoProvider: [],
// //       gamesSpribe: [],
// //       gamesSports: [],
// //      gamesMostExpensive:[],
// //       error: null,
// //       loading: false,
// //       message: null,
// //       showMore: false,
// //       showMoreP: false,
// //       showMoreO: false,
// //       showMoreLCP: false,
// //       showMoreSP: false,
// //       showMoreSB: false,
// //       isMobileView: window.innerWidth <= 768,
// //       searchQuery: '',
// //       showIframe: false,
// //       iframeUrl: '',
// //       iframeLoading: true,
// //     };
// //     this.sections = ["/assets/images/evolutionicon.png", "/assets/images/ezubi.png", "/assets/images/pragmatic.png", "/assets/images/netent.png", "/assets/images/novomatic.png"];
// //     this.livecasino = ["/assets/images/live1.png", "/assets/images/live2.png", "/assets/images/live3.jpg", "/assets/images/live4.jpg", "/assets/images/live5.jpg", "/assets/images/live6.png"];
// //     this.itemsToShow = 5;
// //   }

// //   handleGameLaunch = async (gameCode, provider) => {
// //     this.setState({ loading: true, message: null });
// //     try {
// //       const { cookies } = this.props;
// //       const uid = cookies.get('uid');

// //       if (!uid) {
// //         throw new Error("Please login to play live game");
// //       }

// //       const response = await fetch('https://api.ibitplay.com/game_launch', {
// //         method: 'POST',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //         body: JSON.stringify({
// //           user_code: String(uid),
// //           provider_code: provider,
// //           game_code: gameCode
// //         }),
// //       });

// //       if (!response.ok) {
// //         throw new Error("Network response was not ok");
// //       }

// //       const result = await response.json();

// //       if (result.status === 1) {
// //         this.setState({ iframeUrl: result.launch_url, showIframe: true, iframeLoading: true  });
// //       } else if (result.status === 0 && result.detail === "user_code is not allowed to be empty") {
// //         throw new Error("Please login to play live game");
// //       } else {
// //         throw new Error("Failed to launch game: " + result.msg);
// //       }
// //     } catch (error) {
// //       this.setState({ message: { type: 'error', text: error.message } });
// //     } finally {
// //       this.setState({ loading: false });
// //       setTimeout(() => {
// //         this.setState({ message: null });
// //       }, 5000);
// //     }
// //   };

// //   handleGameLaunchNew = async (gameCode, provider) => {
// //     this.setState({ loading: true, message: null });
// //     try {
// //       const { cookies } = this.props;
// //       const uid = cookies.get('uid');
// //       if (!uid) {
// //         throw new Error("Please login to play live game");
// //       }
// //       const response = await fetch('https://api.ibitplay.com/game_launch_new', {
// //         method: 'POST',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //         body: JSON.stringify({
// //           user_code: String(uid),
// //           game_code: gameCode
// //         }),
// //       });

// //       if (!response.ok) {
// //         throw new Error("Network response was not ok");
// //       }

// //       const result = await response.json();

// //       if (result.status === "success" && result.content && result.content.game && result.content.game.url) {
// //         this.setState({ iframeUrl: result.content.game.url, showIframe: true , iframeLoading: true });
// //       } else if (result.status === "fail" && result.detail === "user_code is not allowed to be empty") {
// //         throw new Error("Please login to play live game");
// //       } else {
// //         throw new Error("Failed to launch game: " + result.error);
// //       }
// //     } catch (error) {
// //       this.setState({ message: { type: 'error', text: error.message } });
// //     } finally {
// //       this.setState({ loading: false });
// //       setTimeout(() => {
// //         this.setState({ message: null });
// //       }, 5000);
// //     }
// //   };

// //   handleBackButton = () => {
// //     this.setState({ showIframe: false, iframeUrl: '', iframeLoading: true });
// //   };

// //   handleIframeLoad = () => {
// //     this.setState({ iframeLoading: false });
// //   };

// //   componentDidMount() {
// //     this._isMounted = true;
// //     document.body.scrollTop = 0;
// //     document.documentElement.scrollTop = 0;

// //     if (isMobile()) {
// //       this.setState({ padding: 'p-1' });
// //     }

// //     window.addEventListener('resize', this.handleResize);
// //     this.handleResize();

// //     this.fetchGames("EVOLUTION").then(games => {
// //       this.setState({ gamesEvolution: games.map(game => ({ ...game, provider_code: "EVOLUTION" })) });
// //     });
// //     this.fetchGames("PRAGMATICLIVE").then(games => {
// //       this.setState({ gamesPragmatic: games.map(game => ({ ...game, provider_code: "PRAGMATICLIVE" })) });
// //     });
// //     this.fetchGames("PRAGMATIC").then(games => {
// //       this.setState({ gamesPragmaticlive: games.map(game => ({ ...game, provider_code: "PRAGMATIC" })) });
// //     });
// //     this.fetchGames("EVOPLAY").then(games => {
// //       this.setState({ gamesEvoplay: games.map(game => ({ ...game, provider_code: "EVOPLAY" })) });
// //     });
// //     this.fetchGames("DREAMTECH").then(games => {
// //       this.setState({ gamesGenisis: games.map(game => ({ ...game, provider_code: "DREAMTECH" })) });
// //     });
// //     this.fetchGames("PGSOFT").then(games => {
// //       this.setState({ gamesPgsoft: games.map(game => ({ ...game, provider_code: "PGSOFT" })) });
// //     });
// //     // this.fetchGamesNew("live_dealers").then(games => {
// //     //   const filteredGames = games.filter((game, index) => [1,6,0].includes(index));
// //     //   this.setState({ gamesLiveCasinoProvider: filteredGames.map(game => ({ ...game, provider_code: "live_dealers" })) });
// //     // });
// //     this.fetchGamesNew("spribe").then(games => {
// //       const filteredGames = games.filter((game, index) => [8,5,6,7].includes(index));
// //       this.setState({ gamesSpribe: filteredGames.map(game => ({ ...game, provider_code: "spribe" })) });
// //     });
// //     // this.fetchGamesNew("more_expensive").then(games => {
// //     //   this.setState({ gamesMostExpensive: games.map(game => ({ ...game, provider_code: "more_expensive" })) });
// //     //   gamesLiveCasinoProvider.concat(gamesMostExpensive);
// //     // });
// // this.fetchGamesNew("more_expensive").then(moreExpensiveGames => {
// //   const updatedMoreExpensiveGames = moreExpensiveGames.map(game => ({ ...game, provider_code: "more_expensive" }));
// //   this.setState(prevState => ({
// //     gamesMostExpensive: updatedMoreExpensiveGames,
// //     gamesLiveCasinoProvider: (prevState.gamesLiveCasinoProvider || []).concat(updatedMoreExpensiveGames)
// //   }));
// // });
// // this.fetchGamesNew("live_dealers").then(liveDealerGames => {
// //   const filteredLiveDealerGames = liveDealerGames.filter((game, index) => [1, 6, 0].includes(index));
// //   const updatedLiveDealerGames = filteredLiveDealerGames.map(game => ({ ...game, provider_code: "live_dealers" }));
// //   this.setState(prevState => ({
// //     gamesLiveCasinoProvider: (prevState.gamesLiveCasinoProvider || []).concat(updatedLiveDealerGames)
// //   }));
// // });

// //     this.fetchGamesNew("sport_betting").then(games => {
// //       this.setState({ gamesSports: games.map(game => ({ ...game, provider_code: "sport_betting" })) });
// //     });
// //   }

// //   componentWillUnmount() {
// //     window.removeEventListener('resize', this.handleResize);
// //     this._isMounted = false;
// //   }

// //   fetchGames = async (provider) => {
// //     try {
// //       const response = await fetch(`https://api.ibitplay.com/game-list?s=${provider}`, {
// //         method: 'GET',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //       });

// //       if (!response.ok) {
// //         throw new Error("Network response was not ok");
// //       }

// //       const result = await response.json();
// //       return result.games;
// //     } catch (error) {
// //       console.error("Failed to fetch data:", error.message);
// //       return [];
// //     }
// //   };

// //   fetchGamesNew = async (provider) => {
// //     try {
// //       const response = await fetch(`https://api.ibitplay.com/game-list-new?lable=${provider}`, {
// //         method: 'GET',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //       });

// //       if (!response.ok) {
// //         throw new Error("Network response was not ok");
// //       }

// //       const result = await response.json();
// //       if (result.games && result.games[provider]) {
// //         return result.games[provider];
// //       } else {
// //         throw new Error(`No games found for the provider: ${provider}`);
// //       }
// //     } catch (error) {
// //       console.error("Failed to fetch data:", error.message);
// //       return [];
// //     }
// //   };

// //   handleResize = () => {
// //     const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
// //     this.setState({ isMobileView: width <= 768 });
// //   };

// //   handleClick = (e) => {
// //     this.setState({ selectedSection: e });
// //   };

// //   handleArrowClick = (direction) => {
// //     const { sectionIndex } = this.state;
// //     const { sections, itemsToShow } = this;
// //     const totalSections = sections.length;

// //     let newIndex;

// //     if (direction === 'left') {
// //       newIndex = (sectionIndex - itemsToShow + totalSections) % totalSections;
// //     } else {
// //       newIndex = (sectionIndex + itemsToShow) % totalSections;
// //     }

// //     this.setState({ selectedSection: sections[newIndex], sectionIndex: newIndex });
// //   };

// //   handleShowMore = () => {
// //     this.setState({ showMore: !this.state.showMore });
// //   };

// //   handleShowMoreP = () => {
// //     this.setState({ showMoreP: !this.state.showMoreP });
// //   };

// //   handleShowMoreO = () => {
// //     this.setState({ showMoreO: !this.state.showMoreO });
// //   };

// //   handleShowMoreLCP = () => {
// //     this.setState({ showMoreLCP: !this.state.showMoreLCP });
// //   };

// //   handleShowMoreSP = () => {
// //     this.setState({ showMoreSP: !this.state.showMoreSP });
// //   };

// //   handleShowMoreSB = () => {
// //     this.setState({ showMoreSB: !this.state.showMoreSB });
// //   };

// //   handleSearch = (query) => {
// //     this.setState({ searchQuery: query });
// //   };

// //   getFilteredGames = (games) => {
// //     const { searchQuery } = this.state;
// //     if (!searchQuery) return games;
// //     return games.filter(game => game.game_name && game.game_name.toLowerCase().includes(searchQuery.toLowerCase()));
// //   };
// //   render() {
// //     let { content, t } = this.props;
// //     const { selectedSection, sectionIndex, slideSelected, gamesEvolution, gamesLiveCasinoProvider, gamesSpribe, gamesPragmatic, gamesPragmaticlive, gamesSports, gamesEvoplay, gamesGenisis, gamesPgsoft, gamesMostExpensive,loading, message, searchQuery, showMore, showMoreP, showMoreO, showMoreLCP, showMoreSP, showMoreSB, isMobileView, padding, showIframe, iframeUrl,iframeLoading } = this.state;
// //     const { sections, itemsToShow, livecasino } = this;

// //     const visibleSections = sections.slice(sectionIndex, sectionIndex + itemsToShow);

// //     const filteredGamesEvolution = this.getFilteredGames(gamesEvolution);
// //     const filteredGamesPragmatic = this.getFilteredGames(gamesPragmatic);
// //     const filteredGamesEvoplay = this.getFilteredGames(gamesEvoplay);
// //     const filteredGamesGenisis = this.getFilteredGames(gamesGenisis);
// //     const filteredGamesPgsoft = this.getFilteredGames(gamesPgsoft);
// //     const filteredSpribe = this.getFilteredGames(gamesSpribe);
// //     const filteredSports = this.getFilteredGames(gamesSports);
// //     const filtergamespragamaticlive = this.getFilteredGames(gamesPragmaticlive);
// //     const filtergamesLiveCasinoProvider = this.getFilteredGames(gamesLiveCasinoProvider);
// //     const displayedGames = isMobileView ? gamesEvolution : (showMore ? gamesEvolution : gamesEvolution.slice(0, 5));
// //     const displayedGamesSP = isMobileView ? gamesSpribe : (showMoreSP ? gamesSpribe : gamesSpribe.slice(0, 5));
// //     const displayedGamesSB = isMobileView ? gamesSports : (showMoreSB ? gamesSports : gamesSports.slice(0, 5));
// //     const displayedGamesLCP = isMobileView ? gamesLiveCasinoProvider: (showMoreLCP ? gamesLiveCasinoProvider : gamesLiveCasinoProvider.slice(0, 5));
// //     const displayGamesP = isMobileView ? gamesPragmatic : (showMoreP ? gamesPragmatic : gamesPragmatic.slice(0, 5));
// //     const displayGamesPL = isMobileView ? gamesPragmaticlive : (showMoreO ? gamesPragmaticlive : gamesPragmaticlive.slice(0, 5));

// //     // if (showIframe) {
// //     //   return (
// //     //     <FullScreenContainer>
// //     //       {this.state.iframeLoading && (
// //     //         // <Loader>
// //     //         //   <FontAwesomeIcon icon={faSpinner} spin />
// //     //         // </Loader>
// //     //         <img src="assets/images/lg.png" alt="Loading" style={imageStyle} />
// //     //       )}
// //     //       <FullScreenIframe
// //     //         src={iframeUrl}
// //     //         title="Game Iframe"
// //     //         onLoad={this.handleIframeLoad}
// //     //         loading={this.state.iframeLoading}
// //     //       />
// //     //       <BackButton onClick={this.handleBackButton}>
// //     //         <FontAwesomeIcon icon={faArrowLeft} />
// //     //       </BackButton>
// //     //     </FullScreenContainer>
// //     //   );
// //     // }
// //     const imageStyle = {
// //       position: 'absolute',
// //       top: '50%',
// //       left: '50%',
// //       transform: 'translate(-50%, -50%)',
// //       width: '250px',
// //       animation: 'fadeinout 2s infinite',
// //     };
// //     const animationStyle = `
// //       @keyframes fadeinout {
// //         0%, 100% { opacity: 0; }
// //         50% { opacity: 1; }
// //       }
// //     `;
// //     if (showIframe) {
// //       return (
// //         <FullScreenContainer>
// //           <style>{animationStyle}</style>
// //           {this.state.iframeLoading && (
// //             <img src="assets/images/lg.png" alt="Loading" style={imageStyle} />
// //           )}
// //           <FullScreenIframe
// //             src={iframeUrl}
// //             title="Game Iframe"
// //             onLoad={this.handleIframeLoad}
// //             loading={this.state.iframeLoading}
// //           />
// //           <BackButton onClick={this.handleBackButton}>
// //             <FontAwesomeIcon icon={faArrowLeft} />
// //           </BackButton>
// //         </FullScreenContainer>
// //       );
// //     }

// //     return (
// //       <>
// //         {this._isMounted &&
// //           <>
// //             <Helmet>
// //               <title>{BRAND} - Crypto Casino Games and Crypto Gambling</title>
// //               <meta name="keywords" content="WEB 3.0 Crypto Casino Games, Online Casino Games, Bitcoin Crash Game, Dice Games, Live Casino Games, Crypto Casino Games, Blockchain Games, Bitcoin Casino Games, Best Crypto Online Game, Crypto Gambling Games, PlayCrash.com Crypto Games" />
// //               <meta name="description"
// //                 content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
// //               <meta name="og:title" content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
// //               <meta name="og:description" content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
// //             </Helmet>

// //             {/* Page Content */}
// //             {content}

// //             <div style={{ marginTop: -20 }}>
// //               <Carousel />
// //                 {isMobileView && ( // Render only if it's mobile view
// //           <Card className="brd7" style={{ marginTop: -5,width:'97%',marginLeft:4, marginBottom: 0,backgroundColor:'#90909020',paddingTop:7,paddingBottom:7, }}>
// //             <Card.Body className="py-2" style={{ display: 'flex', width: '100%', alignItems: 'center',
// //                textAlign: 'center',  }}>
// //               <div
// //                 style={{
// //                   // display: 'flex',
// //                   alignItems: 'center',
// //                   justifyContent:'center',
// //                   cursor: 'pointer',
// //                   marginLeft:-10,
// //                   borderRadius: 8,
// //                   backgroundColor: selectedSection === 'home' ? '#2A5846' : 'transparent',
// //                   paddingTop: '3px', // Adjust the top padding as needed
// //                   paddingBottom: '3px', // Adjust the bottom padding as needed
// //                 }}
// //                 onClick={() => this.handleClick('home')}
// //               >
// //                 <img src="/assets/images/gacor.png" style={{ height: 28, width: 28,  }} />
// //                 <h6 style={{ fontSize: 10,color:'white',marginTop:5, }} className="section-title">Gacor</h6>
// //               </div>
// //               <div
// //                 style={{
// //                   alignItems: 'center',
// //                   cursor: 'pointer',
// //                   backgroundColor: selectedSection === 'originals' ? '#2A5846' : 'transparent',
// //                   borderRadius: 8,
// //                   paddingTop: '3px', // Adjust the top padding as needed
// //                   paddingBottom: '3px', // Adjust the bottom padding as needed
// //                 }}
// //                 onClick={() => this.handleClick('originals')}
// //               >
// //                 <img src="/assets/images/original.png" style={{ height: 28, width: 28, color: 'yellow' }} />
// //                 <h6 style={{ fontSize:10,marginTop:5,color:'white', }}  className="section-title">ORIGINAL</h6>
// //               </div>
// //               <div
// //                 style={{
// //                   alignItems: 'center',
// //                   cursor: 'pointer',
// //                   backgroundColor: selectedSection === 'sports' ? '#2A5846' : 'transparent',
// //                   borderRadius: 8,
// //                   paddingTop: '3px', // Adjust the top padding as needed
// //                   paddingBottom: '3px', // Adjust the bottom padding as needed
// //                 }}
// //                 onClick={() => this.handleClick('sports')}
// //               >
// //                 <img src="/assets/images/slots.png" style={{ height: 28, width: 28,alignSelf:'center', color: 'yellow' }} />
// //                 <h6 style={{ fontSize: 10,marginTop:5,color:'white' }} className="section-title">Slots</h6>
// //               </div>
// //               <div
// //                 style={{
// //                   alignItems: 'center',
// //                   cursor: 'pointer',
// //                   backgroundColor: selectedSection === 'casino' ? '#2A5846' : 'transparent',
// //                   borderRadius: 8,
// //                   paddingTop: '3px', // Adjust the top padding as needed
// //                   paddingBottom: '3px', // Adjust the bottom padding as needed
// //                 }}
// //                 onClick={() => this.handleClick('casino')}
// //               >
// //                 <img src="/assets/images/casino.png" style={{ height: 28, width: 28, color: 'yellow' }} />
// //                 <h6 style={{ fontSize: 10,marginTop:5,color:'white' }} className="section-title">CASINO</h6>
// //               </div>
// //               <div
// //                 style={{
// //                   alignItems: 'center',
// //                   cursor: 'pointer',
// //                   backgroundColor: selectedSection === 'fishing' ? '#2A5846' : 'transparent',
// //                   borderRadius: 8,
// //                   paddingTop: '3px', // Adjust the top padding as needed
// //                   paddingBottom: '3px', // Adjust the bottom padding as needed
// //                 }}
// //                 onClick={() => this.handleClick('fishing')}
// //               >
// //                 <img src="/assets/images/fishing.png" style={{ height: 28, width: 28, color: 'yellow' }} />
// //                 <h6 style={{ fontSize: 10,marginTop:5,color:'white' }} className="section-title">Fishing</h6>
// //               </div>
// //             </Card.Body>
// //           </Card>
// //         )}

// //             <div style={{marginTop:15,width:'100%'}} >

// //             <img src="/assets/images/livertp.png" style={{ height: 70, width: '97%',alignSelf:'center', borderRadius:10,marginLeft:4,marginRight:20 }} />
// //             </div>
// //               {/* Search Bar */}
// //               {/* <Container fluid className="py-2">
// //                 <Row>
// //                   <Col xs={12} className="px-0">
// //                     <div style={{ position: 'relative', width: '100%' }}>
// //                       <div style={{
// //                         position: 'absolute',
// //                         top: '50%',
// //                         left: '8px',
// //                         transform: 'translateY(-50%)',
// //                         zIndex: 10,
// //                       }}>
// //                         <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#555" viewBox="0 0 16 16">
// //                           <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
// //                         </svg>
// //                       </div>
// //                       <FormControl
// //                         type="text"
// //                         placeholder="Search games..."
// //                         value={searchQuery}
// //                         onChange={(e) => this.handleSearch(e.target.value)}
// //                         style={{
// //                           padding: '2px 5px 2px 28px',
// //                           height: '28px',
// //                           width: 'calc(100% - 10px)',
// //                           margin: '0 5px',
// //                         }}
// //                       />
// //                     </div>
// //                   </Col>
// //                 </Row>
// //               </Container> */}

// //               {/* Filtered Games Display */}
// //               {searchQuery && (
// //                 <Card className="brd7">
// //                   <Card.Body className={this.state.padding}>
// //                     <h4 className="mt-2 mb-2 section-title"> Search Results</h4>
// //                     <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
// //                       <div style={{
// //                         display: 'flex',
// //                         flexWrap: isMobileView ? 'nowrap' : 'wrap',
// //                         width: '100%',
// //                         overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
// //                         WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
// //                         msOverflowStyle: 'none', // hide scrollbar in IE and Edge
// //                         scrollbarWidth: 'none', // hide scrollbar in Firefox
// //                       }}>
// //                         {filteredGamesEvolution.concat(filteredGamesPragmatic, filteredGamesEvoplay, filteredGamesGenisis, filteredGamesPgsoft, filtergamespragamaticlive,).map((game, index) => (
// //                           <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
// //                             <img src={game.banner} style={{ width: '150px', height: '175px', borderRadius: 10, marginBottom: '10px' }} />
// //                           </div>
// //                         ))}
// //                       </div>
// //                     </div>
// //                   </Card.Body>
// //                 </Card>
// //               )}

// // <Card className="brd7" id="#live" style={{ marginTop: 15, marginBottom: 10, width: '100%', display: 'block' }}>
// //                 <Card.Body className={this.state.padding}>

// //                   {/* Evoplay Games */}
// //                   <div className="d-flex w-full" style={{ overflow: 'hidden', height: '150px', marginBottom: '10px' }}>
// //                     <div style={{ display: 'flex', width: '100%', overflowY: 'auto', scrollbarColor: 'transparent transparent', scrollbarWidth: 'none' }}>
// //                       {filteredGamesEvoplay.map((game) => (
// //                         <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
// //                           <img src={game.banner} style={{ width: '110px', height: '150px', borderRadius: 10, marginRight: 10, marginLeft: 3 }} />
// //                         </div>
// //                       ))}
// //                     </div>
// //                   </div>

// //                   {/* Genesis Games */}
// //                   <div className="d-flex w-full" style={{ overflow: 'hidden', height: '150px', marginBottom: '10px' }}>
// //                     <div style={{ display: 'flex', width: '100%', overflowY: 'auto', scrollbarColor: 'transparent transparent', scrollbarWidth: 'none' }}>
// //                       {filteredGamesGenisis.map((game) => (
// //                         <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
// //                           <img src={game.banner} style={{ width: '110px', height: '150px', borderRadius: 10, marginRight: 10, marginLeft: 3 }} />
// //                         </div>
// //                       ))}
// //                     </div>
// //                   </div>

// //                   {/* Pgsoft Games */}
// //                   <div className="d-flex w-full" style={{ overflow: 'hidden', height: '150px' }}>
// //                     <div style={{ display: 'flex', width: '100%', overflowY: 'auto', scrollbarColor: 'transparent transparent', scrollbarWidth: 'none' }}>
// //                       {filteredGamesPgsoft.map((game) => (
// //                         <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
// //                           <img src={game.banner} style={{ width: '110px', height: '150px', borderRadius: 10, marginRight: 10, marginLeft: 3 }} />
// //                         </div>
// //                       ))}
// //                     </div>
// //                   </div>
// //                 </Card.Body>
// //               </Card>
// //               <Card className="brd7">
// //                 <Card.Body className={this.state.padding}>
// //                   <h4 className="mt-2 mb-2 section-title"> Most Popular</h4>
// //                   <Popular t={t} />
// //                 </Card.Body>
// //               </Card>

// //                {/* {isMobileView && ( // Render only if it's mobile view
// //           <Card style={{ marginTop: -15, marginBottom: 10, width: '110%', marginLeft: -10 }}>
// //             <Card.Body className={padding}>
// //               <div className="d-flex w-full" style={{ overflow: 'hidden' }}>
// //                 <div style={{ display: 'flex', width: '100%', overflowY: 'scroll' }}>
// //                   {visibleSections.map((section, index) => (
// //                     <div
// //                       key={index}
// //                       style={{
// //                         display: 'flex',
// //                         alignItems: 'center',
// //                         cursor: 'pointer',
// //                         borderRadius: 10,
// //                         backgroundColor: slideSelected === section ? "#90909020" : 'transparent',
// //                         paddingTop: '2px', // Adjust the top padding as needed
// //                         paddingBottom: '2px', // Adjust the bottom padding as needed
// //                       }}
// //                       onClick={() => this.handleClick(section)}
// //                     >
// //                       <img src={section} style={{ width: '117px', height: 'auto', marginRight: 10, marginLeft: 3 }} />
// //                     </div>
// //                   ))}
// //                 </div>
// //               </div>
// //             </Card.Body>
// //           </Card>
// //         )} */}

// // <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
// //         <Card.Body className={this.state.padding}>
// //           <h4 className="mt-2 mb-2 section-title">Live Casino Providers</h4>
// //           <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
// //             <div style={{
// //               display: 'flex',
// //               flexWrap: isMobileView ? 'nowrap' : 'wrap',
// //               width: '100%',
// //               overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
// //               WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
// //               msOverflowStyle: 'none', // hide scrollbar in IE and Edge
// //               scrollbarWidth: 'none', // hide scrollbar in Firefox
// //             }}>
// //               {displayedGamesLCP.map((game, index) => (
// //                 <div key={game.id} onClick={() => this.handleGameLaunchNew(game.id, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
// //                   <img src={game.img} style={{ width: '125px', height: '145px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
// //                 </div>
// //               ))}
// //             </div>
// //           </div>
// //           {!isMobileView && filtergamesLiveCasinoProvider.length > 5 && (
// //             <div className="text-center">
// //               <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreLCP}>
// //                 <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreLCP ? 'Show Less' : 'Show More'}
// //               </div>
// //             </div>
// //           )}
// //         </Card.Body>
// //       </Card>

// // <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
// //         <Card.Body className={this.state.padding}>
// //           <h4 className="mt-2 mb-2 section-title">EVOLUTION</h4>
// //           <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
// //             <div style={{
// //               display: 'flex',
// //               flexWrap: isMobileView ? 'nowrap' : 'wrap',
// //               width: '100%',
// //               overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
// //               WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
// //               msOverflowStyle: 'none', // hide scrollbar in IE and Edge
// //               scrollbarWidth: 'none', // hide scrollbar in Firefox
// //             }}>
// //               {displayedGames.map((game, index) => (
// //                 <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
// //                   <img src={game.banner} style={{ width: '150px', height: '175px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
// //                 </div>
// //               ))}
// //             </div>
// //           </div>
// //           {!isMobileView && filteredGamesEvolution.length > 5 && (
// //             <div className="text-center">
// //               <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMore}>
// //                 <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMore ? 'Show Less' : 'Show More'}
// //               </div>
// //             </div>
// //           )}
// //         </Card.Body>
// //       </Card>

// //       <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
// //         <Card.Body className={this.state.padding}>
// //           <h4 className="mt-2 mb-2 section-title">Spribe</h4>
// //           <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
// //             <div style={{
// //               display: 'flex',
// //               flexWrap: isMobileView ? 'nowrap' : 'wrap',
// //               width: '100%',
// //               overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
// //               WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
// //               msOverflowStyle: 'none', // hide scrollbar in IE and Edge
// //               scrollbarWidth: 'none', // hide scrollbar in Firefox
// //             }}>
// //               {displayedGamesSP.map((game, index) => (
// //                 <div key={game.id} onClick={() => this.handleGameLaunchNew(game.id, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
// //                   <img src={game.img} style={{ width: '85px', height: '85px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
// //                 </div>
// //               ))}
// //             </div>
// //           </div>
// //           {!isMobileView && filteredSpribe.length > 5 && (
// //             <div className="text-center">
// //               <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreSP}>
// //                 <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreSP ? 'Show Less' : 'Show More'}
// //               </div>
// //             </div>
// //           )}
// //         </Card.Body>
// //       </Card>

// //       <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
// //         <Card.Body className={this.state.padding}>
// //           <h4 className="mt-2 mb-2 section-title">PRAGMATIC LIVE</h4>
// //           <div className="d-flex w-full" style={{ overflow: 'hidden' }}>
// //             <div style={{
// //               display: 'flex',
// //               flexWrap: isMobileView ? 'nowrap' : 'wrap',
// //               width: '100%',
// //               overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
// //               WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
// //               msOverflowStyle: 'none', // hide scrollbar in IE and Edge
// //               scrollbarWidth: 'none', // hide scrollbar in Firefox
// //             }}>
// //               {displayGamesP.map((game, index) => (
// //                 <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
// //                   <img src={game.banner} style={{ width: '135px', height: '155px', borderRadius: 10, marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: 3 }} alt={`Game ${index}`} />
// //                 </div>
// //               ))}
// //             </div>
// //           </div>
// //           {!isMobileView && filteredGamesPragmatic.length > 5 && (
// //             <div className="text-center">
// //               <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreP}>
// //                 <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreP ? 'Show Less' : 'Show More'}
// //               </div>
// //             </div>
// //           )}
// //         </Card.Body>
// //       </Card>

// //       <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
// //         <Card.Body className={this.state.padding}>
// //           <h4 className="mt-2 mb-2 section-title">PRAGMATIC</h4>
// //           <div className="d-flex w-full" style={{ overflow: 'hidden' }}>
// //             <div style={{
// //               display: 'flex',
// //               flexWrap: isMobileView ? 'nowrap' : 'wrap',
// //               width: '100%',
// //               overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
// //               WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
// //               msOverflowStyle: 'none', // hide scrollbar in IE and Edge
// //               scrollbarWidth: 'none', // hide scrollbar in Firefox
// //             }}>
// //               {displayGamesPL.map((game, index) => (
// //                 <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
// //                   <img src={game.banner} style={{ width: '150px', height: '175px', borderRadius: 10, marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: 3 }} alt={`Game ${index}`} />
// //                 </div>
// //               ))}
// //             </div>
// //           </div>
// //           {!isMobileView && filtergamespragamaticlive.length > 5 && (
// //             <div className="text-center">
// //               <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreO}>
// //                 <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreO ? 'Show Less' : 'Show More'}
// //               </div>
// //             </div>
// //           )}
// //         </Card.Body>
// //       </Card>

// //               <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
// //         <Card.Body className={this.state.padding}>
// //           <h4 className="mt-2 mb-2 section-title">Sports</h4>
// //           <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
// //             <div style={{
// //               display: 'flex',
// //               flexWrap: isMobileView ? 'nowrap' : 'wrap',
// //               width: '100%',
// //               overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
// //               WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
// //               msOverflowStyle: 'none', // hide scrollbar in IE and Edge
// //               scrollbarWidth: 'none', // hide scrollbar in Firefox
// //             }}>
// //               {displayedGamesSB.map((game, index) => (
// //                 <div key={game.id} onClick={() => this.handleGameLaunchNew(game.id, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
// //                   <img src={game.img} style={{ width: '135px', height: '165px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
// //                 </div>
// //               ))}
// //             </div>
// //           </div>
// //           {!isMobileView && filteredSports.length > 5 && (
// //             <div className="text-center">
// //               <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreSB}>
// //                 <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreSB ? 'Show Less' : 'Show More'}
// //               </div>
// //             </div>
// //           )}
// //         </Card.Body>
// //       </Card>
// //               <Card className="brd7" id="originals">
// //                 <Card.Body className={this.state.padding + ' pb-3'}>
// //                   <h4 className="mt-2 mb-2 section-title"> {t('originals')} Games</h4>
// //                   <Games t={t} />
// //                 </Card.Body>
// //               </Card>
// //               <Card className="brd7">
// //                 <Card.Body className={this.state.padding}>
// //                   <h4 className="mt-2 mb-2 section-title"> Free Slots</h4>
// //                   <Slots home={true} />
// //                   <div className="text-center">
// //                     <Link className="btn btn-more btn-sm px-4 mt-1 mb-3" to="/slots">
// //                       <i className="mdi mdi-arrow-right-bold-box align-middle" /> Show More
// //                     </Link>
// //                   </div>
// //                 </Card.Body>
// //               </Card>
// //             </div>
// //           </>
// //         }

// //         {/* Loader */}
// //         {loading && (
// //           <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
// //             <Spinner animation="border" role="status">
// //               <span className="sr-only">Loading...</span>
// //             </Spinner>
// //           </div>
// //         )}

// //         {/* Snackbar */}
// //         {message && (
// //           <Alert variant={message.type === 'error' ? 'danger' : 'success'} style={{ position: 'fixed', bottom: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 9999 }}>
// //             {message.text}
// //           </Alert>
// //         )}
// //       </>
// //     );
// //   }
// // }

// // export default withCookies(Home);

import { ArrowRight, Info, MoveRight, Sparkles, Star } from "lucide-react";
import styled from "styled-components";
import { useHistory } from 'react-router-dom';
import { StakeCarousel } from "../../../Components/StakeCarousel";
import RacesRaffles from "../../../Components/Races";
import { CardActions } from "@mui/material";
import { BadgeDollarSign } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import storage from "../../../../Storage";
import BettingDashboard from "./Stake/BettingDashboard";
import CasinoLeaderboard from "../../../Components/CasinoLeaderBoard";

const Container = styled.div`
  background-image: url("/assets/images/registerbg.svg");
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  align-items: center;

  // Apply flex-direction: column for mobile devices
  flex-direction: column;

  // Apply flex-direction: row for larger screens (e.g., tablets and desktops)
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const ContainerPlaySmarter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;



const Title = styled.h1`
  font-size: 20px;

  font-weight: bold;
`;

const RegisterButton = styled.button`
  background-color: #3a7bfd;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 9px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
`;

const OrTextWrapper = styled.div`
  display: flex;
  gap: 5px;
`;
const Line = styled.div`
  height: 2px;
  width: 85px;
  background-color: #1a2c38;
  margin-top: 9px;
  border-radius: 3px;
`;

const OrText = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  color: #b1bad3;
  font-weight: bold;
`;

const SocialButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const SocialButton = styled.button`
  background-color: #2f4553;
  border: none;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SponsorshipSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SponsorsImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const SponsorLogos = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const SponsorLogo = styled.img`
  max-width: 80px;
  height: auto;
`;
const breakpoints = {
  mobile: "768px", // Mobile devices
  desktop: "1024px", // Desktop devices
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column; /* Default to column layout (mobile-first) */
`;

const BoxContainer = styled.div`
  background-color: #1A2C38;
  color: white;
  font-family: Arial, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 12px;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
  padding: 10px 12px;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  margin: 0;
  margin-left: 10px;
`;

const Banner = styled.div`
  position: relative;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BannerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Description = styled.p`
  font-size: 14px;
  color: #a0a0a0;
  line-height: 1.4;
  margin-bottom: 15px;
`;

const Button = styled.button`
  background-color: #3a7bfd;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 12px;
`;
const BottomContainer = styled.div`
  font-family: Arial, sans-serif;
  width: 100%;
  margin: 0 auto;
`;

const PartnershipBanner = styled.div`
  background-color: #1e2328;
  color: white;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-right: 15px;
`;

const Separator = styled.div`
  width: 1px;
  height: 20px;
  background-color: #ffffff;
  margin: 0 15px;
`;

const LearnMoreButton = styled.button`
  background-color: #2c3137;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
`;

const PaymentOptionsSection = styled.div`
  background-color: #0f212e;
  color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items:center;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 12px;
    flex: 1 1 auto;
    justify-content: space-around;
    align-items: center;
  }
`;

const SectionTitle1 = styled.div`
  font-size: 22px;
  margin: 0 0 20px 0;
`;

const PaymentOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const PaymentLogo = styled.div`
  // background-color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BuyCryptoButton = styled.button`
  background-color: #2F4553;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  width: 220px;
  cursor: pointer;
  font-size: 16px;
`;

const BackgroundContainer = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: center;

  @media (min-width: 1320px) {
    padding-left: 70px;
    padding-right: 70px;
  }
`;

const CardComp = styled.div`
  background-color: #1e2630;
  border-radius: 8px;
  padding: 33px 16px;
  color: white;
  border: 1px solid #fed100;
  flex: 1;
`;

const Conatiner = styled.div`
  border-radius: 8px;
  padding: 8px;
  color: white;
  background-color: #213743;

  @media (min-width: 1400px) {
    max-width: 560px;
    flex: 0.5;
  }

  @media (min-width: 769px) and (max-width: 1399px) {
    width: 100%;
    max-width: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
`;

const Username = styled.h2`
  margin: 0 0 16px 0;
  font-size: 18px;
`;

const ProgressSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 16px;
`;

const ProgressText = styled.span`
  font-size: 14px;
`;

const ProgressBar = styled.div`
  background-color: #2a3541;
  height: 8px;
  border-radius: 4px;
  width: 100%;
  margin: 8px 0;
`;

const ProgressFill = styled.div`
  background-color: #fed100;
  height: 100%;
  border-radius: 4px;
`;

const LevelSection = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

const GamingOptionsWrapper = styled.div`
  background-color: #1a2633;
  padding: 20px;
  border-radius: 10px;
  @media (min-width: 769px) {
    display: none;
  }
`;

const GamingOptionsWrapperWeb = styled.div`
  padding: 20px;
  flex: 1;

  @media (max-width: 1400px) {
    display: none;
  }
`;

const GamingCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;
const GamingCardsContainerWeb = styled.div`
  display: flex;
  gap: 6px;
  justify-content: end;
`;

const GamingOption = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

const GamingOptionWeb = styled.div`
  width: 46%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

const GamingOptionImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const GamingOptionLabel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1a2c38;
  padding: 10px;
  color: white;
  font-weight: bold;
`;

const StartPlayingButton = styled.div`
  color: white;
  font-size: 18px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const PlayIcon = styled.span`
  margin-right: 10px;
`;

const HomeAuthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const GamesContainer = styled.div`
  background-color: #1a2c38;
  padding: 0 10px;
`;

//   background-color: #1a2633;
//   padding: 20px;
//   border-radius: 10px;
// `;

// const GamingCardsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const GamingOption = styled.div`
//   width: 48%;
//   border-radius: 8px;
//   overflow: hidden;
//   position: relative;
// `;

// const GamingOptionImage = styled.img`
//   width: 100%;
//   height: auto;
//   display: block;
// `;

// const GamingOptionLabel = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: rgba(0, 0, 0, 0.7);
//   padding: 10px;
//   color: white;
//   font-weight: bold;
// `;


const trendingData = [
  {
    id: 1,
    title: "DICE",
    subtitle: "Stake Originals",
    players: 7437,
    backgroundColor: "#8B5CF6",
     icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/staketrendingsGames/zeus.avif"
  },
  {
    id: 2,
    title: "CRASH",
    subtitle: "Stake Originals",
    players: 3967,
    backgroundColor: "#FBBF24",
    icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/staketrendingsGames/six.avif"
  },
  {
    id: 3,
    title: "PLINKO",
    subtitle: "Stake Originals",
    players: 4389,
    backgroundColor: "#EC4899",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/staketrendingsGames/olyms.avif"
  },
  {
    id: 4,
    title: "MINES",
    subtitle: "Stake Originals",
    players: 14849,
    backgroundColor: "#3B82F6",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakelivecasino/stakebetcity.avif"
  },
  {
    id: 5,
    title: "LIMBO",
    subtitle: "Stake Originals",
    players: 6809,
    backgroundColor: "#F59E0B",
    icon: '/assets/images/stakeicons/originalstake.png',
     imageUrl: "/assets/images/staketrendingsGames/hide.avif"
  },
  {
    id: 6,
    title: "KENO",
    subtitle: "Stake Originals",
    players: 2668,
    backgroundColor: "#2563EB",
    icon: '/assets/images/stakeicons/originalstake.png',
     imageUrl: "/assets/images/staketrendingsGames/oldgun.avif"
  },
  {
    id: 7,
    title: "HILO",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/staketrendingsGames/sweet.avif"
  },
  {
    id: 8,
    title: "BLACKJACK",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/staketrendingsGames/suger.avif"

  },
  {
    id: 9,
    title: "DRAGON TOWER",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
       imageUrl: "/assets/images/staketrendingsGames/zeus.avif"

  },
  {
    id: 10,
    title: "DRAGON TOWER",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
       imageUrl: "/assets/images/staketrendingsGames/sweet.avif"

  }
];


const trendingSportsData = [
  {
    id: 1,
    title: "DICE",
    subtitle: "Stake Originals",
    players: 7437,
    backgroundColor: "#8B5CF6",
     icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/staketrendingsports/tennis.avif"
  },
  {
    id: 2,
    title: "CRASH",
    subtitle: "Stake Originals",
    players: 3967,
    backgroundColor: "#FBBF24",
    icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/staketrendingsports/poli.avif"
  },
  {
    id: 3,
    title: "PLINKO",
    subtitle: "Stake Originals",
    players: 4389,
    backgroundColor: "#EC4899",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/staketrendingsports/baseball.avif"
  },
  {
    id: 4,
    title: "MINES",
    subtitle: "Stake Originals",
    players: 14849,
    backgroundColor: "#3B82F6",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/staketrendingsports/cricket.avif"
  },
  {
    id: 5,
    title: "LIMBO",
    subtitle: "Stake Originals",
    players: 6809,
    backgroundColor: "#F59E0B",
    icon: '/assets/images/stakeicons/originalstake.png',
     imageUrl: "/assets/images/staketrendingsports/basketball.avif"
  },
  {
    id: 6,
    title: "KENO",
    subtitle: "Stake Originals",
    players: 2668,
    backgroundColor: "#2563EB",
    icon: '/assets/images/stakeicons/originalstake.png',
     imageUrl: "/assets/images/staketrendingsports/soccer.avif"
  },
  {
    id: 7,
    title: "HILO",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/staketrendingsports/tennis.avif"
  },
  {
    id: 8,
    title: "BLACKJACK",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/staketrendingsports/racing.avif"

  },
  // {
  //   id: 9,
  //   title: "DRAGON TOWER",
  //   subtitle: "Stake Originals",
  //   players: 1662,
  //   backgroundColor: "#10B981",
  //   icon: '/assets/images/stakeicons/originalstake.png',
  //      imageUrl: "/assets/images/staketrendingsGames/zeus.avif"

  // },
  // {
  //   id: 10,
  //   title: "DRAGON TOWER",
  //   subtitle: "Stake Originals",
  //   players: 1662,
  //   backgroundColor: "#10B981",
  //   icon: '/assets/images/stakeicons/originalstake.png',
  //      imageUrl: "/assets/images/staketrendingsGames/sweet.avif"

  // }
];

export default function Home({ progress = 25.7 }) {
  const [isLogged, setIsLogged] = useState(true);
  const history= useHistory();
  useEffect(() => {
    const logged = storage.getKey("logged") ? storage.getKey("logged") : false;
    if (logged) {
      setIsLogged(logged);
    }
  }, []);
  return (
    <>
      {isLogged ? (
        <>
          <BackgroundContainer imageUrl="/assets/images/homeAuthBg.png">
            <HomeAuthWrapper>
              <Conatiner>
                <CardComp>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Username>
                      {storage.getKey("name") ? storage.getKey("name") : ""}
                    </Username>
                    <Star color="#FED100" />
                  </div>
                  <ProgressSection>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <ProgressText>Your VIP Progress</ProgressText>
                      {/* <MoveRight/> */}
                      <ArrowRight size={16} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <ProgressText>{progress.toFixed(2)}%</ProgressText>
                      <Info size={18} />
                    </div>
                  </ProgressSection>
                  <ProgressBar>
                    <ProgressFill progress={progress} />
                  </ProgressBar>
                  <LevelSection>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Star color="#FED100" size={20} />
                      <span>Gold</span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Sparkles size={20} />
                      <span>Platinum I</span>
                    </div>
                  </LevelSection>
                </CardComp>
              </Conatiner>
              <GamingOptionsWrapperWeb>
                <GamingCardsContainerWeb>
                  <GamingOptionWeb>
                    <GamingOptionImage
                      src="/assets/images/dice-img.png"
                      alt="Casino"
                    />
                    <GamingOptionLabel>Casino</GamingOptionLabel>
                  </GamingOptionWeb>
                  <GamingOptionWeb>
                    <GamingOptionImage
                      src="/assets/images/football-img.png"
                      alt="Sportsbook"
                    />
                    <GamingOptionLabel>Sportsbook</GamingOptionLabel>
                  </GamingOptionWeb>
                </GamingCardsContainerWeb>
              </GamingOptionsWrapperWeb>
            </HomeAuthWrapper>
          </BackgroundContainer>
          <GamingOptionsWrapper>
            <StartPlayingButton>
              <PlayIcon>▶</PlayIcon>
              Start playing
            </StartPlayingButton>
            <GamingCardsContainer>
              <GamingOption>
                <GamingOptionImage
                  src="/assets/images/dice-img.png"
                  alt="Casino"
                />
                <GamingOptionLabel>Casino</GamingOptionLabel>
              </GamingOption>
              <GamingOption>
                <GamingOptionImage
                  src="/assets/images/football-img.png"
                  alt="Sportsbook"
                />
                <GamingOptionLabel>Sportsbook</GamingOptionLabel>
              </GamingOption>
            </GamingCardsContainer>
          </GamingOptionsWrapper>
          <GamesContainer>
            <StakeCarousel title="Trending Games" data={trendingData} />
            {/* <StakeCarousel title="Trending Games" data={trendingData} /> */}
            <StakeCarousel title="Trending Sports" data={trendingSportsData} />
            <RacesRaffles />

            <CasinoLeaderboard />
          </GamesContainer>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex ",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CenteredContainer>
            <Container>

              <ContainerPlaySmarter>
              <Title>Play Smarter</Title>
              <RegisterButton>Register instantly</RegisterButton>
              <OrTextWrapper>
                <Line />
                <OrText>OR</OrText>
                <Line />
              </OrTextWrapper>

              <SocialButtons>
                <SocialButton>
                  <div
                    style={{
                      objectFit: "contain",
                      height: "20px",
                      width: "20px",
                    }}
                  >
                    <img
                      src="/assets/images/f1.png"
                      alt="Facebook"
                      style={{ objectFit: "contain", width: "100%" }}
                    />
                  </div>
                </SocialButton>

                <SocialButton>
                  <div
                    style={{
                      objectFit: "contain",
                      height: "28px",
                      width: "28px",
                    }}
                  >
                    <img
                      src="/assets/images/g1.webp"
                      alt="Google"
                      style={{ objectFit: "contain", width: "100%" }}
                    />
                  </div>
                </SocialButton>

                <SocialButton>
                  <div
                    style={{
                      objectFit: "contain",
                      height: "40px",
                      width: "40px",
                    }}
                  >
                    <img
                      src="/assets/images/w1.webp"
                      alt="wsp"
                      style={{ objectFit: "contain", width: "100%" }}
                    />
                  </div>
                </SocialButton>

                <SocialButton>
                  <div
                    style={{
                      objectFit: "contain",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <img
                      src="/assets/images/telegram.webp"
                      alt="Twitch"
                      style={{ objectFit: "contain", width: "100%" }}
                    />
                  </div>
                </SocialButton>
              </SocialButtons>
              </ContainerPlaySmarter>

           
              <SponsorshipSection>
                <img
                  style={{
                    objectPosition: "left",
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                  src="https://mediumrare.imgix.net/welcome-top-en.png?&dpr=1.25&format=auto&auto=format&w=540&q=50"
                  alt="sponser"
                />
              </SponsorshipSection>
            </Container>
          </CenteredContainer>
          <CenteredContainer>
            <Wrapper>
              <BoxContainer>
                <Section>
              
                  <Banner>
                    <div
                      style={{
                        marginBottom: "8px",
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <BannerImage
                        style={{
                          height: "20px",
                          width: "27px",
                          objectFit: "contain",
                        }}
                        src="/assets/images/stakeicons/casinostake.png"
                        alt="Casino Banner"
                      />
                      <div style={{fontSize:'18px', fontWeight:'bold'}}>Casino</div>
                    </div>
                    <div>
                      <img
                        style={{
                          objectFit: "contain",
                          borderRadius: "8px",
                          width: "100%",
                        }}
                        src="/assets/images/casino-banner.png"
                        altt="casino"
                      />
                    </div>
                  </Banner>
                  <Description>
                    Leading Online Casino Browse our giant range of casino games
                    as Stake offers a fair and fun online gambling experience.
                    Play Slots, Live Casino, Blackjack, Baccarat, Roulette, and
                    thousands of classic casino games right from your browser,
                    including your favourite Stake Originals.
                  </Description>
                  <Button onClick={()=>history.push("/home/casino")}>Go to Casino</Button>
                </Section>

                <Section>

                  <Banner>
                  <div
                      style={{
                        marginBottom: "8px",
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <BannerImage
                        style={{
                          height: "20px",
                          width: "27px",
                          objectFit: "contain",
                        }}
                        src="/assets/images/stakeicons/sportsstake.png"
                        alt="Casino Banner"
                      />
                      <div style={{fontSize:'18px', fontWeight:'bold'}}>Sports</div>
                    </div>
                    <div>
                      <img
                        style={{
                          objectFit: "contain",
                          borderRadius: "8px",
                          width: "100%",
                        }}
                        src="https://mediumrare.imgix.net/welcome-sports-en.jpg?&dpr=2&format=auto&auto=format&q=50&w=586"
                        altt="casino"
                      />
                    </div>
                  </Banner>
                  <Description>
                    Bet on your favourite teams, players and leagues from all
                    around the world on our sports betting platform. Gamble on a
                    wide range of sports betting options and markets for live
                    sports across MMA, Basketball, Soccer and more for an
                    unbeatable betting experience.
                  </Description>
                  <Button onClick={()=>history.push("/home/sports")}>Go to Sportsbook</Button>
                </Section>
              </BoxContainer>

              <BottomContainer>
                {/* <PartnershipBanner>
                  <LogoContainer>
                    <Logo>Stake</Logo>
                    <Separator />
                    <Logo>DRAKE</Logo>
                  </LogoContainer>
                  <LearnMoreButton>Learn More</LearnMoreButton>
                </PartnershipBanner> */}

                <PaymentOptionsSection>
                  <SectionTitle1>No Crypto? No problem.</SectionTitle1>
                  <PaymentOptions>
                    <PaymentLogo>
                      <img src="/assets/images/master.svg" alt="Mastercard" />
                    </PaymentLogo>
                    <PaymentLogo>
                      <img src="/assets/images/ipay.svg" alt="Apple Pay" />
                    </PaymentLogo>
                    <PaymentLogo>
                      <img
                        src="/assets/images/googlePay.svg"
                        alt="Google Pay"
                      />
                    </PaymentLogo>
                    <PaymentLogo>
                      <img
                        src="/assets/images/samsungPay.svg"
                        alt="Samsung Pay"
                      />
                    </PaymentLogo>
                  </PaymentOptions>
                  <BuyCryptoButton>Buy Crypto</BuyCryptoButton>
                </PaymentOptionsSection>
              </BottomContainer>
            </Wrapper>
          </CenteredContainer>

          <CenteredContainer>
            <BettingDashboard />
          </CenteredContainer>
        </div>
      )}
    </>
  );
}
