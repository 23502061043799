// import React, {Component} from "react";
// import storage from "../../../../Storage";
// import socket from "../../../../Socket";
// import {__, decode, encode, forceSatoshiFormat, fixDate, wait} from "../../../../Helper";
// import C from "../../../../Constant";

// const C_ = React.createElement;

// class inrhistory extends React.Component {
//     _isMounted = false;
//     constructor(props) {
//         super(props);
//         this.state = {
//             loading: true,
//             deposits: []
//         };
//     }

//     componentDidMount(){
//         this._isMounted = true;
//         socket.emit(C.inr_History);
//         socket.on(C.inr_History, data => this.makeHistory(decode(data)));
//     }

//     componentWillUnmount() {
//         this._isMounted = false;
//     }

//     makeHistory(data){
//         if(this._isMounted)
//         {
//             let array = data.deposit;
//             if(array) {
//                 array.forEach((deposit, i) => {
//                     let block = <DepositTable data={deposit} key={i}/>;
//                     this.setState(state => ({deposits: [block, ...state.deposits]}));
//                 });
//             }
//             this.setState({ loading: false });
//         }
//     }

//     render() {
//         let { loading } = this.state;
//         return(
//             <>
//                     <>
//                         <div className="table-responsive mt-2" style={{ minHeight: 460 }}>
//                             <table className="table table-hover deposit">
//                                 <thead>
//                                 <tr>
//                                     <th scope="col">Date</th>
//                                     <th scope="col">Amount</th>
//                                     <th scope="col">Status</th>
//                                     <th scope="col">Transaction</th>
//                                 </tr>
//                                 </thead>
//                                 {this.state.deposits.length > 0 &&
//                                     this.state.deposits
//                                 }
//                             </table>
//                             {loading ?
//                                 <div className="text-center" style={{ minHeight: 460 }}>
//                                        <div className="spinner-grow text-white my-3" role="status" />
//                                 </div>
//                             :
//                                 <>
//                                 {this.state.deposits.length === 0 &&
//                                     <div className="alert bg-soft-dark mt-2 w-100 font-13 text-center text-upper">
//                                         [ Your deposit list is empty ]
//                                     </div>
//                                 }
//                                 </>
//                             }
//                         </div>
//                     </>
//             </>
//         );
//     }
// }

// function DepositTable(props){
//     const { amount, status, date, trxid } = props.data;
//     return(
//             <tr>
//                 <td>{fixDate(date)}</td>
//                 <td>{forceSatoshiFormat(amount) + ' ' + __.upperCase(coin)}</td>
//                 <td>{status}</td>
//                 <td>
//                     <input className="form-control" readOnly={true} value={trxid} />
//                 </td>
//             </tr>
//     );
// }

// export default inrhistory;





import React, { useState, useEffect } from "react";
import storage from "../../../../Storage";
import { forceSatoshiFormat, fixDate } from "../../../../Helper";
import axios from "axios";

function Inrhistory() {
    const [deposits, setDeposits] = useState([]);
    const [loading, setLoading] = useState(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    useEffect(() => {
        let isMounted = true; // Track the mounted status
    
        fetchData().then(() => {
            if (!isMounted) {
                // If component is not mounted, cancel the Axios request
                source.cancel('Component unmounted, operation canceled');
            }
        });
    
        return () => {
            isMounted = false; 
        };
    }, []);
    
    

    const fetchData = async () => {
        setLoading(true); 
        try {
            
    
            const username = storage.getKey('name');
    
            const response = await axios.post('https://api.ibitplay.com/inrhistory', {
                name: username
            }, {
                cancelToken: source.token, // Using cancel token
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': '*',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
       console.log(response.data);
       if (response.data && Array.isArray(response.data)) {
        const mappedData = response.data.map(deposit => ({
            date: fixDate(deposit.date), 
            amount: forceSatoshiFormat(deposit.amount), 
            status: deposit.status,
            trxid: deposit.trxid
        }));
        console.log("Mapped Data:", mappedData); // Debugging
        setDeposits(mappedData);
        console.log("State set for Deposits"); 
    } else {
        console.error("Unexpected API response format");
    }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                console.error("API Error:", error);
            }
        } finally {
            setLoading(false); 
        }
    
        return () => source.cancel('Operation canceled by the user.');
    };
    
    

    useEffect(() => {
       
            console.log('Deposits updated:', deposits);
        
    }, [deposits]); 
    

    return (
        <div className="table-responsive mt-2" style={{ minHeight: 460 }}>
            {loading ? (
                <div className="text-center" style={{ minHeight: 460 }}>
                    <div className="spinner-grow text-white my-3" role="status"></div>
                </div>
            ) : deposits.length > 0 ? (
                <table className="table table-hover deposit">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Status</th>
                            <th scope="col">Transaction</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deposits.map((deposit, index) => (
                            <tr key={index}>
                                <td>{deposit.date}</td>
                                <td>{deposit.amount}</td>
                                <td>{deposit.status}</td>
                                <td>
                                    <input 
                                        className="form-control" 
                                        readOnly={true} 
                                        value={deposit.trxid} 
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="alert bg-soft-dark mt-2 w-100 font-13 text-center text-upper">
                    Your deposit list is empty.
                </div>
            )}
        </div>
    );
    
}

export default Inrhistory;

