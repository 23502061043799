import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import SearchInputBox from '../../../Components/StakeSearch';

// Separate JSON data for lobby games and slots
const lobbyGamesData = [
    {
      id: 1,
      title: "DICE",
      subtitle: "Stake Originals",
      players: 7437,
      backgroundColor: "#8B5CF6",
       icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/dice-casino.avif"
    },
    {
      id: 2,
      title: "CRASH",
      subtitle: "Stake Originals",
      players: 3967,
      backgroundColor: "#FBBF24",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakecrash.avif"
    },
    {
      id: 3,
      title: "PLINKO",
      subtitle: "Stake Originals",
      players: 4389,
      backgroundColor: "#EC4899",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakeplinko.avif"
    },
    {
      id: 4,
      title: "MINES",
      subtitle: "Stake Originals",
      players: 14849,
      backgroundColor: "#3B82F6",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakemines.avif"
    },
    {
      id: 5,
      title: "LIMBO",
      subtitle: "Stake Originals",
      players: 6809,
      backgroundColor: "#F59E0B",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakelimbo.avif"
    },
    {
      id: 6,
      title: "KENO",
      subtitle: "Stake Originals",
      players: 2668,
      backgroundColor: "#2563EB",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakekino.avif"
    },
    {
      id: 7,
      title: "HILO",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakehilo.avif"
    },
    {
      id: 8,
      title: "BLACKJACK",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakeblackjack.avif"

    },
    {
      id: 9,
      title: "DRAGON TOWER",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakedragontower.avif"

    },
    {
      id: 10,
      title: "TIME OF LIFE",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/staketomeoflife.avif"

    },
    {
      id: 11,
      title: "Wheel",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakewheel.avif"

    },
    {
      id: 12,
      title: "SACARAB SPIN",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakescrabspin.avif"

    },
    {
      id: 13,
      title: "DIAMONDS",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakediamonds.avif"

    },
    {
      id: 14,
      title: "ROULLETE",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakeroulette.avif"

    }
    ,

    {
      id: 15,
      title: "SLIDE",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakeslide.avif"

    },
    {
      id: 16,
      title: "BLUE",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakeblue.avif"

    },
    {
      id: 17,
      title: "VIDEO POKER",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakepoker.avif"

    },
    {
      id: 18,
      title: "BACCARAT",
      subtitle: "Stake Originals",
      players: 1662,
      backgroundColor: "#10B981",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeGames/stakebaccarat.avif"

    }
];

const slotsGamesData = [
  {
    id: 1,
    title: "DICE",
    subtitle: "Stake Originals",
    players: 7437,
    backgroundColor: "#8B5CF6",
     icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakeslots/slotsweet.avif"
  },
  {
    id: 2,
    title: "CRASH",
    subtitle: "Stake Originals",
    players: 3967,
    backgroundColor: "#FBBF24",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakeslots/slotwanted.avif"
  },
  {
    id: 3,
    title: "PLINKO",
    subtitle: "Stake Originals",
    players: 4389,
    backgroundColor: "#EC4899",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakeslots/slotolympus.avif"
  },
  {
    id: 4,
    title: "MINES",
    subtitle: "Stake Originals",
    players: 14849,
    backgroundColor: "#3B82F6",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakeslots/slotbendit.avif"
  },
  {
    id: 5,
    title: "LIMBO",
    subtitle: "Stake Originals",
    players: 6809,
    backgroundColor: "#F59E0B",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakeslots/slotsuger.avif"
  },
  {
    id: 6,
    title: "KENO",
    subtitle: "Stake Originals",
    players: 2668,
    backgroundColor: "#2563EB",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakeslots/slotzeus.avif"
  },
  {
    id: 7,
    title: "HILO",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakeslots/slotrip.avif"
  },
  {
    id: 8,
    title: "BLACKJACK",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakeslots/slotfruit.avif"

  },
  {
    id: 9,
    title: "DRAGON TOWER",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakeslots/slotsweet.avif"

  }
];

const liveCasinoData = [
  {
    id: 1,
    title: "DICE",
    subtitle: "Stake Originals",
    players: 7437,
    backgroundColor: "#8B5CF6",
     icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakelivecasino/stakeblack.avif"
  },
  {
    id: 2,
    title: "CRASH",
    subtitle: "Stake Originals",
    players: 3967,
    backgroundColor: "#FBBF24",
    icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakelivecasino/stakebaccarat.avif"
  },
  {
    id: 3,
    title: "PLINKO",
    subtitle: "Stake Originals",
    players: 4389,
    backgroundColor: "#EC4899",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakelivecasino/stakeroullete.avif"
  },
  {
    id: 4,
    title: "MINES",
    subtitle: "Stake Originals",
    players: 14849,
    backgroundColor: "#3B82F6",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakelivecasino/stakebetcity.avif"
  },
  {
    id: 5,
    title: "LIMBO",
    subtitle: "Stake Originals",
    players: 6809,
    backgroundColor: "#F59E0B",
    icon: '/assets/images/stakeicons/originalstake.png',
     imageUrl: "/assets/images/stakelivecasino/stakedragontiger.avif"
  },
  {
    id: 6,
    title: "KENO",
    subtitle: "Stake Originals",
    players: 2668,
    backgroundColor: "#2563EB",
    icon: '/assets/images/stakeicons/originalstake.png',
     imageUrl: "/assets/images/stakelivecasino/stakecraps.avif"
  },
  {
    id: 7,
    title: "HILO",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/stakelivecasino/stakextreme.avif"
  },
  {
    id: 8,
    title: "BLACKJACK",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/stakelivecasino/stakered.avif"

  },
  {
    id: 9,
    title: "DRAGON TOWER",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
       imageUrl: "/assets/images/stakelivecasino/stakestock.avif"

  },
  {
    id: 10,
    title: "DRAGON TOWER",
    subtitle: "Stake Originals",
    players: 1662,
    backgroundColor: "#10B981",
    icon: '/assets/images/stakeicons/originalstake.png',
       imageUrl: "/assets/images/stakelivecasino/stakecrazy.avif"

  }
];







const providerData = [
  {
    id: 1,
    title: "Book of Dead",
    subtitle: "Play'n GO",
    players: 5231,
    backgroundColor: "#6D28D9",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/paramatic-play.avif"
  },
  {
    id: 2,
    title: "Starburst",
    subtitle: "NetEnt",
    players: 4892,
    backgroundColor: "#D97706",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/evolution.avif"
  },
  {
    id: 3,
    title: "Gonzo's Quest",
    subtitle: "NetEnt",
    players: 3754,
    backgroundColor: "#059669",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/hacksaw.avif"
  },
  {
    id: 4,
    title: "Reactoonz",
    subtitle: "Play'n GO",
    players: 3201,
    backgroundColor: "#4F46E5",
    icon: '/assets/images/stakeicons/originalstake.png',
   imageUrl: "/assets/images/paramatic-play.avif"
  },
  {
    id: 5,
    title: "Mega Moolah",
    subtitle: "Microgaming",
    players: 2987,
    backgroundColor: "#B45309",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/evolution.avif"
  },
  {
    id: 6,
    title: "Jammin' Jars",
    subtitle: "Push Gaming",
    players: 2543,
    backgroundColor: "#7C3AED",
    icon: '/assets/images/stakeicons/originalstake.png',
     imageUrl: "/assets/images/paramatic-play.avif"
  },
  {
    id: 7,
    title: "Sweet Bonanza",
    subtitle: "Pragmatic Play",
    players: 2321,
    backgroundColor: "#DB2777",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/evolution.avif"
  },
  {
    id: 8,
    title: "Sweet Bonanza",
    subtitle: "Pragmatic Play",
    players: 2321,
    backgroundColor: "#DB2777",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/evolution.avif"
  },
  {
    id: 9,
    title: "Sweet Bonanza",
    subtitle: "Pragmatic Play",
    players: 2321,
    backgroundColor: "#DB2777",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/evolution.avif"
  },
  {
    id: 10,
    title: "Sweet Bonanza",
    subtitle: "Pragmatic Play",
    players: 2321,
    backgroundColor: "#DB2777",
    icon: '/assets/images/stakeicons/originalstake.png',
    imageUrl: "/assets/images/evolution.avif"
  }
];




const slotsData = [
    {
      id: 1,
      title: "Book of Dead",
      subtitle: "Play'n GO",
      players: 5231,
      backgroundColor: "#6D28D9",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/book-of-dead.avif"
    },
    {
      id: 2,
      title: "Starburst",
      subtitle: "NetEnt",
      players: 4892,
      backgroundColor: "#D97706",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/starburst.avif"
    },
    {
      id: 3,
      title: "Gonzo's Quest",
      subtitle: "NetEnt",
      players: 3754,
      backgroundColor: "#059669",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/gonzos-quest.avif"
    },
    {
      id: 4,
      title: "Reactoonz",
      subtitle: "Play'n GO",
      players: 3201,
      backgroundColor: "#4F46E5",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/reactoonz.avif"
    },
    {
      id: 5,
      title: "Mega Moolah",
      subtitle: "Microgaming",
      players: 2987,
      backgroundColor: "#B45309",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/mega-moolah.avif"
    },
    {
      id: 6,
      title: "Jammin' Jars",
      subtitle: "Push Gaming",
      players: 2543,
      backgroundColor: "#7C3AED",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/jammin-jars.avif"
    },
    {
      id: 7,
      title: "Sweet Bonanza",
      subtitle: "Pragmatic Play",
      players: 2321,
      backgroundColor: "#DB2777",
      icon: '/assets/images/stakeicons/originalstake.png',
      imageUrl: "/assets/images/sweet-bonanza.avif"
    }
];

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  padding-top: 25px;
`;

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const CarouselTrack = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  margin-top: 12px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
    grid-auto-columns: calc((100% - 16px) / 3);
  }

  @media (min-width: 768px) {
    gap: 16px;
    grid-auto-columns: calc((100% - 96px) / 7);
  }
`;

// Define the styled component
const Container = styled.div`
  margin-bottom: 19px;
  // padding: 0 15px;

  // @media (max-width: 768px) {
  //   padding: 0 0;
  // }
`;


const pulseAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const GameCard = styled.div`
  height: 0;
  padding-bottom: 134%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundColor};
  scroll-snap-align: start;
  position: relative;
  top: 0;
  transition: top 0.3s ease;
  
  &:hover {
    top: -10px;
    z-index:9999;
  }

`;
const TopWrapper = styled.div`
  background-color: #0f212e;
`

const GameCardImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const GameCardInfo = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: left;
  width: 100%;
  margin-top: 4px;
  font-size: 12px;
`;

const PlayerCount = styled.div`
  font-weight: bold;
`;

const PlayingText = styled.div`
  color: #b1bbd3;
`;

const ButtonGroup = styled.div`
  display: flex;
  background-color: transparent;
  border-radius: 9999px;
  padding: 2px;
  width: 84px;
  border:1px solid #2f4553;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  color: #94a3b8;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
  width: 28px;
  height: 28px;

  &:hover:not(:disabled) {
    background-color: rgba(30, 41, 59, 0.8);
    color: #e2e8f0;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const DividerButton = styled.div`
  width: 1px;
  height: 16px;
  background-color: rgba(255, 255, 255, 0.2); // Increased opacity and changed to white
  margin: 0 1px; // Reduced margin to ensure it's visible
`;

const Divider = styled.div`
  width: 1px;
  background-color: #334155;
  margin: 0 4px;
`;

const GameNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top:-12px;
`;

const GameName = styled.div`
  font-size: 18px;
  color: white;
  font-weight: bold;
`;

const TabContainerWrapper = styled.div`
  overflow-x: auto;
  margin-bottom: 10px;
  margin-top: 15px;
  margin-left:5px;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TabContainer = styled.div`
  display: inline-flex;
  background-color: #0f212e;
  border-radius: 50px;
  padding: 5px;
  // gap: 10px;
  white-space: nowrap;

`;

const Tab = styled.button`
  background-color: ${props => props.active ? '#2F4453' : 'transparent'};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.active ? '#2F4453' : '#252a30'};
  }

  @media (min-width: 1320px) {
    padding: 15px 25px;
  }
`;

const GameIcon = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
`;

const ProviderCarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  padding-top: 40px;
`;

const ProviderCarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const ProviderCarouselTrack = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 32px;
  margin-top: 12px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }

  /* Mobile: 3 items */
  @media (max-width: 767px) {
    gap: 12px;
    grid-auto-columns: calc((100% - 24px) / 3);
  }

  /* Tablet: 4 items */
  @media (min-width: 768px) and (max-width: 1023px) {
    gap: 16px;
    grid-auto-columns: calc((100% - 48px) / 3);
  }

  /* Desktop: 7 items */
  @media (min-width: 1024px) {
    gap: 16px;
    grid-auto-columns: calc((100% - 96px) / 7);
  }
`;


const ProviderGameNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ProviderGameName = styled.div`
  font-size: 18px;
  color: white;
  font-weight: bold;
`;

const ProviderGameIcon = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
`;


const ProviderButtonGroup = styled.div`
  display: flex;
  background-color: transparent;
  border-radius: 9999px;
  padding: 2px;
  width: 84px;
  border:1px solid #2f4553;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 30px;
  right: 8px;
`;

const ProviderButton = styled.button`
  background-color: transparent;
  border: none;
  color: #94a3b8;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
  width: 28px;
  height: 28px;

  &:hover:not(:disabled) {
    background-color: rgba(30, 41, 59, 0.8);
    color: #e2e8f0;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const ProviderButtonDivider = styled.div`
  width: 1px;
  height: 16px;
  background-color: rgba(255, 255, 255, 0.2); // Increased opacity and changed to white
  margin: 0 1px; // Reduced margin to ensure it's visible
`;

const ProviderImage = styled.img`
  height: auto;
  width: 100%;
  object-fit: contain;
  border-radius: 4px;

  @media (max-width: 767px) {
    width: calc((100% - 32px) / 3); /* Adjust width for small screens */
    min-width: 119px; /* Set a minimum width */
    min-height: 50px; /* Set a minimum height */
  }

  @media (min-width: 768px) {
    height: 68px;
    width: 170px; /* Use fixed width for larger screens */
  }
`;




const GameCardComponent = ({ title, subtitle, players, backgroundColor, imageUrl }) => (
  <div>
    <GameCard backgroundColor={backgroundColor}>
      <GameCardImage>
        <img src={imageUrl} alt={title} />
      </GameCardImage>
    </GameCard>
    <GameCardInfo>
      <div style={{height: '8px', width: '8px', borderRadius: '50%', backgroundColor: "#1fff20"}}></div>
      <PlayerCount>{players}</PlayerCount>
      <PlayingText>Playing</PlayingText>
    </GameCardInfo>
  </div>
);

const Carousel = ({ title, image, data }) => {

  console.log("data", data)
  const trackRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [itemWidth, setItemWidth] = useState(0);

  const checkScrollButtons = () => {
    const { current } = trackRef;
    if (current) {
      setCanScrollLeft(current.scrollLeft > 0);
      setCanScrollRight(
        current.scrollLeft < current.scrollWidth - current.clientWidth - 1
      );
    }
  };

  const updateItemWidth = () => {
    const { current } = trackRef;
    if (current) {
      const computedStyle = window.getComputedStyle(current);
      const gap = parseInt(computedStyle.getPropertyValue('column-gap'), 10);
      const totalWidth = current.clientWidth + gap;
      const itemsPerView = window.innerWidth < 768 ? 3 : 7;
      setItemWidth(totalWidth / itemsPerView - gap);
    }
  };

  useEffect(() => {
    const { current } = trackRef;
    if (current) {
      const resizeObserver = new ResizeObserver(() => {
        updateItemWidth();
        checkScrollButtons();
      });
      resizeObserver.observe(current);

      current.addEventListener('scroll', checkScrollButtons);
      updateItemWidth();
      checkScrollButtons();

      return () => {
        current.removeEventListener('scroll', checkScrollButtons);
        resizeObserver.disconnect();
      };
    }
  }, []);

  const scroll = (direction) => {
    const { current } = trackRef;
    if (current) {
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      setTimeout(checkScrollButtons, 400); // Check after scroll animation
    }
  };

  return (
    <CarouselWrapper>
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <GameNameWrapper>
      
            
 
            <GameIcon src={image} alt={`${title} icon`} />
 
          <GameName>{title}</GameName>


     
        </GameNameWrapper>
        <ButtonGroup>
  <Button onClick={() => scroll('left')} disabled={!canScrollLeft}>
    <ChevronLeft size={16} />
  </Button>
  <DividerButton />
  <Button onClick={() => scroll('right')} disabled={!canScrollRight}>
    <ChevronRight size={16} />
  </Button>
</ButtonGroup>
      </div>
      <CarouselContainer>
        <CarouselTrack ref={trackRef}>
          {data.map((game) => (
            <GameCardComponent key={game.id} {...game} />
          ))}
        </CarouselTrack>
      </CarouselContainer>
    </CarouselWrapper>
  );
};

const CarouselProvider = ({ title, data }) => {
  const trackRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [itemWidth, setItemWidth] = useState(0);

  const checkScrollButtons = () => {
    const { current } = trackRef;
    if (current) {
      setCanScrollLeft(current.scrollLeft > 0);
      setCanScrollRight(
        current.scrollLeft < current.scrollWidth - current.clientWidth - 1
      );
    }
  };

  const updateItemWidth = () => {
    const { current } = trackRef;
    if (current) {
      const computedStyle = window.getComputedStyle(current);
      const gap = parseInt(computedStyle.getPropertyValue('column-gap'), 10);
      const totalWidth = current.clientWidth + gap;
      const itemsPerView = window.innerWidth < 768 ? 3 : 7;
      setItemWidth(totalWidth / itemsPerView - gap);
    }
  };

  useEffect(() => {
    const { current } = trackRef;
    if (current) {
      const resizeObserver = new ResizeObserver(() => {
        updateItemWidth();
        checkScrollButtons();
      });
      resizeObserver.observe(current);

      current.addEventListener('scroll', checkScrollButtons);
      updateItemWidth();
      checkScrollButtons();

      return () => {
        current.removeEventListener('scroll', checkScrollButtons);
        resizeObserver.disconnect();
      };
    }
  }, []);

  const scroll = (direction) => {
    const { current } = trackRef;
    if (current) {
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      setTimeout(checkScrollButtons, 400); // Check after scroll animation
    }
  };

  return (
    <CarouselWrapper>
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        {/* <GameNameWrapper>
  
      
        {data[0] && data[0].icon && (
            <GameIcon src={data[0].icon} alt={`${title} icon`} />
          )}

    <GameName>{title}</GameName>
        </GameNameWrapper> */}
        <ButtonGroup>
  <Button onClick={() => scroll('left')} disabled={!canScrollLeft}>
    <ChevronLeft size={16} />
  </Button>
  <DividerButton />
  <Button onClick={() => scroll('right')} disabled={!canScrollRight}>
    <ChevronRight size={16} />
  </Button>
</ButtonGroup>
      </div>
      <CarouselContainer>
        <CarouselTrack ref={trackRef}>
          {data.map((game) => (
            <img src={game.imageUrl} style={{height:'68px', width:'170px', objectFit:'contain', borderRadius:'4px'}}/>

            // <GameCardComponent key={game.id} {...game} />
          ))}
        </CarouselTrack>
      </CarouselContainer>
    </CarouselWrapper>
  );
};

const gamesSteps = [
  { icon: '/assets/images/stakeicons/lobbystake.png', name: 'Lobby' },
  { icon: '/assets/images/stakeicons/originalstake.png', name: 'Stake Originals' },
  { icon: '/assets/images/stakeicons/slotstake.png', name: 'Slots' },
  { icon: '/assets/images/stakeicons/livecasinostake.png', name: 'Live Casino' },
  // { icon:'/assets/images/stakeicons/originalstake.png', name: 'Game Shows' },
  { icon: '/assets/images/stakeicons/stakeexclusive.png', name: 'Stake Exclusives' },
  // { icon: '/assets/images/stakeicons/originalstake.png', name: 'New Release' }
];


const renderIcons = (activeTab) => {
  switch (activeTab) {
    case 'Lobby':
      return gamesSteps.find(step => step.name === 'Lobby').icon;
    case 'Stake Originals':
      return gamesSteps.find(step => step.name === 'Stake Originals').icon;
    case 'Slots':
      return gamesSteps.find(step => step.name === 'Slots').icon;
    case 'Live Casino':
      return gamesSteps.find(step => step.name === 'Live Casino').icon;
    case 'Stake Exclusives':
      return gamesSteps.find(step => step.name === 'Stake Exclusives').icon;
    default:
      return gamesSteps.find(step => step.name === 'Lobby').icon; // Default to Lobby icon
  }
};

const GamesCardComponent = () => {
  const [activeTab, setActiveTab] = useState('Lobby');

  return (
    <Container>
            <SearchInputBox/>
      <TabContainerWrapper>
      <TabContainer>
  {gamesSteps.map((tab, index) => (
    <Tab
      key={tab.name}
      active={activeTab === tab.name}
      onClick={() => setActiveTab(tab.name)}
    >
      <div style={{display:'flex', gap:'4px', alignItems:'center'}}>
        <img 
          src={tab.icon} 
          style={{
            height: index === 2 ? '20px' : '17px', 
            width: '20px', 
            objectFit:'contain'
          }} 
          alt={tab.icon}
        />
        <div>{tab.name}</div>
      </div>
    </Tab>
  ))}
</TabContainer>
      </TabContainerWrapper>
      
      <Carousel image={renderIcons(activeTab)} title={activeTab} data={lobbyGamesData} />
      <Carousel image="/assets/images/stakeicons/slotstake.png" title="Slots" data={slotsGamesData} />
      <ResponsiveCarouselProvider image="/assets/images/stakecoins/providerstake.png" title={"Providers"} data={providerData} />
      <Carousel image="/assets/images/stakeicons/livecasinostake.png" title="Live Casino" data={liveCasinoData} />
    </Container>
  );
};

export default GamesCardComponent;


const ResponsiveCarouselProvider = ({ title,image, data }) => {
  const trackRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [itemWidth, setItemWidth] = useState(0);

  const checkScrollButtons = () => {
    const track = trackRef.current;
    if (track) {
      setCanScrollLeft(track.scrollLeft > 0);
      setCanScrollRight(
        track.scrollLeft < track.scrollWidth - track.clientWidth - 1
      );
    }
  };

  const updateItemWidth = () => {
    const track = trackRef.current;
    if (track) {
      const computedStyle = window.getComputedStyle(track);
      const gap = parseInt(computedStyle.getPropertyValue('column-gap'), 10);
      const totalWidth = track.clientWidth + gap;
      let itemsPerView;
      if (window.innerWidth < 768) {
        itemsPerView = 3;
      } else if (window.innerWidth < 1024) {
        itemsPerView = 4;
      } else {
        itemsPerView = 7;
      }
      setItemWidth(totalWidth / itemsPerView - gap);
    }
  };

  useEffect(() => {
    const track = trackRef.current;
    if (track) {
      const resizeObserver = new ResizeObserver(() => {
        updateItemWidth();
        checkScrollButtons();
      });
      resizeObserver.observe(track);

      track.addEventListener('scroll', checkScrollButtons);
      updateItemWidth();
      checkScrollButtons();

      return () => {
        track.removeEventListener('scroll', checkScrollButtons);
        resizeObserver.disconnect();
      };
    }
  }, []);

  const scroll = (direction) => {
    const track = trackRef.current;
    if (track) {
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      track.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      setTimeout(checkScrollButtons, 400);
    }
  };

  return (
    <ProviderCarouselWrapper>
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <ProviderGameNameWrapper>
     
            <ProviderGameIcon src={image} alt={`${title} icon`} />
      
          <ProviderGameName>{title}</ProviderGameName>
        </ProviderGameNameWrapper>
        <ProviderButtonGroup>
          <ProviderButton onClick={() => scroll('left')} disabled={!canScrollLeft}>
            <ChevronLeft size={16} />
          </ProviderButton>
          <ProviderButtonDivider />
          <ProviderButton onClick={() => scroll('right')} disabled={!canScrollRight}>
            <ChevronRight size={16} />
          </ProviderButton>
        </ProviderButtonGroup>
      </div>
      <ProviderCarouselContainer>
        <ProviderCarouselTrack ref={trackRef}>
          {data.map((game) => (
            <ProviderImage key={game.id} src={game.imageUrl} alt={game.title} />
          ))}
        </ProviderCarouselTrack>
      </ProviderCarouselContainer>
    </ProviderCarouselWrapper>
  );
};

