


import React from 'react'

export default function LogoutIcon() {
    return (
<svg fill="currentColor" viewBox="0 0 64 64" class="svg-icon " style={{ height: '20px', width: '20px' }}> <title></title> <path fill-rule="evenodd" clip-rule="evenodd" d="M23.174 48.96h15.174v-6.506h8V56.96H23.174V64L0 56.96V7.04L23.174 0v7.04h23.174v14.506h-8V15.04H23.174v33.92Zm25.332-25.895L64 32l-15.494 8.934V36h-16.16v-8h16.16v-4.934Z"></path></svg>
    )
}
