import React from 'react'

export default function VauletIcon() {
    return (
        <svg fill="currentColor" viewBox="0 0 64 64" class="svg-icon " style={{height:"20px", width:'20px'}}> <title></title> <path fill-rule="evenodd" clip-rule="evenodd" d="M0 49.573V0h64v49.573H0ZM31.067 28.8h7.786v-8h-7.786a11.92 11.92 0 1 0 0 8ZM48 41.947h8v-12.08h-8v12.08Zm0-22.214h8V7.653h-8v12.08ZM23.893 24.8a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM5.333 64H18.72l4.56-7.76H5.333V64Zm53.334 0H45.28l-4.56-7.76h17.947V64Z"></path></svg>
    )
}



